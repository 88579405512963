import { HTMLProps } from 'react';

import { cn } from '@utils';

interface TrianglesBackgroundProps extends HTMLProps<HTMLDivElement> {
  className?: string;
}

export const TrianglesBackground = ({ className, ...props }: TrianglesBackgroundProps) => {
  const rectangleSVGDark = `<svg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'><rect width='40' height='40' x='0' y='0' stroke='rgba(255,255,255,0.15)' fill='none' /></svg>`;
  const encodedRectangleSVGDark = encodeURIComponent(rectangleSVGDark);
  return (
    <div className={cn('pointer-events-none absolute inset-0 z-0 h-full w-full overflow-hidden', className)} {...props}>
      <div
        className={cn('h-full w-full block')}
        style={{
          backgroundImage: `url("data:image/svg+xml,${encodedRectangleSVGDark}")`,
          backgroundSize: '40px 40px',
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat',
        }}
      />
    </div>
  );
};
