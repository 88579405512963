import { useMemo } from 'react';

import { useCdnSummary } from '@store';

type CdnData = ReturnType<typeof useCdnSummary>;

interface BreadcrumbItem {
  id: string;
  name: string;
}

export const useBreadcrumbItems = (
  pathname: string,
  dataSource: CdnData | null,
  translate: (key: string) => string,
): BreadcrumbItem[] => {
  return useMemo(() => {
    if (pathname.includes('invite')) {
      return [
        {
          id: pathname,
          name: translate('navigator.workspaceInvitation'),
        },
      ];
    }

    const segments = pathname.split('/').filter(Boolean);

    return segments.reduce<BreadcrumbItem[]>((acc, segment, index) => {
      if (segment === 'configuration') {
        return acc;
      }

      if (segment === ':id' && dataSource) {
        acc.push({
          id: String(dataSource.id),
          name: dataSource.name || String(dataSource.id),
        });
        return acc;
      }

      const isNumeric = /^\d+$/.test(segment);

      if (isNumeric && dataSource && Number(dataSource.id) === Number(segment)) {
        acc.push({
          id: String(dataSource.id),
          name: dataSource.name || segment,
        });
      } else {
        const translatedKey = `navigator.${segment}`;
        const translatedName = translate(translatedKey);
        acc.push({
          id: segment,
          name: translatedName !== translatedKey ? translatedName : segment,
        });
      }

      return acc;
    }, []);
  }, [pathname, dataSource, translate]);
};
