import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants';
import { FormType } from '@enums';
import { useCurrentWorkspaceId, useFormList, useWorkspaceOptions } from '@store';

import { CdnForm } from './CdnForm';

export enum CdnStatus {
  Active = 1,
  InActive = 0,
}

export interface CdnData {
  id: string;
  name: string;
  status: CdnStatus;
  allow_wildcard: boolean;
  allow_domain_count: number;
  allow_total_domain_count: number;
  allow_page_rules_count: number;
}

export const Cdn = () => {
  const navigate = useNavigate();

  const currentWorkspaceId = useCurrentWorkspaceId();
  const workspaceList = useWorkspaceOptions();

  const cdnError = useFormList({ formType: FormType.Cdn });

  useEffect(() => {
    if (cdnError) {
      if (!currentWorkspaceId || workspaceList.length <= 0) {
        navigate(ROUTES.homepage);
      }
    }
  }, [cdnError]);

  return <div className="flex flex-1 flex-col gap-8">{<CdnForm />}</div>;
};
