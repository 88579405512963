import { decrypt64Base, encrypt64Base } from '@utils';

import type { InvitationData } from '@types';

const STORAGE_KEYS = {
  INVITE_TOKEN: 'inviteToken',
  INVITE_WORKSPACE_ID: 'inviteWorkspaceId',
} as const;

export const invitationStorage = {
  store: (data: InvitationData): void => {
    try {
      localStorage.setItem(STORAGE_KEYS.INVITE_TOKEN, encrypt64Base(data.token));
      localStorage.setItem(STORAGE_KEYS.INVITE_WORKSPACE_ID, encrypt64Base(data.workspaceId));
    } catch (error) {
      console.error('Failed to store invitation data:', error);
      throw new Error('Failed to store invitation data');
    }
  },

  get: (): InvitationData | null => {
    try {
      const token = localStorage.getItem(STORAGE_KEYS.INVITE_TOKEN);
      const workspaceId = localStorage.getItem(STORAGE_KEYS.INVITE_WORKSPACE_ID);

      if (!token || !workspaceId) return null;

      return {
        token: decrypt64Base(token),
        workspaceId: decrypt64Base(workspaceId),
      };
    } catch (error) {
      console.error('Failed to retrieve invitation data:', error);
      return null;
    }
  },

  clear: (): void => {
    localStorage.removeItem(STORAGE_KEYS.INVITE_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.INVITE_WORKSPACE_ID);
  },
};
