import classNames from 'classnames';
import React, { memo } from 'react';

import { Cards, Chip, Text } from '@components';
import { SCREEN_BREAKPOINTS } from '@constants';
import { VariantType } from '@enums';
import { useMediaQuery } from '@hooks';

interface PredefinedCardProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  besideTitle?: boolean;
  showChip?: boolean;
  selectedInput?: SelectedInput[] | SelectedInputObject[];
  rightColumnClassName?: string;
  className?: string;
}

type SelectedInputObject = {
  label: string;
  value: string;
  disabled?: boolean;
};

type SelectedInput = string | number;

const TitleComponent = memo(({ title }: { title: React.ReactNode }) =>
  typeof title === 'string' ? (
    <Text $level={4} color="text-black" className="font-medium leading-5">
      {title}
    </Text>
  ) : (
    title
  ),
);

const DescriptionComponent = memo(({ description }: { description?: React.ReactNode }) =>
  typeof description === 'string' ? (
    <Text $level={5} color="text-gray-400" className="font-light text-justify leading-4">
      {description}
    </Text>
  ) : (
    description
  ),
);

export const PredefinedCards: React.FC<PredefinedCardProps> = memo(
  ({
    title,
    description,
    children,
    besideTitle = false,
    showChip = false,
    selectedInput,
    rightColumnClassName = 'max-w-64',
    className = '',
  }) => {
    const selectedLabel =
      selectedInput?.map((input) => {
        if (typeof input === 'object' && 'label' in input) {
          return input.label;
        }
        return input;
      }) ?? [];
    const isAboveSm = useMediaQuery(`(min-width: ${SCREEN_BREAKPOINTS.sm}px)`);

    return (
      <Cards rounded="rounded-2xl" withoutPadding className={`flex flex-col sm:flex-row justify-between ${className}`}>
        <div className="flex flex-col gap-2 p-6 sm:p-8 sm:flex-1">
          <div className="flex gap-3 justify-between">
            <TitleComponent title={title} />
            {besideTitle && <div className="pb-1">{children}</div>}
          </div>
          <DescriptionComponent description={description} />
          <div className="flex flex-wrap">
            {showChip &&
              selectedLabel?.length > 0 &&
              selectedLabel.map((item, index) => {
                return (
                  <div key={index}>
                    <Chip
                      smallFont
                      chipVariant={VariantType.Outlined}
                      withoutPadding
                      className="px-5 py-1 w-fit mr-2 my-1"
                      label={item}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        {!besideTitle && (
          <div
            className={classNames(
              'flex flex-col w-full gap-4',
              'sm:shadow-card sm:border-l sm:rounded-bl-none',
              'px-6 pb-6 sm:p-6',
              'justify-center items-center',
              'sm:bg-gray-400 sm:bg-opacity-5',
              'rounded-b-2xl sm:rounded-r-2xl',
              { [rightColumnClassName]: isAboveSm },
            )}
          >
            {children}
          </div>
        )}
      </Cards>
    );
  },
);
