import { createValidationSchema } from '@utils';

export const CHANGE_PASSWORD_SCHEMA = createValidationSchema({
  current_password: {
    required: true,
    minLength: 8,
  },
  password: {
    required: true,
    minLength: 8,
  },
  confirm_password: {
    required: true,
    customRule: {
      validator: ({ value, formData }) => value === formData.password,
      message: 'validation:changePassword.passwordsMustMatch',
    },
  },
});
