import i18n from 'i18next';

import { Column, Text } from '@components';
import { CellType } from '@enums';
import { capitalizeFirstLetter, formatTableTimestamp, getColumnSizes } from '@utils';

export const cdnWafLogTable = ({ actionHandlers }): Column[] => [
  {
    label: i18n.t('configuration:tables.id'),
    value: 'id',
    fontSize: 5,
    labelStyle: 'font-medium',
    multiLines: true,
    ...getColumnSizes(50),
  },
  {
    label: i18n.t('configuration:tables.status'),
    value: 'action',
    cellType: CellType.Custom,
    fontSize: 5,
    labelStyle: 'font-medium',
    format: (value) => capitalizeFirstLetter(value),
    multiLines: true,
    ...getColumnSizes(90),
  },
  {
    label: i18n.t('configuration:tables.client_ip'),
    value: 'remote_addr',
    multiLines: true,
    fontSize: 5,
    labelStyle: 'font-medium',
    ...getColumnSizes(110),
  },
  {
    label: i18n.t('configuration:tables.rule'),
    value: 'matches',
    multiLines: true,
    cellType: CellType.Custom,
    format: (value) => {
      const rule = value?.map(({ group }) => group) ?? [];
      return (
        !!rule.length && (
          <div className="flex flex-col space-y-4 select-text h-full">
            {rule.map((entry, index) => (
              <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
                <Text $level={5} color="black" className="font-medium">
                  {entry}
                </Text>
              </div>
            ))}
          </div>
        )
      );
    },
    ...getColumnSizes(200),
  },
  {
    label: i18n.t('configuration:tables.logInformation'),
    value: '',
    multiLines: true,
    cellType: CellType.Custom,
    format: (_value, rowData) => {
      const logEntries = [
        { label: i18n.t('configuration:tables.requestId'), value: rowData.request_id ?? '_' },
        { label: i18n.t('configuration:tables.host'), value: rowData.host ?? '_' },
        { label: i18n.t('configuration:tables.request'), value: rowData.request ?? '_' },
      ];
      return (
        <div className="flex flex-col space-y-3.5 select-text">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <Text $level={5} className="font-medium" color="black">
                {entry.label}
              </Text>
              <Text $level={5} className="font-light w-full whitespace-break-spaces break-all">
                {entry.value}
              </Text>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(220),
  },
  {
    label: i18n.t('configuration:tables.logDate'),
    value: 'created_unix',
    format: (value) => formatTableTimestamp(value),
    multiLines: true,
    fontSize: 5,
    labelStyle: 'font-medium',
    ...getColumnSizes(140),
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    multiLines: true,
    ...getColumnSizes(90),
  },
];
