import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumbs, Cards, CdnCards, Text } from '@components';
import { ROUTES } from '@constants';
import { FormListLayout } from '@containers';
import { FormActionType, FormType, Translation } from '@enums';
import { useFormHandlers } from '@hooks';
import { useFormList, useFormLoadingStatus, useFormSubmissionStatus } from '@store';
import { CdnApplicationModal } from '../../subComponents';
import { CdnSkeleton } from './CdnSkeleton';

export enum CdnStatus {
  Active = 1,
  InActive = 0,
}

export interface CdnData {
  id: string;
  name: string;
  status: CdnStatus;
  allow_wildcard: boolean;
  allow_domain_count: number;
  allow_total_domain_count: number;
  allow_page_rules_count: number;
}

export const CdnForm = () => {
  const navigate = useNavigate();

  const formType = FormType.Cdn;

  const cdnData = useFormList({ formType });
  const loading = useFormLoadingStatus({ formType });
  const formSubmitting = useFormSubmissionStatus({ formType });

  const { t: tCdn } = useTranslation(Translation.Cdn);

  const [addNewApplication, setAddNewApplication] = useState<boolean>(false);

  const CdnFormHandler = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Create,
        function: () => setAddNewApplication(true),
        label: tCdn('forms.createApplicationButton'),
        loading: formSubmitting,
      },
      {
        type: FormActionType.View,
        function: (data) => {
          navigate(`${data}/${ROUTES.configuration.main}/${ROUTES.configuration.dashboard}`);
        },
        label: tCdn('forms.view'),
      },
    ],
  });

  return (
    <div className="flex flex-1 flex-col space-y-8">
      <Breadcrumbs />
      <FormListLayout
        label={{
          title: tCdn('applicationList'),
          subTitle: (
            <Text $level={5} $customizeColor className="font-light text-gray-400">
              {tCdn('listOfWebsitesAndApplicationsThatAreUnderThisAccount')}
            </Text>
          ),
        }}
        tableData={{
          actionHandlers: CdnFormHandler,
          hasChildren: true,
          withoutCard: true,
          formType,
        }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 p-0.5 mt-8">
          {cdnData?.length ? (
            cdnData?.map((item: CdnData, index: number) => (
              <Cards key={index} rounded="rounded-xl" withoutPadding className="flex h-full w-full max-h-72">
                <CdnCards data={item} actionHandlers={CdnFormHandler} />
              </Cards>
            ))
          ) : loading ? (
            <CdnSkeleton />
          ) : (
            <Cards rounded="rounded-xl" withoutPadding className="flex max-h-72 h-full w-full">
              <div className="flex flex-1 flex-col items-center justify-center min-h-40">
                <Text $level={3} color="text-gray-400">
                  No Application found
                </Text>
              </div>
            </Cards>
          )}
        </div>
        {!!addNewApplication && (
          <CdnApplicationModal open={addNewApplication} onClose={() => setAddNewApplication(false)} />
        )}
      </FormListLayout>
    </div>
  );
};
