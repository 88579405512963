import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableTable } from '@components';
import { getCdnOriginHealthCheckListColumns } from '@constants';
import { FormType, ServiceModalName, Translation } from '@enums';
import { useAppDispatch } from '@hooks';
import { addServiceModal } from '@store';
import type { ServiceModalPayload } from '@types';

export const useHealthCheckTableConfiguration = () => {
  const dispatch = useAppDispatch();

  const { t: tOriginModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.origin' });
  const { t: tForms } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.origin' });
  const { t: tCommon } = useTranslation(Translation.Common);

  const formType = FormType.CdnOrigin;
  const originHealthCheckList = useMemo(() => getCdnOriginHealthCheckListColumns(), [tForms]);

  const healthCheckTablePayload = useCallback(
    (rawData) => ({
      name: ServiceModalName.ViewModal,
      payload: {
        label: {
          title: tOriginModal('originServiceStatus'),
          text: tOriginModal('realTimeMonitoringStatusOfOriginServersAndServiceEndpoints'),
          confirmButton: tCommon('button.close'),
        },
        option: {
          modalTable: {
            formType,
            children: <DraggableTable rows={rawData} columns={originHealthCheckList} formType={formType} />,
          },
          button: {
            loading: false,
            show: {
              confirmButton: true,
            },
            fullWidth: true,
          },
          size: 'md',
        },
      } as ServiceModalPayload,
    }),
    [tCommon],
  );

  const addNewHealthCheckStatusModal = useCallback(
    (rawData: any) => {
      dispatch(addServiceModal(healthCheckTablePayload(rawData)));
    },
    [healthCheckTablePayload],
  );
  return {
    modal: { healthCheckTablePayload: healthCheckTablePayload },
    function: { handleHealthStatusModal: addNewHealthCheckStatusModal },
  };
};
