import { useFormikContext } from 'formik';
import { memo } from 'react';
import { Fragment } from 'react/jsx-runtime';

import { FormField } from '@components';
import { InputType } from '@enums';
import { validateValueEqual } from '@utils';

export const GFormFields = memo(({ inputList = [], touched = {}, errors = {} }: any) => {
  const { values } = useFormikContext<Record<string, any>>();

  return inputList.map((inputForm, index) => {
    const isCustomForm = validateValueEqual(inputForm.inputType, InputType.Custom);

    if (inputForm.hide) {
      const { field, condition } = inputForm.hide;
      const watchedValue = values[field];
      if (condition(watchedValue)) {
        return null;
      }
    }

    return (
      <Fragment key={`${inputForm.name}-${index}`}>
        <FormField
          label={inputForm.label}
          name={inputForm.name}
          smallFont
          withoutLabel={!inputForm.label}
          isCorrect={!!inputForm.isCorrect && touched[inputForm.name] && !errors[inputForm.name]}
          direction={inputForm.direction}
          loading={inputForm.loading}
          disabled={inputForm.disabled}
          inputType={inputForm.inputType}
          defaultValue={inputForm.defaultValue}
          placeholder={inputForm.placeholder}
          required={inputForm.required}
          options={inputForm.options}
          fullWidth
        >
          {isCustomForm && inputForm.children}
        </FormField>
      </Fragment>
    );
  });
});
