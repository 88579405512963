import { DefaultPort, originType } from '@core/enums';
import { domainValidationReg, ipValidationReg } from '@core/regexp';
import i18n from 'i18next';
import * as Yup from 'yup';

const portValidation = Yup.number()
  .required(() => i18n.t('validation:origin.portRequired'))
  .min(1, () => i18n.t('validation:origin.portRange'))
  .max(65535, () => i18n.t('validation:origin.portRange'))
  .test('scheme-port-match', function (value) {
    if (!value) return true;

    const type = this.from?.[1]?.value?.type;

    if (type === originType.HTTPS && value === DefaultPort.HTTP) {
      return this.createError({
        message: i18n.t('validation:origin.portHttpsNotAllowed'),
      });
    }

    if (type === originType.HTTP && value === DefaultPort.HTTPS) {
      return this.createError({
        message: i18n.t('validation:origin.portHttpNotAllowed'),
      });
    }

    return true;
  });

export const originFormSchema = Yup.object().shape({
  name: Yup.string().required(() => i18n.t('validation:origin.originRequired')),
  type: Yup.string().required(() => i18n.t('validation:origin.originTypeRequired')),
  origins: Yup.array()
    .when('origin_type', {
      is: 'ip',
      then: () =>
        Yup.array()
          .of(
            Yup.object().shape({
              origin: Yup.string()
                .matches(
                  ipValidationReg,
                  () => `${i18n.t('validation:origin.checkIpFormat')} (${i18n.t('validation:format.ip')})`,
                )
                .required(() => i18n.t('validation:origin.IpIsRequired')),
              port: portValidation,
            }),
          )
          .min(1, () => i18n.t('validation:origin.minimumOneIp')),
    })
    .when('origin_type', {
      is: 'domain',
      then: () =>
        Yup.array()
          .of(
            Yup.object().shape({
              origin: Yup.string()
                .matches(
                  domainValidationReg,
                  () => `${i18n.t('validation:origin.checkDomainFormat')} (${i18n.t('validation:format.domain')})`,
                )
                .required(() => i18n.t('validation:origin.DomainIsRequired')),
              port: portValidation,
            }),
          )
          .min(1, () => i18n.t('validation:origin.minimumOneDomain')),
    }),
  enable_proxy_host: Yup.boolean().oneOf([true, false]),
  proxy_host: Yup.string().when('enable_proxy_host', {
    is: true,
    then: () =>
      Yup.string()
        .required(() => i18n.t('validation:origin.filledHostHeaderRequired'))
        .matches(
          domainValidationReg,
          () => `${i18n.t('validation:origin.checkOverwriteHostHeaderFormat')} (${i18n.t('validation:format.domain')})`,
        ),
  }),
});
