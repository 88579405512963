import i18n from '@core/i18n';

import { Badge, Column, Text } from '@components';
import { CellType } from '@enums';
import { capitalize, formatTableTimestamp, getColumnSizes } from '@utils';

export const getDashboardAttackLogsColumns = (): Column[] => [
  {
    label: i18n.t('configuration:tables.id'),
    value: 'id',
    multiLines: true,
    fontSize: 5,
    labelStyle: 'font-medium',
    ...getColumnSizes(40),
  },
  {
    label: i18n.t('configuration:tables.logInformation'),
    value: '',
    multiLines: true,
    cellType: CellType.Custom,
    format: (_value, rowData) => {
      const logEntries = [
        { label: i18n.t('configuration:tables.ipAddress'), value: rowData?.remote_addr ?? '-' },
        { label: i18n.t('configuration:tables.host'), value: rowData?.host ?? '-' },
        {
          label: i18n.t('configuration:tables.request'),
          value: `${rowData?.request ?? '-'}`,
        },
        { label: i18n.t('configuration:tables.header'), value: rowData?.header ?? '-' },
      ];
      return (
        <div className="flex flex-col space-y-4 select-text">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <Text $level={5} className="font-medium" color="black">
                {entry.label}
              </Text>
              <Text $level={5} className="font-light w-full whitespace-break-spaces break-words">
                {entry.value}
              </Text>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(180),
  },
  {
    label: i18n.t('configuration:tables.reason'),
    value: 'matches',
    multiLines: true,
    cellType: CellType.Custom,
    format: (value) => {
      const ruleName = value.map(({ group }) => group);
      const message = value.map(({ message }) => message);
      const keywordGroup = value.map(({ matches = [] }: { matches: string[] }) =>
        matches.map((item) => (item?.toString() === '0' ? '' : item)),
      );

      const logEntries = [
        { label: i18n.t('configuration:tables.rule'), value: ruleName },
        { label: i18n.t('configuration:tables.keyword'), value: keywordGroup },
        {
          label: i18n.t('configuration:tables.messages'),
          value: message,
        },
      ];
      return (
        <div className="flex flex-col space-y-4 select-text h-full">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <Text $level={5} className="font-medium" color="black">
                {entry.label}
              </Text>
              <Text $level={5} className="font-light w-full whitespace-break-spaces break-words">
                {entry.value}
              </Text>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(180),
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'action',
    format: (value = '') => (
      <div className="h-full">
        <Badge color={value === 'close-connection' ? 'red' : 'green'} className="self-start px-2.5 py-1">
          {value ? capitalize(value) : capitalize('Close Connection')}
        </Badge>
      </div>
    ),
    cellType: CellType.Custom,
    multiLines: true,
    dotsTextOverflow: true,
    ...getColumnSizes(180),
  },
  {
    label: i18n.t('configuration:tables.logDate'),
    value: 'matches',
    format: (value) => formatTableTimestamp(value),
    fontSize: 5,
    labelStyle: 'font-medium',
    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(120),
  },
];
