import { useEffect, useState } from 'react';

export const useImagePreload = (imageSrc: string) => {
  const [state, setState] = useState<{
    isLoading: boolean;
    error: string | null;
  }>({ isLoading: true, error: null });

  useEffect(() => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => setState({ isLoading: false, error: null });
    img.onerror = () => setState({ isLoading: false, error: 'Failed to load image' });

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imageSrc]);

  return state;
};
