import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants';
import { invitationStorage } from '@utils';

import { useLoginLoading, useUserAuthenticated } from '@store';

export const useInvitation = () => {
  const navigate = useNavigate();
  const loginLoading = useLoginLoading();
  const isAuthenticated = useUserAuthenticated();
  const invitationData = invitationStorage.get();

  useEffect(() => {
    if (loginLoading || !isAuthenticated) {
      return;
    }

    if (invitationData) {
      const { workspaceId, token } = invitationData;
      navigate(`${ROUTES.workspace.main}/${workspaceId}/invite?token=${token}`);
    } else {
      navigate(ROUTES.homepage);
    }
  }, [isAuthenticated, loginLoading]);

  return { invitationData };
};
