import classNames from 'classnames';
import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { SelectHTMLAttributes } from 'react';

import { Skeleton } from '@components';
import { InputMod, VariantType } from '@enums';
import { Options } from '@types';
import { validateEmptyValue, validateValueEqual } from '@utils';
import ErrorMessage from './TextInput/errorMessage';

export interface SelectInputProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  adornment?: string;
  name: string;
  errorClassName?: string;
  options?: Options;
  placeholder?: string;
  className?: string;
  variant?: VariantType;
  format?: (value: string) => string;
  withoutRing?: boolean;
  isAdornment?: boolean;
  fullWidth?: boolean;
  customWidth?: string;
  inputMod?: InputMod;
  smallFont?: boolean;
  withoutPadding?: boolean;
  loading?: boolean;
  label?: string;
}
export const SelectInput: React.FC<SelectInputProps> = ({
  disabled,
  withoutRing,
  isAdornment = false,
  name,
  errorClassName = '',
  options,
  loading = false,
  placeholder = '',
  className = '',
  inputMod = InputMod.Default,
  format,
  onChange,
  customWidth,
  fullWidth = false,
  variant = VariantType.Normal,
  smallFont,
  withoutPadding,
  label = '',
}: SelectInputProps) => {
  const [field, meta, helpers] = useField(name);
  const isError = meta.error && meta.touched;

  const isCustom = validateValueEqual(VariantType.Custom, variant);
  const isFilled = validateValueEqual(InputMod.Filled, inputMod);

  const isValueValid = !validateEmptyValue(field.value);

  const dynamicClasses = classNames('block rounded-md border-0 placeholder-gray-400 text-ellipsis', {
    'pl-2.5 pr-8 py-1.5': !isCustom && !withoutPadding,
    'text-us': smallFont,
    'text-sm px-3.5 py-2': !smallFont && !withoutPadding,
    'ring-1 ring-theme-border-default focus:ring-2 focus:ring-indigo-600': !withoutRing,
    'ring-0 focus:ring-0 ': withoutRing,
    'ring-red-500': isError,
    'bg-theme-primary-light hover:cursor-not-allowed': disabled,
    'hover:cursor-pointer text-black': !disabled,
    'text-gray-400': disabled,
    'w-full': fullWidth,
    'w-full px-3 text-sm peer outline-none border-0 rounded': isFilled,
    'pt-6 pb-2': isFilled && isValueValid,
    'group-focus-within:pt-10 peer-valid:pt-10 peer-valid:pb-5 group-focus-within:pb-5': isFilled && !isValueValid,
    [className]: className,
  });

  const dynamicSkeletonClass = classNames('h-10 rounded-md', {
    'w-full': fullWidth,
  });

  if (loading) return <Skeleton className={dynamicSkeletonClass} />;

  return (
    <div className={`flex flex-col h-fit space-y-3 ${fullWidth ? 'w-full' : ''} ${customWidth ?? ''}`}>
      <div className="relative group flex items-center">
        <select
          {...field}
          id={name}
          name={name}
          aria-invalid={!!(meta.error && meta.touched)}
          disabled={disabled}
          aria-describedby={isError ? `${name}-error` : undefined}
          className={`${dynamicClasses}`}
          onChange={onChange ?? ((e) => helpers.setValue(e.target.value))}
        >
          {placeholder && (
            <option disabled value="">
              {placeholder}
            </option>
          )}
          {options?.map(
            (option) =>
              option.value && (
                <option key={option.value} value={option.value} disabled={option.disabled} className="truncate">
                  {format ? format(`${option.label}`) : option.label}
                </option>
              ),
          )}
        </select>
        {isFilled && label && (
          <label
            htmlFor={name}
            className={`transform transition-all text-gray-400 absolute top-0 left-0 flex items-center 
            translate-x-2 group-focus-within:translate-x-0 peer-valid:translate-x-0
        group-focus-within:translate-y-0.5 peer-valid:translate-y-0.5
        h-full group-focus-within:h-1/2 peer-valid:h-1/2 peer-disabled:h-1/2 peer-disabled:translate-y-0.5
       pl-2 disabled:pl-0 group-focus-within:pl-3 peer-valid:pl-3 peer-disabled:pl-1
       ${isValueValid ? 'text-smLabel font-medium' : 'text-xs group-focus-within:text-smLabel peer-valid:text-[0.58rem] group-focus-within:font-medium peer-valid:font-medium'}`}
          >
            {label}
          </label>
        )}
      </div>
      <AnimatePresence>
        {isError && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ErrorMessage isError={isError} errorClassName={errorClassName} meta={meta} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
