export const domainValidationReg = /^[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*\.[a-zA-Z]{2,}$/;
export const combinedDomainValidationReg =
  /^(\*\.[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[a-zA-Z]{2,}|[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*\.[a-zA-Z]{2,})$/;

export const ipValidationReg = /^(?:\d{1,3}\.){3}\d{1,3}$/;
export const ipWithCidrValidationReg = /^(?:\d{1,3}\.){3}\d{1,3}(?:\/(?:[\d]|[1-2][\d]|3[0-2]))?$/;

export const urlRegexp = /^\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]*$/;
export const portRegexp = /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
export const hostNameRegexp = /^\/[\w\\/.-]*$/;
export const prefixRegexp = /^\/[\w\\/.-]*$/;
export const configurationIdRegexp = /\/cdn\/(\d+)\/configuration\//;
export const workSpaceIdRegexp = /[?&]workspaceId=([a-zA-Z\d]+_?\d*)/;

export const publicKeyRegexp = /^-----BEGIN CERTIFICATE-----\n([A-Za-z0-9+/=\n]+)\n-----END CERTIFICATE-----$/;
export const privateKeyRegexp = /^-----BEGIN PRIVATE KEY-----\n([A-Za-z0-9+/=\n]+)\n-----END PRIVATE KEY-----$/;
