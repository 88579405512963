import i18n from 'i18next';
import { SendIcon } from 'lucide-react';

import { Badge, Button, Column, Text } from '@components';
import { CellType, Dictionaries } from '@enums';
import { getCodeValueFromCatalog, getColumnSizes, validateValueEqual } from '@utils';

export const getWsConfigMemberColumns = ({ handleStatus, isOwner }): Column[] => {
  return [
    {
      label: i18n.t('configuration:tables.id'),
      value: 'id',
      ...getColumnSizes(50),
    },
    {
      label: i18n.t('configuration:tables.name'),
      value: 'name',
      ...getColumnSizes(200),
      format: (value, fullData) => (
        <div className="flex space-x-3 items-center">
          <Text $level={4} color="text-black" className="truncate">
            {value}
          </Text>
          <Text $level={6} color="text-gray-400" className="truncate font-light">
            {`@${fullData?.email}`}
          </Text>
        </div>
      ),
    },
    {
      label: i18n.t('configuration:tables.accessLevel'),
      value: 'permission',
      cellType: CellType.Custom,
      format: (value, row) => (
        <Text $level={5} $customizeColor className="truncate">
          {getCodeValueFromCatalog(Dictionaries.Roles, value)}
        </Text>
      ),
      ...getColumnSizes(90),
    },
    {
      label: i18n.t('configuration:tables.status'),
      value: 'status',
      cellType: CellType.Custom,
      format: (value, fullData) => (
        <div className="flex gap-2 items-center">
          <Badge
            color={
              validateValueEqual(value, 'pending') ? 'amber' : validateValueEqual(value, 'active') ? 'green' : 'red'
            }
            className={'self-start px-2.5 py-1 ellipsis-text'}
          >
            <Text $level={5} $customizeColor className="truncate">
              {getCodeValueFromCatalog(Dictionaries.WorkspaceMemberStatus, value)}
            </Text>
          </Badge>
          {!!validateValueEqual(value, 'pending') && isOwner && (
            <Button
              hoverEffect
              hint={
                <Text color="text-white" $level={6}>
                  {i18n.t('common:button.resendInvitation')}
                </Text>
              }
              withoutPadding
              customizeColor
              iconWithoutM
              rounded="rounded-md"
              className="-my-0.5 p-1.5"
              onClick={() => handleStatus(fullData)}
              icon={<SendIcon className="size-4 text-blue-400" />}
            />
          )}
        </div>
      ),
      ...getColumnSizes(90),
    },
    {
      label: i18n.t('configuration:tables.action'),
      value: 'actions',
      cellType: CellType.Action,
      ...getColumnSizes(60),
    },
  ];
};
