import { HomeIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button, Skeleton } from '@components';
import { ROUTES } from '@constants';
import { FormType, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import { fetchFormList, useCdnSummaryLoading, useCurrentWorkspaceId, useFormLoadingStatus } from '@store';
import { NavigatorButton } from './subComponents/NavigatorButton';

export const Breadcrumbs = ({ theme = 'dark' }: { theme?: 'light' | 'dark' }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const { id: application_id } = useParams();

  const formType = FormType.Cdn;

  const workspace_id = useCurrentWorkspaceId();
  const cdnLoading = useFormLoadingStatus({ formType });
  const cdnSummaryLoading = useCdnSummaryLoading();

  useEffect(() => {
    if (pathname.includes('cdn') && !application_id) {
      dispatch(fetchFormList({ formType, params: { workspace_id } }));
    }
  }, [workspace_id]);

  if (cdnLoading || cdnSummaryLoading) return <Skeleton className="h-5 w-40" />;
  return (
    <nav
      className={`flex bg-opacity-90 w-full rounded-xl ${theme === 'dark' ? 'text-theme-primary-main' : 'text-white'}`}
      aria-label="Breadcrumb"
    >
      <ol className="flex items-center space-x-3">
        <li className="flex gap-x-3">
          <Button
            onClick={() => navigate(ROUTES.login)}
            withoutPadding
            iconWithoutM
            variant={VariantType.Transparent}
            withoutScale
            rounded="rounded-none"
            customizeColor
            className="text-sm hover:cursor-default text-gray-600 hover:text-gray-500"
            icon={<HomeIcon className="size-5" />}
          />
        </li>
        <NavigatorButton />
      </ol>
    </nav>
  );
};
