import i18n from 'i18next';

import { Column, Text } from '@components';
import { CellType } from '@enums';
import { formatTableTimestamp, getColumnSizes } from '@utils';

export const cdnErrorLogTable = (): Column[] => [
  {
    label: i18n.t('configuration:tables.id'),
    value: 'id',
    multiLines: true,
    fontSize: 5,
    labelStyle: 'font-medium',
    ...getColumnSizes(60),
  },
  {
    label: i18n.t('configuration:tables.level'),
    value: 'level',
    cellType: CellType.Custom,
    fontSize: 5,
    labelStyle: 'font-medium',
    multiLines: true,
    ...getColumnSizes(90),
  },
  {
    label: i18n.t('configuration:tables.logInformation'),
    value: '',
    multiLines: true,
    cellType: CellType.Custom,
    format: (_value, rowData) => {
      const logEntries = [
        { label: i18n.t('configuration:tables.requestId'), value: rowData?.req_id ?? '_' },
        { label: i18n.t('configuration:tables.host'), value: rowData?.host ?? '-' },
        { label: i18n.t('configuration:tables.upstream'), value: rowData?.upstream ?? '-' },
        { label: i18n.t('configuration:tables.client_ip'), value: rowData?.client_ip ?? '-' },
      ];
      return (
        <div className="flex flex-col space-y-4 select-text">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <Text $level={5} className="font-medium" color="black">
                {entry.label}
              </Text>
              <Text $level={5} className="font-light w-full whitespace-break-spaces break-words">
                {entry.value}
              </Text>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(240),
  },
  {
    label: i18n.t('configuration:tables.messages'),
    value: 'message',
    cellType: CellType.Custom,
    fontSize: 5,
    labelStyle: 'text-zinc-500',
    multiLines: true,
    ...getColumnSizes(280),
  },
  {
    label: i18n.t('configuration:tables.logDate'),
    value: 'timestamp',
    format: (value) => formatTableTimestamp(value),
    fontSize: 5,
    labelStyle: 'font-medium',
    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(120),
  },
];
