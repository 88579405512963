import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { ROUTES } from '@constants';
import { accessToken, clearAllStorageData, refreshToken } from '@core/storage';
import { Headers } from '@enums';
import {
  clearLoginError,
  executeRecaptcha,
  executeRecaptchaError,
  executeRecaptchaSuccess,
  getUserSummary,
  getUserSummarySuccess,
  login,
  loginError,
  loginSuccess,
  logOut,
  resetAuthState,
} from '@store';
import type { ApiErrorResponse, RecaptchaRef, UserLoginFormValues } from '@types';
import { API, getCurrentUnixTime, invitationStorage, setHeaders } from '@utils';

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  token_type: string;
}

export function* logOutSaga() {
  yield call(clearAllStorageData);
  yield call(invitationStorage.clear);
  yield call([window.location, 'replace'], ROUTES.login);
}

function* createRecaptchaPromise(recaptchaRef: RecaptchaRef) {
  let cleanup = () => {};

  try {
    const grecaptcha = (window as any).grecaptcha;
    if (!grecaptcha || !recaptchaRef?.current) {
      throw new Error('Recaptcha not initialized');
    }
    const recaptchaPromise = new Promise((resolve, reject) => {
      const handleOutsideClick = (e: MouseEvent) => {
        const recaptchaFrame = document.querySelector('iframe[title*="recaptcha"]');
        if (recaptchaFrame && !recaptchaFrame.contains(e.target as Node)) {
          cleanup();
          reject(new Error('MODAL_CLOSED'));
        }
      };

      cleanup = () => {
        window.removeEventListener('click', handleOutsideClick);
      };

      window.addEventListener('click', handleOutsideClick);

      if (recaptchaRef?.current) {
        recaptchaRef?.current.executeAsync().then(resolve).catch(reject);
      }
    });

    const token = yield call(() => recaptchaPromise);

    if (!token) {
      throw new Error('RECAPTCHA_FAILED');
    }

    return token;
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === 'MODAL_CLOSED') {
        throw new Error('Please complete the reCAPTCHA verification');
      }
      if (error.message === 'RECAPTCHA_FAILED') {
        throw new Error('ReCAPTCHA verification failed, please try again');
      }
    }
    throw error instanceof Error ? error : new Error('ReCAPTCHA validation failed');
  }
}

export function* executeRecaptchaSaga({ payload }: PayloadAction<RecaptchaRef>) {
  try {
    const result = yield call(createRecaptchaPromise, payload);
    yield put(executeRecaptchaSuccess(result));
  } catch (error: unknown) {
    payload.current?.reset();
    const errorMessage = error instanceof Error ? error.message : 'ReCAPTCHA execution failed';
    yield put(executeRecaptchaError(errorMessage ?? 'ReCAPTCHA error'));
  }
}

const getErrorMessage = (error: unknown): string => {
  if (!error) {
    return 'Login failed';
  }

  if (error instanceof Error) {
    if ('response' in error) {
      const responseMessage = (error as ApiErrorResponse).response?.data?.message;
      if (responseMessage) {
        return responseMessage;
      }
    } else {
      return error.message;
    }
  }
  return 'Login failed';
};

function* userLoginSaga({
  payload,
}: PayloadAction<{
  values: UserLoginFormValues;
  recaptchaRef: RecaptchaRef;
}>) {
  const { values, recaptchaRef } = payload;
  try {
    yield put(executeRecaptcha(recaptchaRef));

    const recaptchaResult = yield race({
      success: take(executeRecaptchaSuccess.type),
      error: take(executeRecaptchaError.type),
    });

    if (recaptchaResult.error) {
      const error = recaptchaResult.error.payload;
      yield put(loginError(error));
      return;
    }

    const { recaptchaToken } = yield select((state) => state.auth);

    if (!recaptchaToken) {
      throw new Error('No reCAPTCHA token available');
    }

    const { data } = yield call(API.post, '/user/login', {
      ...values,
      recaptcha_token: recaptchaToken,
      next_action: true,
    });
    if (data.access_token && data.refresh_token) {
      yield all([
        call(accessToken.setItem, data.access_token),
        call(refreshToken.setItem, data.refresh_token),
        call(setHeaders, { [Headers.Authorization]: `Bearer ${data.access_token}` }),
      ]);
      yield all([put(clearLoginError()), put(getUserSummary())]);
      yield take(getUserSummarySuccess.type);
      yield put(loginSuccess());
    } else if (data.next_action && data.message) {
      yield put(
        loginError({
          nextAction: {
            type: data.next_action,
            email: values.username,
            timestamp: getCurrentUnixTime(),
          },
        }),
      );
    } else window.location.reload();
  } catch (error) {
    yield put(resetAuthState());
    recaptchaRef.current?.reset();
    const errorMessage: string = getErrorMessage(error as ApiErrorResponse);
    yield put(loginError({ error: errorMessage }));
  }
}

export function* authSagaWatcher() {
  yield takeLatest(login.type, userLoginSaga);
  yield takeLatest(logOut.type, logOutSaga);
  yield takeLatest(executeRecaptcha.type, executeRecaptchaSaga);
}
