import { DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Button, DialogModal, ModalBody, Text } from '@components';
import GeneralInfoBlock from '@components/Cards/GeneralInfoBlock';
import { FormType, VariantType } from '@enums';
import { useFormContent } from '@store';
import { capitalizeFirstLetter, formatTableTimestamp } from '@utils';
import { cdnWafLogDetailInfo } from '@constants/components/cdnWafLogDetailInfo';
import i18n from '@core/i18n';

interface WafLogDetailModalProps {
  open: boolean;
  onClose: () => void;
  formType: FormType;
}

export const WafLogDetailModal = ({ open, formType, onClose }: WafLogDetailModalProps) => {
  const wafLogDetail = useFormContent({ formType });
  const formattedDate = formatTableTimestamp(wafLogDetail.created_unix, 'MMM dd, yyyy h:mm a');

  if (!open) return;

  return (
    <DialogModal withoutPadding notForm open onClose={onClose} size="3xl">
      <DialogPanel>
        <div className="relative items-center p-8 border-b">
          <div className="absolute top-6 right-8">
            <Button
              variant={VariantType.Transparent}
              withoutPadding
              iconWithoutM
              className="p-2.5"
              rounded="rounded-full"
              hoverEffect
              icon={<XMarkIcon className="w-5 h-5 " onClick={onClose} />}
            />
          </div>
          <div className="space-y-5">
            <div className="space-y-1.5">
              <Text $level={5} className=" leading-4">
                {formattedDate}
              </Text>
              <div className="flex items-center gap-4">
                <Text $level={2} className="font-medium break-words" color="black">
                  {wafLogDetail.host ?? '-'}
                </Text>
              </div>
            </div>
            <div className="grid cols-span-2 md:flex justify-between ">
              <div className="flex gap-8">
                <div className="flex flex-col gap-2">
                  <Text $level={6} className="font-light">
                    {i18n.t('configuration:tables.status')}
                  </Text>
                  <div className="px-2 py-1 bg-slate-600 text-white rounded-md">
                    <Text $customizeColor $level={6} className="font-medium">
                      {capitalizeFirstLetter(wafLogDetail.action ?? '-')}
                    </Text>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <Text $level={6} className="font-light">
                    {i18n.t('configuration:tables.ipAddress')}
                  </Text>
                  <Text $customizeColor $level={5} className="font-medium pt-0.5">
                    {wafLogDetail.remote_addr ?? '-'}
                  </Text>
                </div>
              </div>
              <div className="flex flex-col gap-2 pt-2 md:pt-0 md:text-right">
                <Text $level={6} className="font-light">
                  {i18n.t('configuration:tables.requestId')}
                </Text>
                <Text $customizeColor $level={5} className="font-medium pt-0.5">
                  {wafLogDetail.request_id ?? '-'}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <ModalBody notForm className="max-w-full p-8">
          {wafLogDetail &&
            Object.entries(cdnWafLogDetailInfo(wafLogDetail)).map(([title, items], index, arr) => {
              const isLast = index === arr.length - 1;
              return <GeneralInfoBlock key={title} title={title} items={items} isLast={isLast} />;
            })}
        </ModalBody>
      </DialogPanel>
    </DialogModal>
  );
};
