import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { Direction } from '@enums';
import { FormField } from '../Forms';

export const SearchInput = ({ name, ...props }) => {
  return (
    <FormField
      {...props}
      name={name}
      type="search"
      withoutLabel
      fullWidth
      adornmentPosition={Direction.Right}
      smallFont
      adornment={<MagnifyingGlassIcon className="w-4 h-4 self-center text-gray-400" />}
    />
  );
};
