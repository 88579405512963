import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';

import { FormField, PredefinedCards, Skeleton, ToggleButton } from '@components';
import { appConfigurationFormSchema, SCREEN_BREAKPOINTS } from '@constants';
import { columnDirection, FormType, InputMod, InputType, StorageUnits, Translation } from '@enums';
import { useAppDispatch, useErrorFormInner, useMediaQuery } from '@hooks';
import {
  getCdnSummary,
  submitFormRequest,
  useCdnSummary,
  useCdnSummaryLoading,
  useFormError,
  useFormSubmissionStatus,
} from '@store';
import { convertEnumToObjectArray, filterSelectedValues } from '@utils';

export const AppConfigurationForm = ({ onFormikInstance }) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnApplicationConfiguration;

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.appConfiguration' });
  const { t: tForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.appConfiguration' });

  const isAboveSm = useMediaQuery(`(min-width: ${SCREEN_BREAKPOINTS.sm}px)`);

  const isSubmitting = useFormSubmissionStatus({ formType });
  const submitError = useFormError({ formType });
  const isOptionLoading = useCdnSummaryLoading();
  const formLoading = useFormSubmissionStatus({ formType });

  const {
    configuration,
    name,
    http_port,
    https_port,
    max_body_size,
    max_post_argument,
    max_url_argument,
    enable_websocket,
  } = useCdnSummary();

  const {
    http_port: httpPortOption,
    https_port: httpsPortOption,
    max_body_size: maxBodySizeMaxValue,
    max_post_argument: maxPostArgumentMaxValue,
    max_url_argument: maxUrlArgumentMaxValue,
    default_http_port,
    default_https_port,
    default_url_argument,
    default_post_argument,
    default_body_size,
  } = configuration ?? [];

  const initialAppConfigurationValues = {
    http_port: http_port ? filterSelectedValues(httpPortOption, http_port) : default_http_port,
    https_port: https_port ? filterSelectedValues(httpsPortOption, https_port) : default_https_port,
    max_body_size: max_body_size ?? default_body_size,
    max_post_argument: max_post_argument ?? default_post_argument,
    max_url_argument: max_url_argument ?? default_url_argument,
    max_body_size_unit: 'mb',
    enable_websocket: enable_websocket ?? true,
    name: name ?? '',
  };

  const handleSubmit = (values) => {
    const { max_body_size_unit, ...updatedValues } = values;
    dispatch(
      submitFormRequest({
        formType,
        formData: { ...updatedValues },
        params: { content_id: application_id },
        globalOptions: {
          returnResult: true,
          content: true,
        },
        callbackFunction: { refetch: getCdnSummary(application_id) },
      }),
    );
  };

  const AppConfigurationErrorInner = () => {
    useErrorFormInner({ submitError, isSubmitting });
    return null;
  };

  if (isOptionLoading) {
    return (
      <div className="space-y-8">
        {[...Array(6)].map((_, index) => (
          <Skeleton key={index} className="flex w-full rounded-2xl h-32" />
        ))}
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialAppConfigurationValues}
      validationSchema={appConfigurationFormSchema}
      initialTouched={{ max_body_size: true, max_post_argument: true, max_url_argument: true }}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        if (onFormikInstance) onFormikInstance(formik);
        return (
          <Fragment>
            <AppConfigurationErrorInner />
            <PredefinedCards title={tTabs('applicationName')} description={tTabs('updateApplicationName')}>
              <FormField
                label={tTabs('applicationName')}
                name="name"
                smallFont
                inputMod={InputMod.Filled}
                withoutLabel
                loading={formLoading}
                fullWidth
                inputType={InputType.Text}
                placeholder={tForm('applicationNamePlaceHolder')}
              />
            </PredefinedCards>
            <PredefinedCards
              title={tTabs('httpPort')}
              description={tTabs('httpPortDescription')}
              showChip
              selectedInput={formik?.values?.http_port}
            >
              <FormField
                label={tTabs('httpPort')}
                name="http_port"
                smallFont
                inputMod={InputMod.Filled}
                withoutLabel
                options={httpPortOption}
                withoutChip
                loading={formLoading}
                placeholder={tForm('httpPlaceHolder')}
                customizeColor
                textInputClassName="bg-white"
                fullWidth
                inputType={InputType.MultipleSelectInput}
                disabledSearch
              />
            </PredefinedCards>
            <PredefinedCards
              title={tTabs('httpsPort')}
              description={tTabs('httpsPortDescription')}
              selectedInput={formik?.values?.https_port}
              showChip
            >
              <FormField
                label={tTabs('httpsPort')}
                name="https_port"
                placeholder={tForm('httpsPlaceHolder')}
                smallFont
                inputMod={InputMod.Filled}
                loading={formLoading}
                withoutLabel
                options={httpsPortOption}
                withoutChip
                customizeColor
                textInputClassName="bg-white"
                fullWidth
                inputType={InputType.MultipleSelectInput}
                disabledSearch
              />
            </PredefinedCards>
            <PredefinedCards title={tTabs('maxBodySize')} description={tTabs('maxBodySizeDescription')}>
              <FormField
                label={tTabs('maxBodySize')}
                name="max_body_size"
                type="number"
                min={1}
                max={maxBodySizeMaxValue}
                inputMod={InputMod.Filled}
                withoutLabel
                loading={formLoading}
                smallFont
                adornmentWithoutM
                customizeColor
                textInputClassName="bg-white"
                fullWidth
                adornment={
                  <FormField
                    label={tForm('unit')}
                    name="max_body_size_unit"
                    inputType={InputType.Select}
                    withoutLabel
                    inputMod={InputMod.Filled}
                    smallFont
                    centerItem
                    customWidth="w-20"
                    withoutRing
                    className="flex border-l"
                    loading={false}
                    placeholder={tForm('selectYourUnit')}
                    options={convertEnumToObjectArray(StorageUnits)}
                  />
                }
              />
            </PredefinedCards>
            <PredefinedCards title={tTabs('maxPostArguments')} description={tTabs('maxPostArgumentsDescription')}>
              <FormField
                label={tTabs('maxPostArguments')}
                name="max_post_argument"
                type="number"
                min={1}
                max={maxPostArgumentMaxValue}
                inputMod={InputMod.Filled}
                loading={formLoading}
                withoutLabel
                smallFont
                adornmentWithoutM
                customizeColor
                textInputClassName="bg-white"
                fullWidth
              />
            </PredefinedCards>
            <PredefinedCards title={tTabs('maxUrlArguments')} description={tTabs('maxUrlArgumentsDescription')}>
              <FormField
                label={tTabs('maxUrlArguments')}
                name="max_url_argument"
                type="number"
                min={1}
                max={maxUrlArgumentMaxValue}
                loading={formLoading}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                adornmentWithoutM
                customizeColor
                textInputClassName="bg-white"
                fullWidth
              />
            </PredefinedCards>
            <PredefinedCards
              besideTitle={!isAboveSm}
              title={tForm('webSocket')}
              description={
                <div className="space-y-5">
                  <article className="text-xs font-light text-justify text-gray-400">
                    {tForm('webSocketDescription')}
                  </article>
                </div>
              }
            >
              <FormField
                withoutLabel
                label="Enabled"
                name="enable_websocket"
                loading={formLoading}
                fullWidth
                smallFont
                direction={columnDirection.Row}
                inputType={InputType.Custom}
              >
                <ToggleButton className="-ml-3" name="enable_websocket" />
              </FormField>
            </PredefinedCards>
          </Fragment>
        );
      }}
    </Formik>
  );
};
