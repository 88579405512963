import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { initData } from '@core/storage';
import { Dictionaries, Translation } from '@enums';
import { resources } from '@translations/config';

const DEFAULT_LANGUAGE = process.env.DEFAULT_LANGUAGE || 'en';

const config = {
  lng: (initData.getItem()?.language ?? DEFAULT_LANGUAGE) as 'en' | 'cn',
  ns: [...Object.values(Dictionaries), ...Object.values(Translation)],
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: { escapeValue: false },
  resources,
  react: { useSuspense: false },
};

export const initializeI18n = () => i18n.use(initReactI18next).init(config);
export const i18nInstance = initializeI18n();
export const getAvailableNamespaces = () => config.ns;
export const getCurrentLanguage = () => i18n.language as 'en' | 'cn';

export default i18n;
