export const ValidationRegExp = {
  // Basic patterns
  LATIN_LETTERS: /^[A-Za-z]+$/,
  SINGLE_LATIN_LETTER: /[a-zA-Z]/,
  ALPHANUMERIC: /^[A-Za-z0-9]+$/,
  NUMBERS: /^\d+$/,
  FIRST_CHAR_OF_WORD: /\b\w/g,

  // Number formats
  DECIMAL_NUMBERS: /^\d+(\.\d+)?$/,
  DECIMAL_TWO_PLACES: /^\d+(\.\d{1,2})$/,

  NAME: /^[A-Za-zÀ-ÿ '-]+$/,

  // Common validations
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  IP_ADDRESS: /^(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.){3}(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/,
  URL: /^https?:\/\/(?:[\w-]+\.)+[\w-]+(?:\/[\w-./?%&=]*)?$/,
  PHONE: /^\+?[1-9]\d{9,14}$/,
  DATE_ISO: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
  TIME_24H: /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/,
  URI: /^\/[a-zA-Z0-9\-._~!$&'()*+,;=:@%/]*$/,
  DOMAIN: /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/,
} as const;

export type ValidationPatternType = typeof ValidationRegExp;
