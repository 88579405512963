import { format, fromUnixTime } from 'date-fns';

export const formatTableTimestamp = (unixTimestamp, dateTimeFormat = 'dd/MM/yyyy hh:mm a') => {
  if (!unixTimestamp || isNaN(unixTimestamp)) {
    return 'Invalid timestamp';
  }

  const date = fromUnixTime(Number(unixTimestamp));
  return format(date, dateTimeFormat);
};
