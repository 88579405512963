import { AnimatePresence, motion } from 'framer-motion';
import { memo } from 'react';

import { ErrorIcon } from '@assets';
import { Text } from '@components/Text';

const ErrorMessage: React.FC<any> = ({ isError, errorClassName, meta }) => {
  if (!isError) return null;

  const formatError = (error: any): string[] => {
    if (Array.isArray(error)) {
      return error.flatMap(formatError);
    }
    if (typeof error === 'object' && error !== null) {
      return Object.values(error).flatMap(formatError);
    }
    if (typeof error === 'string') {
      return error.split('\n');
    }
    return [String(error)];
  };

  const errorLines = formatError(meta.error);

  if (meta.error)
    return (
      <AnimatePresence>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`overflow-hidden ${errorClassName}`}
        >
          <div className="flex items-start">
            <motion.div
              animate={{ rotate: [0, -5, 5, 0] }}
              transition={{ repeat: Infinity, duration: 2, ease: 'easeInOut' }}
              className="mr-2 flex-shrink-0"
            >
              <ErrorIcon size={18} />
            </motion.div>
            <Text $level={6} color="text-red-600" className="font-medium flex flex-col leading-4">
              {errorLines.map((line, index) => (
                <span key={index}>{line}</span>
              ))}
            </Text>
          </div>
        </motion.div>
      </AnimatePresence>
    );
};

export default memo(ErrorMessage);
