import { Edit2 } from 'lucide-react';
import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@components';
import { Translation } from '@enums';

import type { CallbackPayloadProps, LabelPayloadProps } from '@types';
import { InfoBlock } from '../InfoBlock';

interface InfoItem {
  left: string;
  right: ReactNode;
}

interface WsConfigCardProps {
  label?: LabelPayloadProps;
  infoItems: InfoItem[];
  callbackFunction?: CallbackPayloadProps;
}

export const WsConfigCard = ({
  label,
  infoItems,
  callbackFunction,
  children,
}: PropsWithChildren<WsConfigCardProps>) => {
  const { t: tCommon } = useTranslation(Translation.Common, { keyPrefix: 'button' });

  return (
    <div className="flex flex-col gap-6 w-full bg-white rounded-2xl shadow-card py-7 px-9">
      <div className="flex justify-between">
        <Text $level={2} $customizeColor className="font-medium">
          {label?.title}
        </Text>
        {!!callbackFunction?.click && (
          <Button icon={<Edit2 className="size-3" />} onClick={callbackFunction?.click}>
            <Text $level={5} $customizeColor>
              {label?.confirmButton ?? tCommon('edit')}
            </Text>
          </Button>
        )}
      </div>
      <div className="space-y-1">
        {infoItems.map((item, index) => (
          <InfoBlock
            key={index}
            label={{
              left: item.left,
              right: item.right,
            }}
          />
        ))}
      </div>
      {children}
    </div>
  );
};
