import { Formik } from 'formik';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormField, FormModal } from '@components';
import { originFormSchema } from '@constants';
import { determineOriginType } from '@core/utils/form/determineOriginType';
import {
  DefaultPort,
  Dictionaries,
  FormActionType,
  FormType,
  InputType,
  Translation,
  columnDirection,
  hostType,
  originType,
} from '@enums';
import { useAppDispatch } from '@hooks';
import { fetchFormList, submitFormRequest, useFormContent, useFormProcedure } from '@store';
import { getOptionListFromCatalog, validateValueEqual } from '@utils';
import { initializeOriginsValues, updateOriginsByType } from '../originUtils';

interface RouteFormProps {
  open: boolean;
  onClose: () => void;
}

export const AddOriginModal = ({ open, onClose }: RouteFormProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnOrigin;

  const currentForm = useFormContent({ formType });
  const currentProcedure = useFormProcedure(formType);

  const { t: tForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.origin' });
  const { t: tFormat } = useTranslation(Translation.Validation, { keyPrefix: 'format' });
  const { t: tModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.origin' });
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.origin' });

  const isEdit = validateValueEqual(currentProcedure, FormActionType.Edit);

  const handleSubmit = (values: any) => {
    const { origin_type, enable_proxy_host, proxy_host, origins, verify_ssl_cert, type, ...otherValues } = values;

    const formData = {
      ...(origins && {
        origins: origins.filter((entry) => entry.origin.trim() !== ''),
        type,
        verify_ssl_cert: type === originType.HTTPS ? values.verify_ssl_cert : false,
      }),
      ...(enable_proxy_host && {
        proxy_host: proxy_host,
      }),
      ...otherValues,
    };

    dispatch(
      submitFormRequest({
        formType,
        formData: isEdit ? formData : { application_id, ...formData },
        ...(isEdit && {
          params: {
            content_id: currentForm?.id,
          },
        }),
        callbackFunction: {
          refetch: fetchFormList({
            formType,
            params: { application_id },
            globalOptions: {
              pagination: true,
            },
          }),
        },
      }),
    );
  };
  const initialValues: any = {
    name: currentForm?.name ?? '',
    type: currentForm?.type ?? originType.HTTPS,
    origins: initializeOriginsValues(currentForm),
    verify_ssl_cert: currentForm?.verify_ssl_cert ?? false,
    origin_type: determineOriginType(currentForm?.origins, hostType) ?? hostType.Ip,
    enable_proxy_host: Boolean(currentForm.proxy_host),
    proxy_host: currentForm?.proxy_host ?? '',
  };

  if (!open) return;
  return (
    <Formik initialValues={initialValues} validationSchema={originFormSchema} onSubmit={handleSubmit}>
      {({ values, setValues }) => {
        const originsEmptyData = {
          origin: '',
          port: values.type === originType.HTTP ? DefaultPort.HTTP : DefaultPort.HTTPS,
        };
        const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value;
          const updatedOrigins = updateOriginsByType(value, values.origins);
          setValues((prevValues) => ({
            ...prevValues,
            type: value,
            origins: updatedOrigins,
          }));
        };

        return (
          <FormModal
            formType={formType}
            title={tModal(isEdit ? 'editOrigin' : 'createNewOrigin')}
            description={tModal(isEdit ? 'editOriginDescription' : 'addNewOriginDescription')}
            onClose={onClose}
            size="xl"
            open={open}
            confirmButton={{ children: tButton(isEdit ? 'editOrigin' : 'addOrigin') }}
          >
            <FormField
              label={tForm('originName')}
              smallFont
              name="name"
              placeholder={tForm('enterYourOriginName')}
              direction={columnDirection.Row}
              required
            />
            <FormField
              label={tForm('scheme')}
              name="type"
              inputType={InputType.Select}
              smallFont
              placeholder={tForm('selectYourScheme')}
              fullWidth
              direction={columnDirection.Row}
              options={getOptionListFromCatalog({ name: Dictionaries.OriginType })}
              required
              onChange={handleTypeChange}
            />
            {values.type === originType.HTTPS && (
              <FormField
                label={tForm('verifySSL')}
                name="verify_ssl_cert"
                inputType={InputType.Switch}
                smallFont
                direction={columnDirection.Row}
              />
            )}
            <FormField
              label={tForm('originType')}
              name="origin_type"
              inputType={InputType.Select}
              smallFont
              placeholder={tForm('selectYourOriginType')}
              fullWidth
              direction={columnDirection.Row}
              options={getOptionListFromCatalog({ name: Dictionaries.HostType })}
            />
            {values.origin_type === hostType.Ip && (
              <FormField
                label={tForm('ipList')}
                name="origins"
                nestedName="origin"
                nestedComponent={
                  <FormField
                    className="w-24"
                    name="port"
                    withoutLabel
                    smallFont
                    placeholder={tForm('port')}
                    type="number"
                    withoutErrorMessage
                  />
                }
                emptyData={originsEmptyData}
                inputType={InputType.MultipleInput}
                smallFont
                placeholder={tFormat('ip')}
                fullWidth
                direction={columnDirection.Row}
                required
                withoutErrorMessage
              />
            )}
            {values.origin_type === hostType.Domain && (
              <FormField
                label={tForm('domainList')}
                name="origins"
                nestedName="origin"
                nestedComponent={
                  <FormField
                    className="w-24"
                    name="port"
                    withoutLabel
                    smallFont
                    placeholder={tForm('port')}
                    type="number"
                    withoutErrorMessage
                  />
                }
                emptyData={originsEmptyData}
                inputType={InputType.MultipleInput}
                smallFont
                placeholder={tFormat('domain')}
                fullWidth
                direction={columnDirection.Row}
                required
                withoutErrorMessage
              />
            )}
            <FormField
              label={tForm('enableOverwriteHostHeader')}
              name="enable_proxy_host"
              inputType={InputType.Switch}
              smallFont
              direction={columnDirection.Row}
            />
            {values.enable_proxy_host && (
              <FormField
                label={tForm('overwriteHostHeader')}
                smallFont
                name="proxy_host"
                placeholder={tFormat('domain')}
                direction={columnDirection.Row}
                required
              />
            )}
          </FormModal>
        );
      }}
    </Formik>
  );
};
