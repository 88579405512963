import { Switch } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/16/solid';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';

import { InputMod } from '@enums';
import { Text } from '../Text';

interface ToggleButtonProps {
  name?: string;
  className?: string;
  disabled?: boolean;
  label?: string;
  inputMod?: InputMod;
  description?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export const ToggleButton = ({
  name,
  disabled,
  className = '',
  label,
  inputMod = InputMod.Default,
  description,
  checked,
  onChange,
}: ToggleButtonProps) => {
  const isFormikMode = Boolean(name);
  const [field] = useField(name ?? '');
  const { setFieldValue } = useFormikContext<{ [key: string]: boolean }>();

  const isFilled = inputMod === InputMod.Filled;
  const isChecked = isFormikMode ? field.value : checked;

  const handleToggle = (newValue: boolean) => {
    if (isFormikMode && name) {
      setFieldValue(name, newValue);
    }
    onChange?.(newValue);
  };

  const switchContainerClasses = classNames('', {
    'flex flex-col w-full ring-1 ring-theme-border-default rounded-md pt-1.5 pb-3 px-3 space-y-1': label && isFilled,
  });

  const switchClasses = classNames(
    'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0',
    {
      'bg-theme-primary-main': isChecked,
      'bg-gray-200': !isChecked,
      'hover:cursor-not-allowed bg-opacity-50': disabled,
      'hover:cursor-pointer': !disabled,
      [className]: className,
    },
  );

  const iconClasses = (current: boolean) => {
    return classNames('absolute inset-0 flex h-full w-full items-center justify-center transition-opacity', {
      'duration-100 ease-out': current,
      'duration-200 ease-in': !current,
      'text-gray-400': (!disabled && !current) || (disabled && current),
      'text-theme-primary-main': !disabled && current,
      'text-gray-300': disabled && !current,
    });
  };

  const containerButtonClasses = classNames(
    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
    {
      'translate-x-5': isChecked,
      'translate-x-0': !isChecked,
      'bg-opacity-70': disabled,
    },
  );

  return (
    <div className={switchContainerClasses}>
      {isFilled && label && (
        <article className="text-[0.58rem] text-gray-400 text-xs font-medium leading-6">{label}</article>
      )}
      <div className={`${isFilled && label && description ? 'pb-2' : ''} flex flex-1 justify-between`}>
        {isFilled && label && (
          <Text
            className={`${
              isChecked ? 'ring-green-400 ring-bg-50 text-green-400' : 'ring-red-400 bg-red-50 text-red-400'
            } ring-1 rounded-md py-1.5 px-2.5 text-us font-normal`}
          >
            {isChecked ? 'Enabled' : 'Disabled'}
          </Text>
        )}

        <Switch disabled={disabled} checked={isChecked ?? false} onChange={handleToggle} className={switchClasses}>
          <span className={containerButtonClasses}>
            <span className={iconClasses(isChecked)} aria-hidden="true">
              {isChecked ? <CheckIcon className="h-3 w-3" /> : <XMarkIcon className="h-3 w-3" />}
            </span>
          </span>
        </Switch>
      </div>
      {description && isFilled && (
        <article className="text-left text-us font-normal ring-1 py-1.5 px-2.5 bg-red-50 ring-red-300 text-red-400 rounded-md">
          {description}
        </article>
      )}
    </div>
  );
};
