import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ApiEndpoint, FormType } from '@enums';
import { useFetchFormList } from '@hooks';
import { useFormContent, useFormInit, useFormLoadingStatus } from '@store';

import { ROUTES } from '@constants';
import { WsConfigSkeleton } from './components';
import { WsConfigMember, WsConfigOverview, WsConfigOwnership } from './InfoBlock';

export const WorkspaceConfiguration = () => {
  const { id: application_id } = useParams();
  const navigate = useNavigate();
  const formType = FormType.Workspace;

  useFetchFormList({
    formType: FormType.Workspace,
    globalOptions: {
      content: true,
      endpoint: ApiEndpoint.CONFIGURATION,
      refetchAnywhere: true,
    },
    callbackFunction: {
      refetch: () => navigate(ROUTES.workspace.main),
    },
    params: { content_id: application_id },
  });

  const isFormInit = useFormInit(formType);
  const formLoading = useFormLoadingStatus({ formType, endpoint: ApiEndpoint.CONFIGURATION });
  const workspaceDetails = useFormContent({ formType, endpoint: ApiEndpoint.CONFIGURATION }) ?? {};

  const workspaceMember = workspaceDetails?.permissions ?? [];
  const memberListWithoutOwner = useMemo(
    () => workspaceMember?.filter((perm) => perm.permission !== 'owner'),
    [workspaceMember],
  );

  if (isFormInit || formLoading) return <WsConfigSkeleton />;
  return (
    <>
      {workspaceDetails?.name && <WsConfigOverview workspaceDetails={workspaceDetails} />}
      {workspaceMember && (
        <WsConfigOwnership workspaceMember={workspaceDetails?.permissions} isOwner={workspaceDetails?.is_owner} />
      )}
      {workspaceMember && (
        <WsConfigMember workspaceMember={memberListWithoutOwner} isOwner={workspaceDetails?.is_owner} />
      )}
    </>
  );
};
