import { motion } from 'framer-motion';
import { FC, memo, PropsWithChildren, ReactNode } from 'react';

import { cn } from '@utils';

interface FixedBackgroundProps extends PropsWithChildren {
  content?: ReactNode;
  backgroundClassName?: string;
}

export const FixedBackground: FC<FixedBackgroundProps> = memo(({ content, children, backgroundClassName = '' }) => {
  return (
    <motion.div className={cn('fixed inset-0', backgroundClassName)}>
      {typeof content === 'string' ? (
        <img src={content} alt="Global Network" className="w-full h-full object-cover" />
      ) : (
        content
      )}
      {!content && children}
    </motion.div>
  );
});
