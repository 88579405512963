import { FC, ReactNode, memo, useMemo } from 'react';

import { SCREEN_BREAKPOINTS } from '@constants';
import { useMediaQuery } from '@hooks';
import { ScreenBreakpoint } from '@types';

interface ResponsiveProps {
  children: ReactNode;
  showAbove?: ScreenBreakpoint;
  showBelow?: ScreenBreakpoint;
}

const createMediaQuery = (above?: ScreenBreakpoint, below?: ScreenBreakpoint): string => {
  if (above) return `(min-width: ${SCREEN_BREAKPOINTS[above]}px)`;
  if (below) return `(max-width: ${SCREEN_BREAKPOINTS[below] - 1}px)`;
  return '';
};

export const Responsive: FC<ResponsiveProps> = memo(({ children, showAbove, showBelow }) => {
  const mediaQuery = useMemo(() => createMediaQuery(showAbove, showBelow), [showAbove, showBelow]);

  const isVisible = useMediaQuery(mediaQuery);

  if (!showAbove && !showBelow) return null;
  return isVisible ? <>{children}</> : null;
});

Responsive.displayName = 'Responsive';
