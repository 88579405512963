import { IpStatusData, StyleOutput } from '@types';

export const calculateStatusRatio = (data: IpStatusData[]): string => {
  if (!data.length) return 'off';

  const totalCount = data.length;
  const trueCount = data.filter((item) => item.status).length;

  return `${trueCount}/${totalCount}`;
};

export const getStatusStyles = (ratio: string): StyleOutput => {
  if (ratio === 'off') {
    return {
      bg: 'bg-gray-200/50',
      text: 'text-gray-500',
    };
  }

  const [current, total] = ratio.split('/').map(Number);

  if (isNaN(current) || isNaN(total)) {
    return {
      bg: 'bg-gray-200/50',
      text: 'text-gray-500',
    };
  }

  return current === total
    ? {
        bg: 'bg-emerald-200/50',
        text: 'text-emerald-700',
      }
    : {
        bg: 'bg-red-200/50',
        text: 'text-red-700',
      };
};
