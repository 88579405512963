import { useViewportQueries } from '@hooks';

import type { ChartDimensionConfig, ChartResponsiveConfig } from '@types';

export const getViewportSpecificConfig = (
  config: ChartResponsiveConfig,
  viewportQueries: ReturnType<typeof useViewportQueries>,
): Partial<ChartDimensionConfig> => {
  const { isMobile, isTablet, isDesktop } = viewportQueries;

  if (isMobile) return config?.mobile ?? config.desktop;
  if (isTablet) return config?.tablet ?? config.desktop;
  if (isDesktop) return config?.desktop ?? config.desktop;
  return config?.large ?? config.desktop;
};
