export const createFormError = (error: unknown): any => {
  if (error instanceof Error) {
    return {
      message: error.message,
      code: (error as any)?.code,
      details: (error as any).details ?? (error as any)?.response?.data?.message,
    };
  }
  return { message: String(error) };
};
