import { useEffect, useState } from 'react';

import homePage from '@assets/homePage.webp';
import { Skeleton } from '@components';

export const HomePage = () => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = homePage;
    img.onload = () => setIsImageLoading(false);
  }, []);

  return (
    <div className="flex flex-col items-center flex-1 px-4 sm:px-12 sm:py-6">
      <div className={`flex h-full min-h-96 w-full flex-1 overflow-hidden`}>
        {isImageLoading ? (
          <Skeleton className="flex flex-1 rounded-2xl" />
        ) : (
          <div
            className={`flex items-center justify-center rounded-2xl h-full w-full flex-1 shadow-card select-none bg-center bg-cover filter-none`}
            style={{ backgroundImage: `url('${homePage}')` }}
          />
        )}
      </div>
    </div>
  );
};
