import clsx from 'clsx';
import { RotateCcw } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Cards, DraggableTable, Text } from '@components';
import { cdnWafLogTable } from '@constants';
import i18n from '@core/i18n';
import { DashboardEndpoint, FormActionType, FormType, Translation, VariantType } from '@enums';
import { useAppDispatch, useFormHandlers } from '@hooks';
import {
  fetchDashboardGraph,
  resetFormData,
  updateFormPages,
  useFormLastUpdated,
  useFormList,
  useFormLoadingStatus,
  useFormPagination,
} from '@store';
import { formatUnixTimestamp } from '@utils';
import { WafLogDetailModal } from './wafLogDetailModal';

export const WafLog = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnDashboard;
  const [openWafLogDetail, setOpenWafLogDetail] = useState<boolean>(false);

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.dashboard' });
  const { t: tTables } = useTranslation(Translation.Description, { keyPrefix: 'configuration.tables' });

  const WafLogHandlers = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.View,
        function: () => setOpenWafLogDetail(true),
        label: i18n.t('configuration:tables.view'),
      },
    ],
  });

  const wafLogTableColumns = useMemo(() => cdnWafLogTable({ actionHandlers: WafLogHandlers }), [tTables]);

  const lastUpdated = useFormLastUpdated(formType);
  const loggedData = useFormList({ formType, endpoint: DashboardEndpoint.WAF_LOG });
  const isDataLoading = useFormLoadingStatus({ formType, endpoint: DashboardEndpoint.WAF_LOG });
  const pagination = useFormPagination({ formType, endpoint: DashboardEndpoint.WAF_LOG });

  const formattedLastUpdated = formatUnixTimestamp(lastUpdated);

  const [refreshTrigger, setRefreshTrigger] = useState<number>(1);

  const handlePageChange = (page: number) => {
    dispatch(
      updateFormPages({
        formType,
        page,
        params: { application_id },
        globalOptions: {
          endpoint: DashboardEndpoint.WAF_LOG,
        },
      }),
    );
  };

  const handleReloadLog = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    if (!application_id) return;

    if (refreshTrigger !== 0) {
      dispatch(
        fetchDashboardGraph({
          formType,
          params: { application_id },
          globalOptions: {
            endpoint: [DashboardEndpoint.WAF_LOG],
            pagination: true,
          },
        }),
      );
    }
  }, [application_id, refreshTrigger]);

  const handleWafLogDetailModal = () => {
    setOpenWafLogDetail(false);
  };

  useEffect(() => {
    return () => {
      dispatch(resetFormData({ formType, endpoint: DashboardEndpoint.WAF_LOG }));
    };
  }, []);

  return (
    <Cards rounded="rounded-2xl px-8 py-7 space-y-6 max-w-full overflow-hidden" withoutPadding>
      <div className="flex justify-between">
        <Text $customizeColor $level={3} className="font-medium text-theme-primary-main">
          {tTabs('wafLogs')}
        </Text>
        <div className="flex gap-5 items-center">
          <Text color="text-gray-500" $level={6}>
            {tTabs('lastUpdated', { date: formattedLastUpdated })}
          </Text>
          <Button
            onClick={handleReloadLog}
            variant={VariantType.Icon}
            disabled={isDataLoading}
            icon={
              <RotateCcw
                className={clsx('size-4', {
                  'text-blue-500': !isDataLoading,
                  'text-gray-400': isDataLoading,
                })}
              />
            }
          />
        </div>
      </div>
      <DraggableTable
        rows={loggedData}
        actionHandlers={WafLogHandlers}
        columns={wafLogTableColumns}
        isDataLoading={isDataLoading}
        formType={formType}
        pagination={pagination}
        onChangePage={handlePageChange}
      />
      {openWafLogDetail && (
        <WafLogDetailModal formType={formType} open={openWafLogDetail} onClose={handleWafLogDetailModal} />
      )}
    </Cards>
  );
};
