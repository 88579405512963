import { clsx } from 'clsx';

import type { fontSizeLevel } from '@types';

type HeadingProps = { level?: fontSizeLevel } & React.ComponentPropsWithoutRef<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;

export function Heading({ className, level = 1, ...props }: HeadingProps) {
  const Element: `h${typeof level}` = `h${level}`;

  const levelClass = {
    1: 'text-4xl',
    2: 'text-3xl',
    3: 'text-2xl',
    4: 'text-xl',
    5: 'text-lg',
    6: 'text-base',
  };

  return (
    <Element {...props} className={clsx(className, levelClass[level], 'font-semibold text-zinc-950 dark:text-white')} />
  );
}

export function Subheading({ className, level = 2, ...props }: HeadingProps) {
  const Element: `h${typeof level}` = `h${level}`;

  const levelClass = {
    1: 'text-2xl',
    2: 'text-xl',
    3: 'text-lg',
    4: 'text-base',
    5: 'text-us',
    6: 'text-smLabel',
  };

  return <Element {...props} className={clsx(className, levelClass[level], 'font-medium bold ')} />;
}
