import { Formik } from 'formik';
import { Layers2Icon } from 'lucide-react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormField, FormModal, Text } from '@components';
import { ROUTES, WORKSPACE_FORM_SCHEMA } from '@constants';
import { FormType, StatusType, Translation } from '@enums';
import { useAppDispatch } from '@hooks';
import { addSnack, useFormContent, useUserDetails } from '@store';

interface WorkspaceManagementModalProps {
  open: boolean;
  onClose: () => void;
}

const WorkspaceManagementModal: React.FC<WorkspaceManagementModalProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formType = FormType.Workspace;

  const { t: tModals } = useTranslation(Translation.Workspace, { keyPrefix: 'modals' });
  const { t: tButton } = useTranslation(Translation.Workspace, { keyPrefix: 'buttons' });
  const { t: tDescription } = useTranslation(Translation.Workspace, { keyPrefix: 'descriptions' });
  const { t: tForms } = useTranslation(Translation.Workspace, { keyPrefix: 'forms' });
  const { t: tToasts } = useTranslation(Translation.Workspace, { keyPrefix: 'toasts' });

  const userDetails = useUserDetails();
  const formContent = useFormContent({ formType });

  const ownership = {
    id: userDetails?.id,
    name: `${userDetails?.first_name} ${userDetails?.last_name}`,
    email: userDetails?.email,
    role: 'owner',
  };
  const initialValues: any = useMemo(
    () => ({
      name: formContent?.name ?? '',
      users: [formContent?.ownership, ownership, ...(formContent?.users ?? [])],
      ownership: formContent?.ownership?.id ?? userDetails?.id,
    }),
    [formContent, userDetails],
  );

  const handleSubmit = (values: any) => {
    console.log(values);
    navigate(`1${ROUTES.workspace.settings}`);
    dispatch(addSnack({ type: StatusType.Success, message: tToasts('newWorkspaceCreatedSuccessfully') }));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={WORKSPACE_FORM_SCHEMA} onSubmit={handleSubmit}>
      {({ isSubmitting }) => {
        return (
          <FormModal
            open={open}
            onClose={onClose}
            title={tModals('setUpNewWorkspace')}
            description={tDescription('configureADedicatedSpaceForYourTeamsProjectsFilesAndCollaboration')}
            confirmButton={{
              children: tButton('createWorkspace'),
              disabled: isSubmitting,
            }}
            size="md"
          >
            <FormField
              name="name"
              smallFont
              label={tForms('workspaceName')}
              placeholder={tForms('chooseAUniqueNameToIdentifyYourWorkspace')}
            />
            <div className="flex items-start gap-2 mt-2">
              <Layers2Icon className="w-4 h-4 flex-shrink-0 text-green-500 mt-0.5 mr-1.5" />
              <Text $level={6} color="text-gray-400" className=" font-light text-justify">
                {tDescription('byDefaultYouAreTheWorkspaceOwnerYouCanChangeOwnershipOrInviteOtherMembersAfterCreation')}
              </Text>
            </div>
          </FormModal>
        );
      }}
    </Formik>
  );
};

export default memo(WorkspaceManagementModal);
