import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from '@components';
import { ROUTES } from '@constants';
import { ApiEndpoint, FormType } from '@enums';
import { useCurrentWorkspaceId, useFormLoadingStatus, useWorkspaceOptions } from '@store';

export const WorkspaceLayout = ({ children }: PropsWithChildren<object>) => {
  const navigate = useNavigate();

  const workspaceList = useWorkspaceOptions();
  const formLoading = useFormLoadingStatus({ formType: FormType.Workspace, endpoint: ApiEndpoint.CONFIGURATION });
  const currentWorkspaceId = useCurrentWorkspaceId();

  useEffect(() => {
    if (!currentWorkspaceId || workspaceList.length <= 0) {
      navigate(ROUTES.homepage);
    }
  }, [currentWorkspaceId, workspaceList, formLoading]);

  return (
    <div className="max-w-full w-full space-y-8">
      <Breadcrumbs />
      {children}
    </div>
  );
};
