import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ErrorIcon } from '@assets';
import { Button, Text } from '@components';
import { ROUTES, SIGN_IN_FORM_SCHEMA, USER_LOGIN_FORM_CONFIG } from '@constants';
import { DisplayMode, InputMod, Translation, VariantType } from '@enums';
import { useAppDispatch, useErrorFormInner } from '@hooks';
import { login, useLoginError, useLoginLoading, useSystemIsOnline } from '@store';
import type { UserLoginFormValues } from '@types';
import { capitalizeFirstLetter } from '@utils';
import { LoginFormFieldWrapper } from '../../subComponents';

export const SignInForm = ({ recaptchaRef }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t: tSignIn } = useTranslation(Translation.SignIn);
  const { t: tButton } = useTranslation(Translation.SignIn, { keyPrefix: 'button' });

  const isLoginLoading = useLoginLoading();
  const loginError = useLoginError();
  const isOnline = useSystemIsOnline();

  const { fields, initialValues } = USER_LOGIN_FORM_CONFIG;

  const handleSubmit = (values: UserLoginFormValues) => {
    dispatch(login({ values, recaptchaRef }));
  };

  const SignInFormInner = () => {
    useErrorFormInner({ submitError: loginError, isSubmitting: isLoginLoading });
    return null;
  };

  const handleForgotPassword = () => {
    navigate(ROUTES.recoverPassword);
  };

  const handleSignUp = () => {
    navigate(ROUTES.registration);
  };

  return (
    <Formik
      initialValues={initialValues as UserLoginFormValues}
      validationSchema={SIGN_IN_FORM_SCHEMA}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form className="space-y-4 pt-2">
            <SignInFormInner />
            <div className="space-y-6">
              {!!loginError && (
                <div className="flex items-center gap-5 py-3.5 px-5 bg-red-500/10 rounded-xl shadow-glow ring-1 ring-red-500/10">
                  <div className="flex-shrink-0 w-4">
                    <ErrorIcon size={18} />
                  </div>
                  <Text $level={5} color="text-red-600" className="font-medium">
                    {capitalizeFirstLetter(loginError)}
                  </Text>
                </div>
              )}
              {fields.map((field) => (
                <LoginFormFieldWrapper
                  key={field.name}
                  field={{
                    ...field,
                    fullWidth: true,
                    withoutRing: true,
                    withoutLabel: true,
                    inputMod: InputMod.Filled,
                    displayMode: DisplayMode.Dark,
                  }}
                  translation={tSignIn}
                />
              ))}
            </div>
            <motion.div
              className="grid grid-cols-1 gap-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              <Button
                disabled={isLoginLoading}
                variant={VariantType.Transparent}
                onClick={handleForgotPassword}
                withoutPadding
                rounded="rounded-none"
                className="bg-transparent pt-0.5 text-red-500/90 hover:underline justify-self-end"
              >
                {`${tButton('forgotPassword')} ?`}
              </Button>
              <Button
                type="submit"
                fullWidth
                loading={isLoginLoading}
                rounded="rounded-lg"
                disabled={!isOnline}
                withoutPadding
                customizeColor
                className={`px-2.5 py-3.5 justify-end text-white bg-[#21648F] ${!isOnline ? 'text-opacity-70 bg-opacity-70' : ''}`}
                data-testid="signIn-submit-button"
              >
                {tButton('signIn')}
              </Button>
              <Text $level={6} color="text-gray-400 text-center">
                {`${tButton('dontHaveAnAccount')}? `}
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSignUp();
                    }
                  }}
                  className="text-blue-400 hover:underline hover:cursor-pointer"
                  onClick={handleSignUp}
                >
                  {tButton('signUp')}
                </span>
              </Text>
            </motion.div>
          </Form>
        );
      }}
    </Formik>
  );
};
