import { Text } from '@components';
import { ReactNode } from 'react';

interface InfoBlockProps {
  label: {
    left?: string;
    right?: string | ReactNode;
  };
  style?: {
    leftClassName?: string;
    rightClassName?: string;
  };
}

export const InfoBlock = ({
  label,
  style = { leftClassName: 'text-gray-400', rightClassName: 'text-slate-600' },
}: InfoBlockProps) => {
  if (!label?.left || !label?.right) return null;

  return (
    <div className="flex py-1.5 items-start justify-between">
      <Text $level={4} $customizeColor className={`font-light w-1/3 ${style.leftClassName}`}>
        {label.left}
      </Text>

      {typeof label.right === 'string' || typeof label.right === 'number' ? (
        <Text $level={4} $customizeColor className={`font-light w-2/3 ${style.rightClassName}`}>
          {label.right}
        </Text>
      ) : (
        <div className="w-2/3">{label.right}</div>
      )}
    </div>
  );
};
