import { call, put, take, takeLatest } from 'redux-saga/effects';

import { initData } from '@core/storage';
import {
  appInit,
  appInitError,
  appInitSuccess,
  clearWorkspaceOptions,
  getUserSummary,
  getUserSummarySuccess,
} from '@store';
import { getAllStorageData } from '../storage';
import { checkAndRefreshToken } from './systemSaga';

const getNormalizedLanguage = () => {
  const browserLanguage = navigator.language.toLowerCase();

  if (browserLanguage.startsWith('en')) {
    return 'en';
  } else if (browserLanguage.startsWith('zh')) {
    return 'cn';
  } else {
    return 'en';
  }
};

function* initSaga() {
  const { current_init_data } = yield call(getAllStorageData);
  if (!current_init_data) {
    try {
      const detectTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
      const initializeInit = {
        timeZone: detectTimeZone(),
        displayMode: 'light',
        userId: null,
        language: getNormalizedLanguage(),
      };
      yield call(initData.setItem, initializeInit);
      yield put(clearWorkspaceOptions());
    } catch (e) {
      console.error(e);
    }
  }

  const { current_access_token: token } = yield call(getAllStorageData);
  if (!token) {
    yield put(appInitError('No access token found'));
    return;
  }

  try {
    const result = yield call(checkAndRefreshToken, 1);
    if (result !== 'failed') {
      yield put(getUserSummary());
    }
  } catch (error) {
    yield put(appInitError('Error checking token'));
  }
  yield take(getUserSummarySuccess.type);
  yield put(appInitSuccess());
}
export function* initSagaTakeEvery() {
  yield takeLatest(appInit.type, initSaga);
}
