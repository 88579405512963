import { FC } from 'react';

import { DetailsItem, GeneralInfoBlockProps } from '@types';
import { Divider } from '../Divider/divider';
import { Text } from '../Text';

const GeneralInfoBlock: FC<GeneralInfoBlockProps> = ({
  title = '',
  items = [],
  labelStyles = 'text-gray-500',
  valueStyles = 'text-gray-800 font-medium',
  isLast = false,
}) => {
  const isArrayOfArrays = Array.isArray(items[0]);

  return (
    <div className="bg-white">
      {title && (
        <Text $level={5} $customizeColor className="text-md font-semibold mb-4">
          {title}
        </Text>
      )}
      <dl className="space-y-0.5">
        {items && isArrayOfArrays
          ? (items as DetailsItem[][]).map((group, groupIndex) => (
              <div key={groupIndex} className="flex flex-col mb-4">
                {group.map((item, index) =>
                  item?.value !== '' ? (
                    <div key={index} className="grid grid-cols-3  gap-x-5 py-1">
                      <div className="col-span-1">
                        <Text $level={5} $customizeColor className={`${labelStyles} break-words`}>
                          {item?.label ?? '-'}
                        </Text>
                      </div>
                      <div className="col-span-2">
                        <Text $level={5} $customizeColor className={`${valueStyles} break-words`}>
                          {item?.value ?? '-'}
                        </Text>
                      </div>
                    </div>
                  ) : null,
                )}
              </div>
            ))
          : (items as DetailsItem[]).map((item, index) =>
              item?.value !== '' ? (
                <div key={index} className="grid grid-cols-[minmax(0,1fr)_minmax(0,2fr)] gap-5 py-1">
                  <Text $level={5} $customizeColor className={`${labelStyles} break-words`}>
                    {item?.label ?? '-'}
                  </Text>
                  <Text $level={5} $customizeColor className={`${valueStyles} break-words`}>
                    {item?.value ?? '-'}
                  </Text>
                </div>
              ) : null,
            )}
      </dl>
      {!isLast && <Divider className="mt-4" />}
    </div>
  );
};

export default GeneralInfoBlock;
