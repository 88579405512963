import { useEffect, useState } from 'react';

const mediaQueryCache = new Map<string, MediaQueryList>();

const getMediaQuery = (query: string): MediaQueryList | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const cached = mediaQueryCache.get(query);
  if (cached) {
    return cached;
  }

  try {
    const mediaQuery = window.matchMedia(query);
    mediaQueryCache.set(query, mediaQuery);
    return mediaQuery;
  } catch (error) {
    console.warn(`Failed to create media query for: ${query}`, error);
    return null;
  }
};

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState<boolean>(() => {
    const mediaQuery = getMediaQuery(query);
    return mediaQuery?.matches ?? false;
  });

  useEffect(() => {
    const mediaQuery = getMediaQuery(query);
    if (!mediaQuery) {
      return;
    }

    const onChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    mediaQuery.addEventListener('change', onChange);

    if (mediaQuery.matches !== matches) {
      setMatches(mediaQuery.matches);
    }

    return () => {
      mediaQuery.removeEventListener('change', onChange);
    };
  }, [query, matches]);

  return matches;
};
