import { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import clsx from 'clsx';

import { validateValueEqual } from '@utils';
import { columnDirection } from '@enums';
import { SelectableItemList } from './SelectableItemList';
import type { DualListBoxProps } from '@types';

export const DualListBox = ({
  name,
  options,
  leftPanel,
  rightPanel,
  direction = columnDirection.Column,
  showValue = true,
}: DualListBoxProps) => {
  const [field, , helpers] = useField<string[]>(name);

  const isDirectionRow = validateValueEqual(direction, columnDirection.Row);

  const leftPanelList = useMemo(
    () => options.filter((item) => !field.value.includes(item.value)),
    [options, field.value],
  );
  const rightPanelList = useMemo(
    () => options.filter((item) => field.value.includes(item.value)),
    [options, field.value],
  );

  const handleMoveAllToRightPanel = useCallback(() => {
    helpers.setValue([...field.value, ...leftPanelList.map((c) => c.value)]);
  }, [field.value]);

  const handleMoveAllToLeftPanel = useCallback(() => {
    helpers.setValue([]);
  }, []);

  const handleToggle = useCallback(
    (item: string, addToBlocked: boolean) => {
      if (addToBlocked) {
        helpers.setValue([...field.value, item]);
      } else {
        helpers.setValue(field.value.filter((c) => c !== item));
      }
    },
    [field.value],
  );

  return (
    <div className={clsx('flex gap-6 flex-col', isDirectionRow ? 'sm:flex-row' : 'sm:flex-col')}>
      <SelectableItemList
        items={leftPanelList}
        onToggleItem={(item) => handleToggle(item, true)}
        onMoveAll={handleMoveAllToRightPanel}
        placeholder={leftPanel.placeholder}
        title={leftPanel.title}
        name={leftPanel.fieldName}
        showValue={showValue}
        zone={0}
      />
      <SelectableItemList
        items={rightPanelList}
        onToggleItem={(item) => handleToggle(item, false)}
        onMoveAll={handleMoveAllToLeftPanel}
        placeholder={rightPanel.placeholder}
        title={rightPanel.title}
        name={rightPanel.fieldName}
        showValue={showValue}
        zone={1}
      />
    </div>
  );
};
