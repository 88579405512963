import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text } from '@components';
import { FORGOT_PASSWORD_FORM_CONFIG, RECOVER_PASSWORD_SCHEMA, ROUTES } from '@constants';
import { FormActionType, FormType, Translation, UserEndpoint } from '@enums';
import { useAppDispatch, useErrorFormInner } from '@hooks';
import { submitFormRequest, updateFormProcedure, useFormError, useFormSubmissionStatus } from '@store';
import type { RecoverPasswordFormValues } from '@types';

import { LoginButtonGroup, LoginFormFieldWrapper } from '../../subComponents';

export const RecoverPasswordForm = ({ recaptchaRef }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formType = FormType.User;
  const forgetPasswordFormMetaData = { formType, endpoint: UserEndpoint.FORGOT_PASSWORD };

  const { t: tSignIn } = useTranslation(Translation.SignIn);
  const { t: tButton } = useTranslation(Translation.SignIn, { keyPrefix: 'button' });

  const isFormSubmitting = useFormSubmissionStatus(forgetPasswordFormMetaData);
  const isFormError = useFormError(forgetPasswordFormMetaData);

  const { fields, initialValues } = FORGOT_PASSWORD_FORM_CONFIG;

  const handleSubmit = useCallback(
    (values: RecoverPasswordFormValues) => {
      dispatch(updateFormProcedure({ formType, procedure: FormActionType.Forget }));
      dispatch(
        submitFormRequest({
          formType,
          formData: values,
          globalOptions: {
            recaptcha: true,
            endPath: true,
            endpoint: UserEndpoint.FORGOT_PASSWORD,
          },
          callbackFunction: {
            refetch: () => navigate(ROUTES.login),
          },
          ref: {
            recaptcha: recaptchaRef,
          },
        }),
      );
    },
    [formType, dispatch],
  );

  const RecoverPasswordFormErrorInner = () => {
    useErrorFormInner({ submitError: isFormError, isSubmitting: isFormSubmitting });
    return null;
  };

  return (
    <Formik
      initialValues={initialValues as RecoverPasswordFormValues}
      validationSchema={RECOVER_PASSWORD_SCHEMA}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="space-y-5 pt-2">
          <RecoverPasswordFormErrorInner />
          {fields.map((field) => (
            <LoginFormFieldWrapper
              key={field.name}
              field={{
                ...field,
                label: (
                  <Text $level={5} $customizeColor className="text-slate-400">
                    {tSignIn(String(field.label))}
                  </Text>
                ),
                fullWidth: true,
                customizeColor: true,
                withoutRing: true,
                smallFont: true,
                textInputClassName:
                  'bg-theme-neutral-main text-white rounded-lg focus:ring-2 focus:ring-theme-primary transition-all duration-300',
              }}
              translation={tSignIn}
            />
          ))}
          <LoginButtonGroup
            label={{ confirmButton: tButton('sendRecoveryLink') }}
            formParams={forgetPasswordFormMetaData}
            hasNavigate
          />
        </Form>
      )}
    </Formik>
  );
};
