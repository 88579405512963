import { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { colors } from '@components';

export const fontLevelClass = {
  1: 'text-xl leading-6 sm:text-xl sm:leading-6',
  2: 'text-lg leading-6 sm:text-lg sm:leading-6',
  3: 'text-base leading-5 sm:text-base sm:leading-5',
  4: 'text-sm leading-5 sm:text-sm sm:leading-5',
  5: 'text-xs leading-4 sm:text-xs sm:leading-4',
  6: 'text-us leading-4 sm:text-us sm:leading-4',
};
export type FontLevel = keyof typeof fontLevelClass;

interface TextProps extends HTMLAttributes<HTMLParagraphElement | HTMLHeadingElement> {
  className?: string;
  color?: string;
  $level?: FontLevel | null;
  $customizeColor?: boolean;
}

const StyledText = styled.p.attrs<TextProps>(({ color, $customizeColor, $level = 1 }) => ({
  className: `
    ${$level && fontLevelClass[$level]}
    ${color && !$customizeColor ? colors[color] || color : ''}
    ${!$customizeColor && !color ? 'text-zinc-500 dark:text-zinc-400' : ''}
    select-text
  `,
}))<TextProps>``;

export function Text({ color, $level = 1, $customizeColor, ...props }: Readonly<TextProps>) {
  return <StyledText data-slot="text" {...props} color={color} $level={$level} $customizeColor={$customizeColor} />;
}
