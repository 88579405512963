import { motion } from 'framer-motion';
import { ChartBar } from 'lucide-react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Translation } from '@enums';
import { Text } from '../Text';

interface IconLabelAnimationInterface {
  label?: string;
  icon?: ReactNode;
  children?: ReactNode;
}

export const IconLabelAnimation: FC<IconLabelAnimationInterface> = ({
  label,
  children,
  icon = <ChartBar className="size-16 -scale-90" />,
}) => {
  const { t: tTable } = useTranslation(Translation.Common, { keyPrefix: 'tables' });

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center pt-8 text-gray-400 gap-5"
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1, rotate: 360 }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
      >
        {icon}
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="mb-2 text-center"
      >
        <Text $level={4} $customizeColor className="font-semibold">
          {label ?? tTable('noRecordFound')}
        </Text>
        {children}
      </motion.div>
    </motion.div>
  );
};
