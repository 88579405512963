import { ValidationType } from '@enums';
import { createValidationSchema } from '@utils';

export const HEALTH_CHECK_FORM_SCHEMA = createValidationSchema({
  status: {
    required: true,
  },
  protocol: {
    required: true,
  },
  host: {
    required: true,
    pattern: ValidationType.DOMAIN,
    dependsOn: {
      field: 'protocol',
      value: 'TCP',
      shouldValidate: false,
    },
    validateWhenOptional: true,
  },
  uri: {
    required: true,
    pattern: ValidationType.URI,
    dependsOn: {
      field: 'protocol',
      value: 'TCP',
      shouldValidate: false,
    },
    validateWhenOptional: true,
  },
  verify_ssl_cert: {
    required: true,
    dependsOn: {
      field: 'protocol',
      value: 'HTTPS',
      shouldValidate: true,
    },
  },
});
