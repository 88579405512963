import { ChevronRightIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';

import { Button } from '@components';
import { ROUTES } from '@constants';
import { ApiEndpoint, FormType, Translation, VariantType } from '@enums';
import { useAppDispatch, useBreadcrumbItems } from '@hooks';
import { getWorkspaceOptions, useCdnSummary, useFormContent } from '@store';
import { validateValueEqual } from '@utils';

export const NavigatorButton = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const { t: translate } = useTranslation(Translation.Common);

  const cdnSummary = useCdnSummary();
  const workspace = useFormContent({ formType: FormType.Workspace, endpoint: ApiEndpoint.CONFIGURATION });

  const dataSource = pathname.includes(ROUTES.workspace.main) ? workspace : cdnSummary;

  const breadcrumbItems = useBreadcrumbItems(pathname, dataSource, translate);

  const generateHref = (index: number) => {
    const segments = breadcrumbItems.slice(0, index + 1);

    const path = segments
      .map((bi) => {
        if (pathname.includes(ROUTES.workspace.main) && /^\d+$/.test(bi.id)) {
          return `${bi.id}${ROUTES.workspace.settings}`;
        }
        return bi.id;
      })
      .join('/');

    return `/${path}`;
  };
  const handleBreadCrumbItem = (index) => {
    navigate(generateHref(index));
    if (index === 0 && pathname.includes(ROUTES.workspace.main)) {
      dispatch(getWorkspaceOptions());
    }
  };
  return (
    <Fragment>
      {breadcrumbItems.map((item, index) => {
        const currentTab = validateValueEqual(index, breadcrumbItems.length - 1);
        return (
          <li key={index} className="flex items-center gap-x-3">
            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <Button
              onClick={() => handleBreadCrumbItem(index)}
              withoutPadding
              rounded="rounded-none"
              variant={VariantType.Transparent}
              disabled={currentTab}
              className={`text-sm ${currentTab ? 'text-gray-400 font-medium' : 'font-normal text-gray-600 hover:font-medium hover:text-gray-500'} py-0.5`}
            >
              {item.name}
            </Button>
          </li>
        );
      })}
    </Fragment>
  );
};
