import type { ToastRule } from '@types';

export const validateRuleOptions = (response: any, rules?: ToastRule[]): boolean => {
  if (!rules) return true;

  return rules.every((rule) => {
    const responseValue = response[rule.key];
    const { expectedValue, operator = 'equals' } = rule;

    switch (operator) {
      case 'equals':
        return responseValue === expectedValue;
      case 'notEquals':
        return responseValue !== expectedValue;
      case 'includes':
        return responseValue?.includes(expectedValue);
      case 'greaterThan':
        return responseValue > expectedValue;
      case 'lessThan':
        return responseValue < expectedValue;
      default:
        return responseValue === expectedValue;
    }
  });
};
