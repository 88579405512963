import i18n from '@core/i18n';

import { capitalizeFirstLetter } from '@utils';

export const cdnWafLogDetailInfo = (content) => {
  const parseHeaders = (headerString: string = '') => {
    return headerString.split('\n').map((line) => {
      const [label, ...valueParts] = line.split(':');
      return {
        label: label.trim(),
        value: valueParts.join(':').trim(),
      };
    });
  };

  const formatMatches = (matches: any[] = []) => {
    return matches.map((match) => [
      {
        label: i18n.t('configuration:tables.rule'),
        value: match.group,
      },
      {
        label: i18n.t('configuration:tables.keyword'),
        value: match.matches[1],
      },
      {
        label: i18n.t('configuration:tables.messages'),
        value: match.message,
      },
    ]);
  };
  return {
    [i18n.t('configuration:tables.logInformation')]: [
      { label: i18n.t('configuration:tables.status'), value: capitalizeFirstLetter(content.action ?? '-') },
      { label: i18n.t('configuration:tables.requestId'), value: content.request_id ?? '-' },
      { label: i18n.t('configuration:tables.host'), value: content.host ?? '-' },
      { label: i18n.t('configuration:tables.request'), value: content.request ?? '-' },
      {
        label: i18n.t('configuration:tables.cumulativeRisk'),
        value:
          content &&
          `${content.score >= content.threshold ? `Threshold reached ` : ''}(${content.score}/${content.threshold})`,
      },
      { label: i18n.t('configuration:tables.responseStatus'), value: content.resp_status ?? '-' },
    ],
    [i18n.t('configuration:tables.clientAddress')]: [
      { label: i18n.t('configuration:tables.areaCode'), value: content.client_country ?? '-' },
      { label: i18n.t('configuration:tables.province'), value: content.client_province ?? '-' },
      { label: i18n.t('configuration:tables.city'), value: content.client_city ?? '-' },
      { label: i18n.t('configuration:tables.ipAddress'), value: content.remote_addr ?? '-' },
    ],
    [i18n.t('configuration:tables.reason')]: formatMatches(content.matches ?? '-'),
    [i18n.t('configuration:tables.headers')]: parseHeaders(content.header ?? '-'),
  };
};
