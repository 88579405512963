import { checkIdProperty, checkNameProperty, validateValueEqual } from '@utils';
import { HeartPulseIcon, Search, PencilLineIcon, Trash2Icon, UserRoundX } from 'lucide-react';
import { Fragment, memo, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DraggableItem, Text } from '@components';
import { FormActionType, FormType, VariantType } from '@enums';
import { FormHandlers, useAppDispatch } from '@hooks';
import { updateFormProcedure, useFormProcedure } from '@store';
import i18n from '@core/i18n';

interface ActionTableCellProps<T> {
  multiLines?: boolean;
  data: T;
  formType: FormType;
  actionHandlers: FormHandlers<T>;
}

interface ActionConfig {
  icon: ReactNode;
  color: string;
  tooltip: () => void;
  condition: (data: any) => boolean;
}

type ActionConfigType = {
  [K in
    | FormActionType.Edit
    | FormActionType.View
    | FormActionType.Delete
    | FormActionType.Remove
    | FormActionType.HealthCheck]: ActionConfig;
};
const actionConfigs: ActionConfigType = {
  [FormActionType.Edit]: {
    icon: <PencilLineIcon className="w-4 h-4" />,
    color: 'text-blue-600',
    tooltip: () => i18n.t('common:button.edit'),
    condition: () => true,
  },
  [FormActionType.View]: {
    icon: <Search className="w-4 h-4" />,
    color: 'text-blue-500',
    tooltip: () => i18n.t('common:button.view'),
    condition: () => true,
  },
  [FormActionType.Delete]: {
    icon: <Trash2Icon className="w-4 h-4" />,
    color: 'text-red-500',
    tooltip: () => i18n.t('common:button.delete'),
    condition: checkIdProperty,
  },
  [FormActionType.Remove]: {
    icon: <UserRoundX className="w-4 h-4" />,
    color: 'text-red-500',
    tooltip: () => i18n.t('common:button.remove'),
    condition: checkIdProperty,
  },
  [FormActionType.HealthCheck]: {
    icon: <HeartPulseIcon className="w-4 h-4" />,
    color: 'text-green-500',
    tooltip: () => i18n.t('common:button.checkHealth'),
    condition: checkIdProperty,
  },
};
export const ActionTableCell = memo(
  <T extends DraggableItem>({ data, actionHandlers, formType, multiLines = false }: ActionTableCellProps<T>) => {
    const dispatch = useAppDispatch();
    const currentProcedure = useFormProcedure(formType);
    const { t } = useTranslation();

    const renderActionButtons = useMemo(() => {
      return Object.entries(actionHandlers)
        .map(([type, { customize, handler, display = true, disabled = false }]: [any, any]) => {
          if (!display) return null;
          const config = actionConfigs[type as FormActionType];
          const handleMouseEnter = () => {
            if (
              currentProcedure !== FormActionType.Edit &&
              currentProcedure !== FormActionType.Delete &&
              currentProcedure !== FormActionType.View &&
              currentProcedure !== FormActionType.HealthCheck
            ) {
              dispatch(updateFormProcedure({ formType, procedure: type }));
            }
          };

          if (validateValueEqual(type, FormActionType.Custom)) {
            return <Fragment key={type}>{customize.children}</Fragment>;
          }

          if (
            !config?.condition(data) ||
            ![
              FormActionType.Edit,
              FormActionType.Remove,
              FormActionType.View,
              FormActionType.Delete,
              FormActionType.Verification,
              FormActionType.HealthCheck,
            ].includes(type as FormActionType)
          ) {
            return null;
          }

          const baseText = data[formType] ?? (checkNameProperty(data) ? data?.name : '') ?? '';

          return (
            <Button
              key={type}
              hoverEffect={!disabled}
              hint={
                <Text $level={4} color="text-white">
                  {disabled ? t('common:functionUnderDevelopment') : `${config.tooltip()} ${baseText}`}
                </Text>
              }
              variant={VariantType.Icon}
              iconWithoutM
              onMouseEnter={handleMouseEnter}
              withoutPadding
              disabled={disabled}
              rounded="rounded-md"
              className={`bg-transparent text-opacity-80 ${disabled ? '' : config.color}`}
              onClick={() => handler?.(data)}
              icon={config.icon}
            />
          );
        })
        .filter(Boolean);
    }, [actionHandlers, data, formType, t]);

    if (!actionHandlers || Object.keys(actionHandlers).length === 0) {
      return null;
    }

    return (
      <div className="flex flex-1 relative gap-x-3 min-h-8">
        <div className="flex">{renderActionButtons}</div>
      </div>
    );
  },
);
