interface RequestConfig {
  formConfig: {
    path: string;
    endPath?: string;
  };
  params?: Record<string, any>;
  endPath?: boolean;
}

interface RequestUrlResult {
  url: string;
  requestParams?: {
    params: Record<string, any>;
  };
}

export const getApiRequestUrl = ({ formConfig, params, endPath }: RequestConfig): RequestUrlResult => {
  const { path, endPath: lastPath } = formConfig;
  let url = path;

  if (params?.content_id) {
    url += `/${params.content_id}`;
  }

  if (endPath && lastPath) {
    url += lastPath;
  }

  const requestParams = params
    ? {
        params: Object.fromEntries(Object.entries(params).filter(([key]) => key !== 'content_id')),
      }
    : undefined;

  return { url, requestParams };
};
