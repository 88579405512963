import { DefaultPort, originType } from '@core/enums';
import { OriginData } from '@core/types';

export const updateOriginsByType = (type: string, origins: OriginData[]): OriginData[] => {
  const isHttpType = type === originType.HTTP;

  return origins.map((origin) => {
    if (isHttpType && origin.port == DefaultPort.HTTPS) {
      return { ...origin, port: DefaultPort.HTTP };
    }
    if (!isHttpType && origin.port == DefaultPort.HTTP) {
      return { ...origin, port: DefaultPort.HTTPS };
    }
    return origin;
  });
};
