import { ReactNode } from 'react';

import { ValidationPatterns } from '@constants/global/validationPatterns';
import { ValidationType } from '@enums';

export const capitalize = <T>(value: T | null | undefined): ReactNode => {
  if (typeof value === 'string') {
    const firstCharOfWord = ValidationPatterns[ValidationType.FIRST_CHAR_OF_WORD].regex;
    return value.replace(firstCharOfWord, (c) => c.toUpperCase());
  }
  if (value === null || value === undefined) {
    return '';
  }
  return value as ReactNode;
};

export const capitalizeFirstLetter = (text: string): string => {
  if (text == null || typeof text !== 'string' || text.trim().length === 0) {
    return '—';
  }

  const singleLatinLetterRegexp = ValidationPatterns[ValidationType.SINGLE_LATIN_LETTER].regex;
  const execResult = singleLatinLetterRegexp.exec(text);
  if (!execResult) return '—';

  const pos = execResult.index;
  return text.slice(0, pos) + text[pos].toUpperCase() + text.slice(pos + 1).toLowerCase();
};
