import { FormikProps } from 'formik';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActionButtonGroup, ConfigurationTab, Responsive } from '@components';
import { FormActionType, FormType, Translation } from '@enums';
import { useFetchFormList, useFormHandlers } from '@hooks';
import { useFormLoadingStatus, useFormProcedure, useFormSubmissionStatus } from '@store';
import { validateValueEqual } from '@utils';

import { SecurityForm } from './securityForm';

export const Security = () => {
  const { id: application_id } = useParams();

  const formType = FormType.CdnSecurity;

  const { t: tSecurityModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.security' });
  const { t: tConfigurationButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons' });

  const tabs = [{ name: tSecurityModal('security'), key: 'security' }];

  const formikRef = useRef<FormikProps<any> | null>(null);
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const formProcedure = useFormProcedure(formType);
  const formSubmitting = useFormSubmissionStatus({ formType });
  const formLoading = useFormLoadingStatus({ formType });

  useFetchFormList({
    formType,
    params: { application_id },
    globalOptions: {
      content: true,
    },
  });

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const handleFormikInstance = useCallback((formik: FormikProps<any> | null) => {
    formikRef.current = formik;
  }, []);

  const SecurityFormHandler = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Save,
        function: handleSaveSettings,
        label: tConfigurationButton('saveSettings'),
        loading: formSubmitting,
        disabled: formLoading,
      },
    ],
  });

  return (
    <div className="flex flex-col flex-1 gap-6 sm:gap-8">
      <div className="flex flex-col sm:flex-row justify-between gap-4">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        <Responsive showAbove="sm">
          {validateValueEqual(activeTab, 'security') && (
            <ActionButtonGroup actionHandlers={SecurityFormHandler} procedure={formProcedure} />
          )}
        </Responsive>
      </div>
      {validateValueEqual(activeTab, 'security') && (
        <SecurityForm onFormikInstance={handleFormikInstance} formikRef={formikRef} />
      )}
      <Responsive showBelow="sm">
        {validateValueEqual(activeTab, 'security') && (
          <div className="self-end">
            <ActionButtonGroup actionHandlers={SecurityFormHandler} procedure={formProcedure} />
          </div>
        )}
      </Responsive>
    </div>
  );
};
