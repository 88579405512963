import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { RECOVER_PASSWORD_SCHEMA, REVERIFY_EMAIL_FORM_CONFIG, ROUTES } from '@constants';
import { DisplayMode, FormActionType, FormType, InputMod, Translation, UserEndpoint } from '@enums';
import { useAppDispatch, useErrorFormInner } from '@hooks';
import {
  resetAuthState,
  submitFormRequest,
  updateFormProcedure,
  useFormError,
  useFormSubmissionStatus,
  useUserNextAction,
} from '@store';
import type { RecoverPasswordFormValues } from '@types';

import { LoginButtonGroup, LoginFormFieldWrapper } from '../../subComponents';

export const ReverifyEmailForm = ({ recaptchaRef }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formType = FormType.User;
  const forgetPasswordFormMetaData = { formType, endpoint: UserEndpoint.REVERIFY_EMAIL };

  const { t: tSignIn } = useTranslation(Translation.SignIn);
  const { t: tButton } = useTranslation(Translation.SignIn, { keyPrefix: 'button' });

  const isFormSubmitting = useFormSubmissionStatus(forgetPasswordFormMetaData);
  const isFormError = useFormError(forgetPasswordFormMetaData);
  const nextAction = useUserNextAction();

  const { fields, initialValues } = REVERIFY_EMAIL_FORM_CONFIG({ email: nextAction?.email });

  const handleSubmit = useCallback(
    (values: RecoverPasswordFormValues) => {
      dispatch(updateFormProcedure({ formType, procedure: FormActionType.Forget }));
      dispatch(
        submitFormRequest({
          formType,
          formData: values,
          globalOptions: {
            recaptcha: true,
            endPath: true,
            endpoint: UserEndpoint.REVERIFY_EMAIL,
          },
          callbackFunction: {
            refetch: () => {
              dispatch(resetAuthState());
              navigate(ROUTES.login);
            },
          },
          ref: {
            recaptcha: recaptchaRef,
          },
        }),
      );
    },
    [formType, dispatch],
  );

  const ReverifyEmailFormErrorInner = () => {
    useErrorFormInner({ submitError: isFormError, isSubmitting: isFormSubmitting });
    return null;
  };

  return (
    <Formik
      initialValues={initialValues as RecoverPasswordFormValues}
      validationSchema={RECOVER_PASSWORD_SCHEMA}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="space-y-5 pt-2">
          <ReverifyEmailFormErrorInner />
          {fields.map((field) => (
            <LoginFormFieldWrapper
              key={field.name}
              field={{
                ...field,
                label: tSignIn(String(field.label)),
                fullWidth: true,
                withoutRing: true,
                withoutLabel: true,
                disabled: true,
                inputMod: InputMod.Filled,
                displayMode: DisplayMode.Dark,
              }}
              translation={tSignIn}
            />
          ))}
          <LoginButtonGroup
            label={{ confirmButton: tButton('sendLinkAgain') }}
            formParams={forgetPasswordFormMetaData}
            hasNavigate
          />
        </Form>
      )}
    </Formik>
  );
};
