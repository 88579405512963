import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getWorkspaceListTableColumn, ROUTES } from '@constants';
import { FormListLayout } from '@containers';
import { FormActionType, FormType, Translation } from '@enums';
import { useFormHandlers } from '@hooks';
import { useCurrentWorkspaceId, useFormList, useWorkspaceOptions, useWorkspaceOptionsLoading } from '@store';

import WorkspaceManagementModal from './workspaceManagementModal';

export const Workspace = () => {
  const navigate = useNavigate();

  const formType = FormType.Workspace;

  const { t: tTables } = useTranslation(Translation.Workspace, { keyPrefix: 'configuration.tables' });
  const { t: tTabs } = useTranslation(Translation.Workspace, { keyPrefix: 'tabs' });

  const [openWorkspaceManagementModal, setOpenWorkspaceManagementModal] = useState<boolean>(false);

  const workspaceList = useWorkspaceOptions();
  const workspaceLoading = useWorkspaceOptionsLoading();
  const currentWorkspaceId = useCurrentWorkspaceId();

  const cdnWorkspaceListTable = useMemo(() => getWorkspaceListTableColumn(), [workspaceList, tTables]);

  const workspaceHandler = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Edit,
        function: (rowData) => {
          navigate(`${ROUTES.workspace.main}/${rowData?.id}${ROUTES.workspace.settings}`);
        },
      },
    ],
  });

  const isFormError = useFormList({ formType });

  useEffect(() => {
    if (isFormError) {
      if (currentWorkspaceId && workspaceList.length > 0) {
        navigate(ROUTES.workspace.main);
      } else navigate(ROUTES.homepage);
    }
  }, [isFormError, workspaceLoading]);

  return (
    <FormListLayout
      label={{
        title: tTabs('workspaceListTitle'),
        subTitle: tTabs('accessAndManageAllYourTeamsCollaborativeSpaces'),
      }}
      tableData={{
        columns: cdnWorkspaceListTable,
        actionHandlers: workspaceHandler,
        formType,
        isLoading: workspaceLoading,
        customTable: workspaceList,
        hasChildren: true,
      }}
    >
      {openWorkspaceManagementModal && (
        <WorkspaceManagementModal
          open={openWorkspaceManagementModal}
          onClose={() => setOpenWorkspaceManagementModal(false)}
        />
      )}
    </FormListLayout>
  );
};
