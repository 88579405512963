import { InputMod } from '@enums';
import { LoginFormConfig } from '@types';

export const REVERIFY_EMAIL_FORM_CONFIG = ({ email }): LoginFormConfig => {
  return {
    initialValues: {
      email: email ?? '',
    },
    fields: [
      {
        name: 'email',
        label: 'form.email',
        inputMod: InputMod.Filled,
        withoutLabel: true,
        placeholder: 'form.enterYourEmailAddress',
      },
    ],
  };
};
