import i18n from 'i18next';

import { Column } from '@components';
import { CellType } from '@enums';
import { capitalizeFirstLetter, formatTableTimestamp, getColumnSizes } from '@utils';

export const getSslStatusLogTable = (): Column[] => [
  {
    label: i18n.t('configuration:tables.time'),
    format: (value) => formatTableTimestamp(value),
    fontSize: 5,
    labelStyle: 'font-medium',
    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(120),
    value: 'timestamp',
  },

  {
    label: i18n.t('configuration:tables.type'),
    value: 'type',
    multiLines: true,
    dotsTextOverflow: true,
    fontSize: 5,
    labelStyle: 'font-medium',
    ...getColumnSizes(60),
    format: (value) => capitalizeFirstLetter(value),
  },
  {
    label: i18n.t('configuration:tables.messages'),
    value: 'message',
    ...getColumnSizes(240),
    multiLines: true,
    fontSize: 5,
    labelStyle: 'font-medium',
  },
];
