import { useMemo } from 'react';

import { CHART_RESPONSIVE_CONFIG } from '@constants';
import type { ChartResponsiveConfig } from '@types';
import { getViewportSpecificConfig, memoizedComputeChartHeight } from '@utils';
import { useViewportQueries } from './useViewportQueries';

interface ChartDimensionsProps {
  dimensions?: ChartResponsiveConfig;
  itemCount?: number;
}

export const useChartDimensions = ({ dimensions, itemCount = 0 }: ChartDimensionsProps) => {
  const viewportQueries = useViewportQueries();

  return useMemo(() => {
    const mergedConfig: ChartResponsiveConfig = dimensions
      ? {
          ...CHART_RESPONSIVE_CONFIG,
          ...dimensions,
        }
      : CHART_RESPONSIVE_CONFIG;

    const activeConfig = getViewportSpecificConfig(mergedConfig, viewportQueries);

    return {
      chartHeight: memoizedComputeChartHeight({ itemCount, dimensions: activeConfig }),
      yAxisWidth: activeConfig.yAxisWidth ?? CHART_RESPONSIVE_CONFIG.desktop.yAxisWidth,
      strokeWidth: activeConfig.strokeWidth ?? CHART_RESPONSIVE_CONFIG.desktop.strokeWidth,
    };
  }, [dimensions, itemCount, viewportQueries]);
};
