import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/Buttons';
import { Text } from '@components/Text';
import { Translation, VariantType } from '@enums';
import { useHealthCheckStatus } from '@hooks';
import type { IHealthCheckProps } from '@types';
import { calculateStatusRatio, getStatusStyles } from '@utils';

const HealthCheckTableCell: React.FC<IHealthCheckProps> = memo(({ status, rowId, onDetailClick }) => {
  const { t: tButton } = useTranslation(Translation.Common, { keyPrefix: 'button' });
  const { t: tTables } = useTranslation(Translation.Configuration, { keyPrefix: 'tables' });

  const { formContent, formLoading } = useHealthCheckStatus(status, rowId);

  const ratio = calculateStatusRatio(formContent);
  const styles = getStatusStyles(ratio);

  const isOff = ratio === 'off';

  return (
    <div className="flex items-center gap-3">
      <Button
        onClick={() => onDetailClick(formContent)}
        hint={tButton('viewStatus')}
        variant={VariantType.Clean}
        disabled={isOff}
        withoutPadding
        loaderColor="rgb(26 131 187 / var(--tw-bg-opacity))"
        loadingWithoutM={formLoading}
        loading={formLoading}
        className={clsx('transition-colors duration-200 shadow-card min-w-[80px] px-3 py-1.5', styles.bg, styles.text)}
      >
        {!formLoading && (
          <Text $level={5} $customizeColor>
            {isOff ? tTables(ratio) : ratio}
          </Text>
        )}
      </Button>
    </div>
  );
});

HealthCheckTableCell.displayName = 'HealthCheckTableCell';

export default HealthCheckTableCell;
