import i18n from 'i18next';

export type optionType = { label: string; value: string; disabled?: boolean };

type OptionsResult = {
  options: optionType[];
  getLabelByValue: any;
};

export const convertOptionList = ({
  originalOptions,
  defaultOptions,
  output = { value: 'id', label: 'label' },
}): OptionsResult => {
  if (!Array.isArray(originalOptions)) {
    return { options: [], getLabelByValue: [] };
  }

  let normalizedOptions = originalOptions
    .map((option) => {
      if (typeof option !== 'object' || option === null) {
        return null;
      }

      const id = option[output.value];
      const name = option[output.label];

      if (id === undefined || name === undefined) {
        return null;
      }

      return {
        value: id,
        label: typeof name === 'string' ? name.trim() : String(name),
      };
    })
    .filter((option): option is optionType => option !== null);

  if (defaultOptions === 'all') {
    normalizedOptions = [{ value: 'all', label: i18n.t('form:common.all') }, ...normalizedOptions];
  }
  const getOriginByValue = (value: string): string | undefined => {
    const option = normalizedOptions.find((option) => option.value === value);
    return option ? option.label : value;
  };
  return { options: normalizedOptions, getLabelByValue: getOriginByValue };
};
