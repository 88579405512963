import type { LoginFormConfig } from '@core/types/forms/user/CreateUserAccountForm';

export const FORGOT_PASSWORD_FORM_CONFIG: LoginFormConfig = {
  initialValues: {
    email: '',
  },
  fields: [
    {
      name: 'email',
      label: 'form.email',
      placeholder: 'form.enterYourEmailAddress',
    },
  ],
} as const;
