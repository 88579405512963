import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text } from '@components';
import { ROUTES } from '@constants';
import { Translation, UserNextAction } from '@enums';
import { useUserNextAction } from '@store';

import { ReverifyEmailForm } from './reverifyEmailForm';

export const ReverifyEmail: FC<any> = ({ recaptchaRef }) => {
  const { t: tTabs } = useTranslation(Translation.SignIn, { keyPrefix: 'tab' });
  const navigate = useNavigate();

  const nextAction = useUserNextAction();

  useEffect(() => {
    if (!nextAction?.email || nextAction?.type !== UserNextAction?.VERIFY_EMAIL) {
      navigate(ROUTES.login);
    }
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <motion.div
        className="text-start space-y-2.5"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.3 }}
      >
        <Text $level={2} $customizeColor className="leading-10 font-medium text-theme-text-main">
          {tTabs('verifyYourEmailAddress')}
        </Text>
        <Text $level={5} $customizeColor className="text-gray-400/90 leading-relaxed">
          {`${tTabs('weSentYouAVerificationLinkByEmail')}?`}
        </Text>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.3 }}
      >
        <ReverifyEmailForm recaptchaRef={recaptchaRef} />
      </motion.div>
    </div>
  );
};
