import { ReactNode, useMemo } from 'react';

import { FormActionType, FormType, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import { resetSubmitFormState, updateFormContent, updateFormProcedure } from '@store';
import { validateValueEqual } from '@utils';

type customizeParams = {
  icon?: boolean;
  location?: string;
  children?: ReactNode;
};

export type FormHandlerOption = {
  type: FormActionType;
  function: (rowData) => void;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  className?: string;
  buttonType?: VariantType;
  customize?: customizeParams;
  display?: boolean;
};

export interface UseFormHandlersParams {
  formType: FormType;
  handlerOptions: FormHandlerOption[];
}

export type FormHandlerWithStatus<T> = {
  handler: (rowData?: T) => void;
  disabled: boolean;
  loading: boolean;
  label?: string;
  buttonType: VariantType;
  className: string;
  customize?: customizeParams | null;
  display: boolean;
};

export type FormHandlers<T> = Record<FormActionType, FormHandlerWithStatus<T>>;

export const useFormHandlers = <T>({ formType, handlerOptions }: UseFormHandlersParams): FormHandlers<T> => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    const handlers: FormHandlers<T> = {} as FormHandlers<T>;

    handlerOptions?.forEach(
      ({
        type,
        label,
        buttonType = VariantType.Contained,
        customize = null,
        function: modalFunction,
        className = '',
        disabled = false,
        loading = false,
        display = true,
      }) => {
        handlers[type] = {
          handler: (rowData?: T) => {
            if (disabled) return;
            dispatch(resetSubmitFormState({ formType }));
            dispatch(updateFormProcedure({ formType, procedure: type }));
            dispatch(
              updateFormContent({
                formType,
                content: validateValueEqual(type, FormActionType.Create) ? {} : rowData ?? {},
              }),
            );
            modalFunction && modalFunction(rowData);
          },
          label,
          buttonType,
          className,
          disabled,
          loading,
          customize,
          display,
        };
      },
    );

    return handlers;
  }, [dispatch, formType, handlerOptions]);
};
