import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, SearchInput, Skeleton, Text } from '@components';
import { InputMod, Translation, VariantType } from '@enums';
import { validateValueEqual } from '@utils';

import type { ObjectOptionType, Options } from '@types';
import ErrorMessage from './TextInput/errorMessage';

interface MultipleSelectInputProps {
  name: string;
  options?: Options;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  disabledSearch?: boolean;
  format?: (label: string) => string;
  className?: string;
  errorClassName?: string;
  customWidth?: string;
  inputMod?: InputMod;
  variant?: VariantType;
  withoutRing?: boolean;
  withoutPadding?: boolean;
  smallFont?: boolean;
  withoutChip?: boolean;
  maxSelections?: number;
}
export const MultipleSelectInput: React.FC<MultipleSelectInputProps> = ({
  name,
  options = [],
  placeholder = '',
  label = '',
  disabled = false,
  loading = false,
  fullWidth = false,
  disabledSearch = false,
  format,
  className = '',
  withoutChip = false,
  errorClassName = '',
  customWidth,
  inputMod = InputMod.Default,
  variant = VariantType.Normal,
  withoutRing = false,
  withoutPadding = false,
  smallFont = false,
  maxSelections,
}) => {
  const [field, meta, helpers] = useField(name);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isError = meta.error && meta.touched;
  const isCustom = validateValueEqual(VariantType.Custom, variant);
  const isFilled = validateValueEqual(InputMod.Filled, inputMod);

  const { t: tTable } = useTranslation(Translation.Common, { keyPrefix: 'tables' });
  const { t: tCommon } = useTranslation(Translation.Common, { keyPrefix: 'multiSelect' });

  const normalizedOptions = useMemo(() => {
    return options.map((option): ObjectOptionType => {
      if (typeof option === 'object' && 'value' in option && 'label' in option) {
        return option as ObjectOptionType;
      }
      return { value: option, label: String(option) };
    });
  }, [options]);

  const selectedValues = useMemo(() => {
    return Array.isArray(field.value)
      ? field.value.map(
          (value) =>
            normalizedOptions.find((option) => validateValueEqual(option.value, value)) || {
              value,
              label: String(value),
            },
        )
      : [];
  }, [field.value, normalizedOptions]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleToggle = useCallback(() => {
    if (!disabled) {
      setIsOpen((prevIsOpen) => !prevIsOpen);
      if (!isOpen) {
        setTimeout(() => {
          const inputRect = inputRef.current?.getBoundingClientRect();
          const dropdownRect = dropdownRef.current?.getBoundingClientRect();
          if (inputRect && dropdownRect) {
            const viewportHeight = window.innerHeight;
            const spaceBelow = viewportHeight - inputRect.bottom;
            const spaceAbove = inputRect.top;
            const dropdownHeight = dropdownRect.height;

            setDropdownPosition(spaceBelow < dropdownHeight && spaceAbove > spaceBelow ? 'top' : 'bottom');
          }
        }, 0);
      }
    }
  }, [disabled, isOpen]);

  const handleOptionClick = useCallback(
    (option: ObjectOptionType) => {
      const isSelected = selectedValues.some((item) => validateValueEqual(item.value, option.value));

      if (!isSelected && selectedValues.length >= (maxSelections ?? Infinity)) {
        return;
      }

      const newSelected = isSelected
        ? selectedValues.filter((item) => !validateValueEqual(item.value, option.value))
        : [...selectedValues, option];
      helpers.setValue(newSelected.map((item) => item.value));
    },
    [selectedValues, helpers, maxSelections],
  );

  const handleDeleteClick = useCallback(
    (optionToDelete: ObjectOptionType) => {
      helpers.setValue(
        selectedValues
          .filter((item) => !validateValueEqual(item.value, optionToDelete.value))
          .map((item) => item.value),
      );
    },
    [selectedValues, helpers],
  );

  const handleSelectAll = useCallback(() => {
    helpers.setValue(
      selectedValues.length === normalizedOptions.length ? [] : normalizedOptions.map((option) => option.value),
    );
  }, [selectedValues, normalizedOptions, helpers]);

  const filteredOptions = useMemo(() => {
    if (!searchTerm) return normalizedOptions;
    const lowerSearchTerm = searchTerm.toLowerCase();
    return normalizedOptions.filter((option) => option.label.toLowerCase().includes(lowerSearchTerm));
  }, [normalizedOptions, searchTerm]);

  const renderOptionLabel = useCallback(
    (option: ObjectOptionType) => (
      <Text $customizeColor $level={6} className="flex-grow leading-4 ellipsis-text">
        {format ? format(option.label) : option.label}
      </Text>
    ),
    [format],
  );

  const isSelectedValuesMoreThanZero = selectedValues.length > 0;
  const inputClasses = classNames(
    'block rounded-md border border-gray-300 shadow-sm placeholder-gray-400 bg-white transition-all duration-200',
    {
      'px-2.5 py-1.5': !isCustom && !withoutPadding && !isFilled,
      'text-us': smallFont,
      'text-sm px-3.5 py-2': !smallFont && !withoutPadding,
      'ring-red-500': isError,
      'bg-gray-100 hover:cursor-not-allowed': disabled,
      'hover:cursor-pointer text-black': !disabled,
      'text-gray-400': disabled,
      'w-full': fullWidth,
      'w-full px-3 text-sm peer outline-none rounded': isFilled,
      'pt-8 pb-3': isFilled && isSelectedValuesMoreThanZero,
      'group-focus-within:pt-10 peer-valid:pt-10 peer-valid:pb-5 group-focus-within:pb-5':
        isFilled && validateValueEqual(selectedValues.length, 0),
      [className]: className,
    },
    !withoutRing && 'focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50',
  );

  if (loading) return <Skeleton className={classNames('h-10 rounded-md', { 'w-full': fullWidth })} />;

  return (
    <div className={`flex flex-col h-fit space-y-3 ${fullWidth ? 'w-full' : ''} ${customWidth ?? ''}`}>
      <div className="relative group" ref={inputRef}>
        <div
          className={`${inputClasses} min-h-[38px] flex flex-wrap items-center gap-2 pr-8 py-2 ${isOpen ? 'ring-2 ring-indigo-600' : ''}`}
          onClick={handleToggle}
        >
          {withoutChip ? (
            <Text $level={6} $customizeColor className={isSelectedValuesMoreThanZero ? `text-black` : `text-gray-400`}>
              {isSelectedValuesMoreThanZero
                ? `${
                    validateValueEqual(selectedValues.length, 1)
                      ? tCommon('oneItemSelected')
                      : tCommon('itemsSelected', { count: selectedValues.length })
                  }`
                : placeholder}
            </Text>
          ) : isSelectedValuesMoreThanZero ? (
            selectedValues.map((item: ObjectOptionType, index) => (
              <Chip
                key={index}
                smallFont
                chipVariant={VariantType.Outlined}
                withoutPadding
                className="px-2 py-1 w-fit"
                onChipClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(item);
                }}
                label={renderOptionLabel(item)}
                adornment={<XMarkIcon className="w-4 h-4 self-center text-blue-400" />}
              />
            ))
          ) : (
            <Text $level={6} $customizeColor className="text-gray-400 leading-4">
              {placeholder}
            </Text>
          )}
          <ChevronUpDownIcon className="w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        {isFilled && label && field.value?.length > 0 && (
          <Text
            $customizeColor
            $level={null}
            className={classNames(
              'pointer-events-none absolute left-3.5 transition-all duration-200 font-medium text-[0.58rem] top-2.5',
              (!withoutChip && isSelectedValuesMoreThanZero) || isOpen ? 'text-gray-500' : 'text-gray-400',
            )}
          >
            {label}
          </Text>
        )}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              ref={dropdownRef}
              initial={{ opacity: 0, y: dropdownPosition === 'bottom' ? -10 : 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: dropdownPosition === 'bottom' ? -10 : 10 }}
              transition={{ duration: 0.2 }}
              className={classNames(
                'absolute w-full z-20 mt-2 max-h-60 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                { 'top-full': dropdownPosition === 'bottom', 'bottom-full': dropdownPosition === 'top' },
              )}
            >
              {!disabledSearch && (
                <div className="sticky top-0 bg-white px-4 pb-3 pt-2 border-b border-gray-200">
                  <SearchInput
                    name="search"
                    className="bg-white bg-opacity-50 focus:bg-opacity-100 focus-within:bg-opacity-100"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e: any) => setSearchTerm(e.target.value)}
                    clearFunction={() => setSearchTerm('')}
                  />
                </div>
              )}
              {options?.length > 0 && options?.length <= 5 && (
                <div
                  className="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-100 transition-colors duration-200"
                  onClick={handleSelectAll}
                >
                  <Text $level={6} className="flex-grow font-medium text-gray-700">
                    {tTable(selectedValues.length === normalizedOptions.length ? 'unselectAll' : 'selectAll')}
                  </Text>
                </div>
              )}
              <div className="overflow-y-auto max-h-32">
                {filteredOptions.map((option, index) => {
                  const isSelected = selectedValues.some((item) => validateValueEqual(item.value, option.value));
                  const isDisabled = !isSelected && selectedValues.length >= (maxSelections ?? Infinity);

                  return (
                    <div
                      key={index}
                      className={classNames(
                        'px-4 py-2 cursor-pointer transition-colors duration-200 flex items-center',
                        isSelected ? 'bg-indigo-50 text-indigo-900' : 'text-gray-900 hover:bg-gray-100',
                        { 'cursor-not-allowed opacity-50': isDisabled },
                      )}
                      onClick={() => !isDisabled && handleOptionClick(option)}
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox size-4 text-indigo-600 rounded border-gray-300 focus:ring-0 mr-3"
                        checked={isSelected}
                        readOnly
                      />
                      {renderOptionLabel(option)}
                    </div>
                  );
                })}
              </div>
              {options.length === 0 && (
                <div className="text-gray-300 items-center py-2 px-4">
                  <Text $level={6} $customizeColor>
                    {tTable('noAvailableOption')}
                  </Text>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {isError && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ErrorMessage isError={isError} errorClassName={errorClassName} meta={meta} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
