import { select } from 'redux-saga/effects';

import { universalFormConfig } from '@constants';

import { FormType } from '@enums';
import type { EndpointType } from '@types';

export function* getFormConfiguration(formType: FormType, endpoint: EndpointType) {
  const { procedure } = yield select((state) => state.form[formType]);
  const { write } = universalFormConfig(formType);
  return { procedure, ...write[endpoint] };
}
