import { Skeleton } from '@components';

export const WsConfigSkeleton = () => {
  return (
    <div className="space-y-4 mx-auto max-w-4xl">
      <Skeleton className="flex w-full rounded-2xl h-32" />
      <Skeleton className="flex w-full rounded-2xl h-32" />
      <Skeleton className="flex w-full rounded-2xl h-32" />
      <Skeleton className="flex w-full rounded-2xl h-32" />
      <Skeleton className="flex w-full rounded-2xl h-32" />
    </div>
  );
};
