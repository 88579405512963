import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '@hooks';
import type { AuthNextAction, AuthSliceState, ErrorType, RecaptchaRef } from '@types';

const authInitialState: AuthSliceState = {
  isLoading: false,
  isAuthenticated: false,
  recaptchaToken: null,
  nextAction: null,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    login: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
    },
    loginSuccess: (state) => {
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    loginError: (state, { payload }: PayloadAction<{ nextAction?: AuthNextAction; error?: ErrorType }>) => {
      const { error, nextAction } = payload;
      state.isAuthenticated = false;
      state.isLoading = false;
      if (nextAction) {
        state.nextAction = nextAction;
      }
      if (error) {
        state.error = error;
      }
    },
    logOut: (state) => {
      state.isLoading = false;
      state.isAuthenticated = authInitialState.isAuthenticated;
      state.recaptchaToken = null;
    },
    changeSystemAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload;
    },
    executeRecaptcha: (state, { payload }: PayloadAction<RecaptchaRef>) => {},
    executeRecaptchaSuccess: (state, { payload }: PayloadAction<string>) => {
      state.recaptchaToken = payload;
    },
    executeRecaptchaError: (state, { payload }: PayloadAction<ErrorType>) => {
      state.recaptchaToken = null;
      state.error = payload;
    },
    clearLoginError: (state) => {
      state.error = null;
      state.nextAction = null;
    },
    resetAuthState: () => authInitialState,
  },
});

export const {
  login,
  loginSuccess,
  loginError,
  logOut,
  changeSystemAuthenticated,
  clearLoginError,
  executeRecaptcha,
  executeRecaptchaSuccess,
  executeRecaptchaError,
  resetAuthState,
} = authSlice.actions;

export const useLoginLoading = () => useAppSelector((state: RootState) => state.auth.isLoading);
export const useLoginError = () => useAppSelector((state: RootState) => state.auth.error);
export const useUserAuthenticated = () => useAppSelector((state: RootState) => state.auth.isAuthenticated);
export const useUserNextAction = () => useAppSelector((state: RootState): AuthNextAction => state.auth.nextAction);

const authReducer = authSlice.reducer;
export default authReducer;
