import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, PredefinedCards, Text } from '@components';
import { ApiEndpoint, FormActionType, FormType, ServiceModalName, StatusType, Translation } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  addServiceModal,
  submitFormRequest,
  updateFormProcedure,
  useFormLoadingStatus,
  useFormSubmissionStatus,
} from '@store';

export const CachePurge = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnCache;

  const { t: tCommon } = useTranslation(Translation.Common);
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.cache' });
  const { t: tForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.cache' });
  const { t: tCacheModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.cache' });

  const clearFormSubmitting = useFormSubmissionStatus({ formType, endpoint: ApiEndpoint.CLEAR });
  const fullFormSubmitting = useFormSubmissionStatus({ formType });
  const fullFormLoading = useFormLoadingStatus({ formType, endpoint: ApiEndpoint.CLEAR });

  const handlePurgeCache = () => {
    dispatch(updateFormProcedure({ formType, procedure: FormActionType.Purge }));
    dispatch(
      addServiceModal({
        name: ServiceModalName.ConfirmModal,
        payload: {
          label: {
            title: tCacheModal('purgeEverything'),
            text: tCacheModal('purgeDescription'),
            confirmButton: tCommon('button.confirm'),
          },
          option: {
            modalType: StatusType.Warning,
            modalTable: {
              formType,
              endpoint: ApiEndpoint.CLEAR,
            },
            modalForm: {
              preventDefault: {
                callback: true,
              },
            },
            button: {
              loading: clearFormSubmitting,
            },
          },
          callbackFunction: {
            submit: () =>
              dispatch(
                submitFormRequest({
                  formType,
                  params: {
                    application_id: application_id,
                  },
                  globalOptions: {
                    endpoint: ApiEndpoint.CLEAR,
                  },
                }),
              ),
          },
        },
      }),
    );
  };

  return (
    <PredefinedCards
      title={tForm('purgeCache')}
      description={
        <div className="flex flex-col gap-4">
          <Text $level={5} color="text-gray-400" className="font-light text-justify leading-4">
            {tForm('cacheFilesWillForceTheCDNToFetchTheLatestVersionsOfTheseFilesFromYourServer')}
          </Text>
          <div className="flex bg-blue-50 fill-blue-400 rounded-md p-3 text-blue-500">
            <InformationCircleIcon className="mr-3 flex min-w-5 h-5" />
            <Text $level={5} className=" bg-blue-50 fill-blue-400 rounded-md" $customizeColor>
              {tForm(
                'clearingTheCacheMayTemporarilyDegradeYourWebsitesPerformanceAndProlongTheTimeItTakesToLoadFilesFromYourSourceServer',
              )}
            </Text>
          </div>
        </div>
      }
    >
      <Button
        fullWidth
        loading={clearFormSubmitting}
        disabled={fullFormLoading || fullFormSubmitting}
        onClick={handlePurgeCache}
      >
        {tButton('clearAll')}
      </Button>
    </PredefinedCards>
  );
};
