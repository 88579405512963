const defaultColors: string[] = [
  'bg-green-200 text-green-800',
  'bg-lime-200 text-lime-800',
  'bg-blue-200 text-blue-800',
  'bg-emerald-200 text-emerald-800',
  'bg-purple-200 text-purple-800',
];

export const getRandomTextAvatarColor = (config?: { colors: string[] }): string => {
  const colorArray = config?.colors || defaultColors;
  return colorArray[Math.floor(Math.random() * colorArray.length)];
};
