import { DefaultPort, originType } from '@enums';
import { validateValueEqual } from '@utils';

export const initializeOriginsValues = (currentForm) => {
  const isHTTPType = validateValueEqual(currentForm.type, originType.HTTP);
  const hasHTTPType = Boolean(currentForm?.type && isHTTPType);
  const getDefaultPort = hasHTTPType ? DefaultPort.HTTP : DefaultPort.HTTPS;

  if (!currentForm?.origins) {
    return [
      {
        origin: '',
        port: getDefaultPort,
      },
    ];
  }
  return currentForm.origins.map((entry) => ({
    origin: entry?.origin,
    port: entry?.port ?? getDefaultPort,
    id: entry?.id,
  }));
};
