import { Formik } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormModal, GFormFields } from '@components';
import { HEALTH_CHECK_FORM_SCHEMA } from '@constants/schema/healthCheckSchema';
import { columnDirection, Dictionaries, FormType, InputType, OriginEndpoint, originType, Translation } from '@enums';
import { useAppDispatch, useFetchFormList } from '@hooks';
import {
  fetchFormList,
  submitFormRequest,
  useFormContent,
  useFormLoadingStatus,
  useFormSubmissionStatus,
} from '@store';
import type { DialogBase, FormFieldProps } from '@types';
import { getOptionListFromCatalog, validateValueEqual } from '@utils';

export const HealthCheckConfigurationModal: FC<DialogBase> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnOrigin;
  const endpoint = OriginEndpoint.HEALTH_CHECK;

  const currentForm = useFormContent({ formType });
  const healthCheckForm = useFormContent({ formType, endpoint });
  const healthCheckFormLoadingStatus = useFormLoadingStatus({ formType, endpoint });
  const healthCheckFormSubmissionStatus = useFormSubmissionStatus({ formType, endpoint });

  const { t: tModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.origin' });
  const { t: tForms } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.origin' });
  const { t: tValidation } = useTranslation(Translation.Validation, { keyPrefix: 'format' });
  const { t: tCommon } = useTranslation(Translation.Common);

  const isHttpsType = validateValueEqual(currentForm?.type, originType.HTTPS);

  useFetchFormList({
    formType,
    params: { content_id: currentForm?.id },
    globalOptions: {
      endpoint,
      content: true,
      endPath: true,
    },
  });

  const handleSubmit = (values: any) => {
    const { protocol, verify_ssl_cert, status, ...updatedValues } = values;

    const isHttpsProtocol = validateValueEqual(protocol, originType.HTTPS);
    const isTcpProtocol = validateValueEqual(protocol, 'tcp');

    const formData = {
      status,
      protocol,
      ...(!isTcpProtocol && { ...updatedValues }),
      ...(isHttpsProtocol && {
        verify_ssl_cert,
      }),
    };
    dispatch(
      submitFormRequest({
        formData,
        formType,
        params: { content_id: currentForm?.id },
        globalOptions: {
          endpoint,
          endPath: true,
        },
        callbackFunction: {
          refetch: fetchFormList({
            formType,
            params: { application_id },
            globalOptions: {
              pagination: true,
            },
          }),
        },
      }),
    );
  };
  const getProtocol = (currentType?: string, healthCheckProtocol?: string) => {
    const isTcpProtocol = validateValueEqual(healthCheckProtocol, 'tcp');
    if (isTcpProtocol) {
      return healthCheckProtocol ?? currentType ?? originType.HTTP;
    }

    return currentType;
  };

  const initialValues: any = useMemo(() => {
    return {
      status: healthCheckForm?.status ?? false,
      host: healthCheckForm?.host ?? '',
      uri: healthCheckForm?.uri ?? '/',
      protocol: getProtocol(currentForm?.type, healthCheckForm?.protocol),
      verify_ssl_cert: healthCheckForm?.verify_ssl_cert ?? true,
    };
  }, [healthCheckForm]);

  const createInputList = (): FormFieldProps[] => {
    return [
      {
        name: 'status',
        inputType: InputType.Switch,
        label: tForms('healthCheck'),
        loading: healthCheckFormLoadingStatus,
        disabled: healthCheckFormSubmissionStatus,
        direction: columnDirection.Row,
        required: true,
      },
      {
        name: 'protocol',
        inputType: InputType.Select,
        options: getOptionListFromCatalog({
          name: Dictionaries.ProtocolTcpType,
          hiddenOptions: [isHttpsType ? originType.HTTP : originType.HTTPS],
        }),
        label: tForms('protocol'),
        loading: healthCheckFormLoadingStatus,
        disabled: healthCheckFormSubmissionStatus,
        direction: columnDirection.Row,
        required: true,
      },
      {
        name: 'host',
        inputType: InputType.Text,
        label: tForms('httpRequestHost'),
        placeholder: tValidation('host'),
        loading: healthCheckFormLoadingStatus,
        disabled: healthCheckFormSubmissionStatus,
        direction: columnDirection.Row,
        required: true,
        hide: {
          field: 'protocol',
          condition: (value: string) => value === 'tcp',
        },
      },
      {
        name: 'uri',
        inputType: InputType.Text,
        label: tForms('httpRequestUri'),
        placeholder: '/status',
        loading: healthCheckFormLoadingStatus,
        disabled: healthCheckFormSubmissionStatus,
        direction: columnDirection.Row,
        required: true,
        hide: {
          field: 'protocol',
          condition: (value: string) => value === 'tcp',
        },
      },
      {
        name: 'verify_ssl_cert',
        inputType: InputType.Switch,
        label: tForms('verifySSL'),
        loading: healthCheckFormLoadingStatus,
        disabled: healthCheckFormSubmissionStatus,
        direction: columnDirection.Row,
        hide: {
          field: 'protocol',
          condition: (value: string) => value !== originType.HTTPS,
        },
      },
    ];
  };

  if (!open) return;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={HEALTH_CHECK_FORM_SCHEMA}
      enableReinitialize
    >
      {({ errors }) => {
        return (
          <FormModal
            formType={formType}
            title={tModal('healthCheckConfiguration')}
            description={tModal('configureAutomaticHealthChecksToMonitorOriginServerAvailability')}
            onClose={onClose}
            size="lg"
            open={open}
            confirmButton={{ children: tCommon('button.configure') }}
          >
            <GFormFields inputList={createInputList()} errors={errors} />
          </FormModal>
        );
      }}
    </Formik>
  );
};
