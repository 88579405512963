import CryptoJS from 'crypto-js';
import { addMonths } from 'date-fns';

import { WorkspaceMemberTable, WorkspaceResponse } from '@core/types/api';
import { sslCertificateType } from '@enums';
import { validateValueEqual } from './validateValueEqual';

export const encrypt64Base = (key?: string): string => {
  if (!key) return '';
  try {
    const wordArray = CryptoJS.enc.Utf8.parse(key);
    return CryptoJS.enc.Base64.stringify(wordArray);
  } catch (error) {
    return key;
  }
};

export const decrypt64Base = (key?: string): string => {
  if (!key) return '';
  try {
    const parsedWordArray = CryptoJS.enc.Base64.parse(key);
    return CryptoJS.enc.Utf8.stringify(parsedWordArray);
  } catch (error) {
    return key;
  }
};
//Form
export const checkSSLFormType = (data) => {
  const { type, public_key, private_key, domain } = data;
  if (type === sslCertificateType.manual) {
    return {
      public_key,
      private_key,
      domain: [] as string[],
    };
  } else if (type === sslCertificateType.letsEncrypt) {
    return {
      public_key: '',
      private_key: '',
      domain,
    };
  }
  return data;
};

function getNewStatus(item: any, isManualType: boolean) {
  if (!item.expired_at) {
    return item.status ? 'valid' : 'inactive';
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const oneMonthFromNow = Math.floor(addMonths(new Date(), 1).getTime() / 1000);
  const expirationTime = Math.floor(new Date(item.expired_at).getTime() / 1000);

  const isExpired = expirationTime < currentTime;
  const isExpiringSoon = !isExpired && expirationTime <= oneMonthFromNow;

  if (!item.status) {
    return 'inactive';
  }

  if (isExpired) {
    return 'expired';
  }

  if (isExpiringSoon) {
    return 'expiredSoon';
  }

  if (isManualType) {
    return 'valid';
  }

  return item.status === 0 ? 'pendingIssue' : 'valid';
}

export const updateStatus = <T extends { status?: string | number; type?: 'auto' | 'manual'; expiredAt?: number }>(
  data: T[],
) => {
  return data.map((item) => {
    if (item?.type) {
      const isManualType = validateValueEqual(item?.type, 'manual');

      const newStatus = getNewStatus(item, isManualType);

      return {
        ...item,
        status: newStatus,
      };
    }
  });
};

export const noOperation = (data: any[]) => {
  return data;
};

export const checkResponseVerificationStatus = <T extends { status?: string | number }>(data: T[]) => {
  return updateStatus(data);
};

export const checkResponseCertificateStatus = <T extends { status?: string | number }>(data: T[]) => {
  return updateStatus(data);
};

export const getWorkspaceStatus = (status: number): string => {
  switch (status) {
    case 1:
      return 'active';
    default:
      return 'pending';
  }
};

export const transformWorkspaceStatus = (response: WorkspaceResponse): WorkspaceMemberTable => {
  return {
    ...response,
    permissions: [
      ...response.permissions.map((permission) => ({
        ...permission,
        status: typeof permission.status === 'number' ? getWorkspaceStatus(permission.status) : permission.status,
      })),
    ],
  };
};
