import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigurationTab } from '@components';
import { DashboardEndpoint, Translation } from '@enums';
import { validateValueEqual } from '@utils';

import { DashboardFilter } from './dashboardFilter';
import { AttackLog } from './subPages/attackLog';
import { ErrorLog } from './subPages/errorLog';
import { LogPage } from './subPages/logPage';
import { TrafficPage } from './subPages/trafficPage';
import { WafLog } from './subPages/wafLog';

export const CdnDashboard = () => {
  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.dashboard' });

  const tabs = [
    { name: tTabs('traffic'), key: 'traffic' },
    { name: tTabs('log'), key: 'log' },
    { name: tTabs('errorLog'), key: 'errorLog' },
    { name: tTabs('wafLog'), key: 'wafLog' },
  ];

  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  const handleCurrentTab = (data: string) => {
    setActiveTab(data);
  };

  const tabContent = () => {
    switch (activeTab) {
      case 'traffic':
        return <TrafficPage />;
      case 'log':
        return <LogPage />;
      case 'attackLog':
        return <AttackLog />;
      case 'errorLog':
        return <ErrorLog />;
      case 'wafLog':
        return <WafLog />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-1 flex-col w-full gap-8">
      <div className="flex flex-row justify-between gap-4">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        {validateValueEqual(activeTab, 'traffic') ? (
          <DashboardFilter currentTab={activeTab} targetFrom={DashboardEndpoint.TOTAL_TRAFFIC} />
        ) : null}
      </div>
      <div className="gap-8">{tabContent()}</div>
    </div>
  );
};
