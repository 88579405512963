import { motion } from 'framer-motion';
import { Children, cloneElement, FC, isValidElement, ReactElement, ReactNode, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation, useNavigate } from 'react-router-dom';

import { FixedBackground, GlobalLoader, TrianglesBackground } from '@components';
import { ERROR_MESSAGE, ROUTES } from '@constants';
import { UserNextAction } from '@enums';
import { useInvitation } from '@hooks';
import { useLoginError, useUserNextAction } from '@store';
import { cn, validateValueEqual } from '@utils';

import LoginBg from '@assets/loginBg.webp';
import CompanyLogo from '@assets/logo.webp';
import { useImagePreload } from '@hooks/system/useImagePreload';
import type { WithRecaptchaRef } from '@types';

interface LoginLayoutProps {
  children: ReactNode;
  className?: string;
}

export const LoginLayout: FC<LoginLayoutProps> = ({ children, className }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const nextAction = useUserNextAction();
  const loginError = useLoginError();

  const { isLoading: isImageLoading, error: imageError } = useImagePreload(LoginBg);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  useEffect(() => {
    if (nextAction?.type === UserNextAction.VERIFY_EMAIL) {
      navigate(ROUTES.reverifyEmail);
    }
  }, [nextAction, navigate]);

  useEffect(() => {
    //Due to recaptcha, I had to reset it everytime i change a route
    recaptchaRef.current?.reset();
  }, [navigate]);

  useEffect(() => {
    if (loginError === ERROR_MESSAGE.RECAPTCHA_ERROR) {
      window.location.reload();
    }
  }, [loginError]);

  useInvitation();

  if (isImageLoading) return <GlobalLoader overlay />;

  const isLogin = validateValueEqual(pathname, ROUTES.login);
  const siteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

  return (
    <div className={cn('relative w-full h-full bg-black', 'overflow-y-auto padded-scrollbar-track', className)}>
      {imageError ? (
        <FixedBackground backgroundClassName="flex h-screen flex-col items-center justify-center border-b border-neutral-800">
          <div className="h-full w-full overflow-hidden [perspective:1000px] [transform-style:preserve-3d]">
            <TrianglesBackground
              style={{ transform: 'rotateX(45deg)' }}
              className="[mask-image:linear-gradient(to_top,white,transparent)]"
            />
            <TrianglesBackground
              style={{ transform: 'rotateX(-45deg)' }}
              className="[mask-image:linear-gradient(to_bottom,white,transparent)]"
            />
          </div>
        </FixedBackground>
      ) : (
        <FixedBackground content={LoginBg} />
      )}
      <div className="relative w-full min-h-screen overflow-y-auto">
        <div
          className={cn(
            'flex items-center justify-center w-full min-h-screen',
            'px-4 py-8',
            !imageError && 'bg-gradient-to-br from-gray-900/50 to-gray-800/50',
          )}
        >
          <motion.div
            className={cn(
              'w-full max-w-sm rounded-xl',
              'bg-theme-neutral-dark/90',
              'backdrop-blur-sm backdrop-filter',
              'ring-1 ring-white/10',
              'shadow-glow',
              'px-8 pt-8 pb-9',
            )}
          >
            <div className="flex flex-col gap-8 px-1">
              {isLogin && (
                <motion.div className="flex flex-col items-center pt-1 space-y-6">
                  <img src={CompanyLogo} alt="Company Logo" className="w-auto h-16 md:h-20" />
                </motion.div>
              )}

              {Children.map(children, (child) => {
                if (isValidElement(child)) {
                  return cloneElement(child as ReactElement<WithRecaptchaRef>, {
                    recaptchaRef,
                  });
                }
                return child;
              })}
            </div>
          </motion.div>

          <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} theme="light" size="invisible" className="z-50" />
        </div>
      </div>
    </div>
  );
};
