import { ApexOptions } from 'apexcharts';
import { motion } from 'framer-motion';
import { memo, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { NoRecordFound } from '@components';
import { LINE_GRAPH_RESPONSIVE_CONFIG, SCREEN_BREAKPOINTS } from '@constants';
import { useChartDimensions, useMediaQuery } from '@hooks';
import { useFormList } from '@store';
import { formatYAxisValue } from '@utils';

export const CdnConfigurationDashboardCacheChart = memo(({ payload }: any) => {
  const graphData = useFormList(payload);

  const { chartHeight } = useChartDimensions({ dimensions: LINE_GRAPH_RESPONSIVE_CONFIG });
  const isAboveMd = useMediaQuery(`(min-width: ${SCREEN_BREAKPOINTS.md}px)`);

  const { seriesData } = useMemo(() => {
    const trafficValue = [] as number[];
    const trafficKey = [] as string[];
    let max = 0;

    (graphData ?? []).forEach(({ cache_status = '', count = 0 }: { cache_status: string; count: number }) => {
      trafficValue.push(count);
      trafficKey.push(cache_status);
      if (count > max) max = count;
    });

    return { seriesData: { trafficValue, trafficKey }, maxValue: max };
  }, [graphData]);

  const options: ApexOptions = useMemo(
    () => ({
      labels: seriesData.trafficKey,
      legend: {
        position: 'top',
      },
      tooltip: {
        y: {
          formatter: (value) => formatYAxisValue({ value, useUnit: true, decimalPlaces: 2 }),
        },
      },
      theme: {
        mode: 'light',
        monochrome: {
          enabled: true,
          color: '#4aa1ff',
          shadeTo: 'light',
          shadeIntensity: 0.95,
        },
      },
      chart: {
        animations: {
          enabled: isAboveMd,
          easing: 'easeinout',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
    }),
    [seriesData.trafficKey],
  );

  const series = useMemo(() => seriesData.trafficValue, [seriesData.trafficValue]);
  if (!graphData.length) return <NoRecordFound />;

  return (
    <motion.div
      className="flex justify-center mt-8"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <ReactApexChart options={options} series={series} type="pie" height={chartHeight} />
    </motion.div>
  );
});
