import { FormikProps } from 'formik';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormActionType, FormType, Translation } from '@enums';
import { useFetchFormList, useFormHandlers } from '@hooks';
import { useFormLoadingStatus, useFormProcedure, useFormSubmissionStatus } from '@store';

import { ActionButtonGroup, ConfigurationTab, Responsive } from '@components';
import { PredefinedWAFRules } from './';

export const Waf = () => {
  const { id: application_id } = useParams();

  const formType = FormType.CdnWAF;

  const { t: tWAFbutton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.waf' });
  const { t: tConfigurationButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons' });

  const tabs = [
    { name: tWAFbutton('wafRules'), key: 'wafRules' },
    // { name: tWAFbutton('customWaf'), key: 'customWaf' },
    // { name: tWAFbutton('wafWhitelist'), key: 'wafWhitelist' },
  ];
  const formProcedure = useFormProcedure(formType);

  const formikRef = useRef<FormikProps<any> | null>(null);

  const [activeTab, setActiveTab] = useState(tabs[0].key);

  useFetchFormList({
    formType,
    params: { application_id },
    globalOptions: { content: true },
  });

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleFormikInstance = useCallback((formik: FormikProps<any> | null) => {
    formikRef.current = formik;
  }, []);

  const tabContent = () => {
    switch (activeTab) {
      default:
        return <PredefinedWAFRules onSaveClose={handleSaveSettings} onFormikInstance={handleFormikInstance} />;
    }
  };

  const predefinedWAFSubmitting = useFormSubmissionStatus({ formType });
  const predefinedWAFLoading = useFormLoadingStatus({ formType });

  const WAFFormHandler = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Save,
        function: handleSaveSettings,
        label: tConfigurationButton('saveSettings'),
        loading: predefinedWAFSubmitting,
        disabled: predefinedWAFLoading,
      },
    ],
  });

  return (
    <div className="flex flex-1">
      <div className="flex flex-col flex-1 gap-6 sm:gap-8">
        <div className="flex flex-col sm:flex-row justify-between gap-4">
          <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
          <Responsive showAbove="sm">
            <ActionButtonGroup actionHandlers={WAFFormHandler} procedure={formProcedure} />
          </Responsive>
        </div>
        {tabContent()}
        <Responsive showBelow="sm">
          <div className="self-end">
            <ActionButtonGroup actionHandlers={WAFFormHandler} procedure={formProcedure} />
          </div>
        </Responsive>
      </div>
    </div>
  );
};
