import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { Button, getColorMapping, Text } from '@components';
import { FormType, StatusType, TableActionType, Translation } from '@enums';
import { FormHandlers } from '@hooks';
import { CdnData, CdnStatus } from '@pages';
import { useFormLoadingStatus } from '@store';

type CdnCardsProps = {
  data: CdnData;
  actionHandlers: FormHandlers<any>;
};

export const CdnCards = ({ data, actionHandlers }: CdnCardsProps) => {
  const formType = FormType.Cdn;

  const { t: tButton } = useTranslation(Translation.Common, { keyPrefix: 'button' });
  const { t: tValidationServer } = useTranslation(Translation.Validation, { keyPrefix: 'server' });

  const isFormLoading = useFormLoadingStatus({ formType });

  const statusType = data.status === CdnStatus.Active ? StatusType.Info : StatusType.Error;
  const colorMapping = getColorMapping(statusType);
  const IconComponent = colorMapping?.icon;

  const renderStatus = (cellType: CdnStatus) => {
    switch (cellType) {
      case CdnStatus.Active:
        return tValidationServer('isWorkingFine');
      case CdnStatus.InActive:
        return tValidationServer('isDeactivated');
      default:
        return null;
    }
  };

  return (
    <dl className="flex flex-1 flex-col p-6 gap-4">
      <div className="grid grid-cols-[minmax(0,1fr)_auto] justify-between items-center gap-3">
        <Text $customizeColor $level={2} className="font-medium ellipsis-text min-w-0">
          {data.name}
        </Text>
        <Tippy content={<article className="text-us">{renderStatus(data?.status)}</article>} placement="top">
          <div className="hover:cursor-pointer">{IconComponent && <IconComponent className="h-8 w-8" />}</div>
        </Tippy>
      </div>
      <Text $level={5} color="text-gray-400" className="font-light">
        {renderStatus(data?.status)}
      </Text>

      <Button
        fullWidth
        className="py-2 mt-2"
        rounded="rounded-2xl"
        withoutPadding
        onClick={() => {
          data && actionHandlers[TableActionType.View] && actionHandlers[TableActionType.View].handler?.(data.id);
        }}
        disabled={isFormLoading}
      >
        <article className="ellipsis-text text-us">{tButton('view')}</article>
      </Button>
    </dl>
  );
};
