import i18n from 'i18next';

import { Badge, Column, Text } from '@components';
import { CellType } from '@enums';
import { formatTableTimestamp, getColumnSizes } from '@utils';

export const getDashboardTrafficLogsColumns = (): Column[] => [
  {
    label: i18n.t('configuration:tables.id'),
    value: 'id',
    multiLines: true,
    fontSize: 5,
    labelStyle: 'font-medium',
    ...getColumnSizes(40),
  },
  {
    label: i18n.t('configuration:tables.logInformation'),
    value: '',
    multiLines: true,
    cellType: CellType.Custom,
    format: (_value, rowData) => {
      const logEntries = [
        { label: i18n.t('configuration:tables.ipAddress'), value: rowData?.ip_address ?? '-' },
        { label: i18n.t('configuration:tables.host'), value: rowData?.host ?? '-' },
        {
          label: i18n.t('configuration:tables.request'),
          value: rowData?.request_method && rowData?.uri ? `${rowData?.request_method} ${rowData?.uri}` : '-',
        },
        { label: i18n.t('configuration:tables.user-agent'), value: rowData?.user_agent ?? '-' },
      ];
      return (
        <div className="flex flex-col space-y-4 select-text">
          {logEntries.map((entry, index) => (
            <div key={index} className="flex flex-col space-y-1.5 flex-wrap">
              <Text $level={5} className="font-medium" color="black">
                {entry.label}
              </Text>
              <Text $level={5} className="font-light w-full whitespace-break-spaces break-words">
                {entry.value}
              </Text>
            </div>
          ))}
        </div>
      );
    },
    ...getColumnSizes(250),
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'limit_action',
    format: (value) => {
      return (
        <div className="h-full">
          <Badge
            color={value === 'block' ? 'red' : value === 'challenge' ? 'cyan' : 'yellow'}
            className="self-start px-2.5 py-1"
          >
            {i18n.t(`common:tables.${value && value !== '' ? value : 'noActionTaken'}`)}
          </Badge>
        </div>
      );
    },
    cellType: CellType.Custom,
    multiLines: true,
    dotsTextOverflow: true,
    ...getColumnSizes(90),
  },
  {
    label: i18n.t('configuration:tables.logDate'),
    value: 'timestamp',
    format: (value) => formatTableTimestamp(value),
    fontSize: 5,
    labelStyle: 'font-medium',
    cellType: CellType.Custom,
    multiLines: true,
    ...getColumnSizes(120),
  },
];
