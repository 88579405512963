import type { LoginFormConfig } from '@core/types/forms/user/CreateUserAccountForm';

export const USER_LOGIN_FORM_CONFIG: LoginFormConfig = {
  initialValues: {
    username: '',
    password: '',
  },
  fields: [
    {
      name: 'username',
      label: 'form.username',
      placeholder: 'form.enterYourUsername',
      autoComplete: 'email',
    },

    {
      name: 'password',
      type: 'password',
      label: 'form.password',
      placeholder: 'form.enterYourPassword',
      isAnimated: true,
      autoComplete: 'password',
    },
  ],
} as const;
