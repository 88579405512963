import i18n from '@translations';
import * as Yup from 'yup';

import { ValidationPatterns } from '@constants';
import { ValidationType } from '@enums';
import type { ValidationConfig, ValidationRuleOptions } from '@types';
import { validateDependency } from './dependencyValidator';

const schemaCache = new WeakMap();

export const createValidationSchema = (config: ValidationConfig): Yup.Schema => {
  if (schemaCache.has(config)) {
    return schemaCache.get(config);
  }

  const schema = buildSchema(config);
  schemaCache.set(config, schema);
  return schema;
};

const buildSchema = (config: ValidationConfig): Yup.ObjectSchema<any> => {
  const schemaFields: Record<string, Yup.Schema> = Object.entries(config).reduce((acc, [field, rules]) => {
    acc[field] = createFieldValidation(rules);
    return acc;
  }, {});

  return Yup.object().shape(schemaFields);
};

const createFieldValidation = (rules: ValidationRuleOptions): Yup.StringSchema => {
  let schema = Yup.string();

  if (rules.valueTransformer) {
    schema = schema.transform(rules.valueTransformer);
  }

  return schema.test({
    name: 'composite',
    test: function (value) {
      const formData = this.parent;
      const isNumberValidation = rules.pattern === ValidationType.NUMBERS;

      if (rules.dependsOn && !validateDependency(formData, rules.dependsOn)) {
        return true;
      }

      if (!value && rules.required) {
        return this.createError({
          message: i18n.t(rules.isSelection ? 'validation:selectRequired' : 'validation:required'),
        });
      }

      if (!value && !rules.validateWhenOptional) {
        return true;
      }

      if (value && (rules.minLength || rules.maxLength)) {
        const length = value.length;
        if (isNumberValidation && rules.pattern) {
          const numValue = Number(value);
          const patternRule = typeof rules.pattern === 'string' ? ValidationPatterns[rules.pattern] : rules.pattern;

          if (isNaN(numValue)) {
            return this.createError({
              message: i18n.t('validation:invalidFormat', {
                type: i18n.t(patternRule.type),
                description: i18n.t(patternRule.format),
              }),
            });
          }

          if (rules.minLength && rules.maxLength) {
            if (numValue < rules.minLength || numValue > rules.maxLength) {
              return this.createError({
                message: i18n.t('validation:invalidFormat', {
                  type: i18n.t(patternRule.type),
                  description: i18n.t('validation:formats.numberRange', {
                    min: rules.minLength,
                    max: rules.maxLength,
                  }),
                }),
              });
            }
          } else if (rules.minLength && numValue < rules.minLength) {
            return this.createError({
              message: i18n.t('validation:invalidFormat', {
                type: i18n.t('validation:types.range'),
                description: i18n.t('validation:formats.minNumber', {
                  min: rules.minLength,
                }),
              }),
            });
          } else if (rules.maxLength && numValue > rules.maxLength) {
            return this.createError({
              message: i18n.t('validation:invalidFormat', {
                type: i18n.t('validation:types.range'),
                description: i18n.t('validation:formats.maxNumber', {
                  max: rules.maxLength,
                }),
              }),
            });
          }
        } else if (rules.minLength && rules.maxLength) {
          if (length < rules.minLength || length > rules.maxLength) {
            return this.createError({
              message: i18n.t('validation:formats.lengthRange', {
                min: rules.minLength,
                max: rules.maxLength,
              }),
            });
          }
        } else if (rules.minLength && length < rules.minLength) {
          return this.createError({
            message: i18n.t('validation:formats.minLength', { minLength: rules.minLength }),
          });
        } else if (rules.maxLength && length > rules.maxLength) {
          return this.createError({
            message: i18n.t('validation:formats.maxLength', { maxLength: rules.maxLength }),
          });
        }
      }

      if (rules.pattern && value) {
        const patternRule = typeof rules.pattern === 'string' ? ValidationPatterns[rules.pattern] : rules.pattern;

        if (!patternRule.regex.test(value)) {
          return this.createError({
            message: i18n.t('validation:invalidFormat', {
              type: i18n.t(patternRule.type),
              description: i18n.t(patternRule.format),
            }),
          });
        }
      }

      if (rules.customRule?.validator) {
        const isValid = rules.customRule.validator({ value, formData });
        if (!isValid) {
          const message =
            typeof rules.customRule.message === 'function'
              ? rules.customRule.message({ value, formData })
              : rules.customRule.message;
          return this.createError({ message: i18n.t(message) });
        }
      }

      return true;
    },
  });
};
