import i18n from 'i18next';

import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { call, delay, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  addSnack,
  cancelAutoReload,
  deleteFormContent,
  deleteFormContentError,
  executeRecaptcha,
  executeRecaptchaError,
  executeRecaptchaSuccess,
  fetchDashboardGraph,
  fetchFormList,
  fetchFormListError,
  fetchFormListSuccess,
  fetchSortingListSuccess,
  resetAuthState,
  resetSubmitFormState,
  submitFormError,
  submitFormRequest,
  submitFormSuccess,
  updateFormPages,
} from '@store';

import { universalFormConfig } from '@constants';
import { ApiEndpoint, DashboardEndpoint, FormType, StatusType } from '@enums';
import {
  API,
  createFormError,
  getApiRequestUrl,
  getCurrentUnixTime,
  getFormConfiguration,
  handleCallbackFunction,
  validateValueEqual,
} from '@utils';

import type { CallbackOptionPayload, DashboardFilter, EndpointType, FormPayload, RecaptchaRef } from '@types';
import { getPaginationInfo } from '@utils/form/getPaginationInfo';
import { validateRuleOptions } from '@utils/form/validateToastRules';

export function* fetchFormListSaga({ payload }: PayloadAction<FormPayload>) {
  const {
    formType,
    params,
    callbackFunction,
    globalOptions: {
      pagination = false,
      content = false,
      endPath = false,
      unique = false,
      refetch_id,
      toast = false,
      toastOption = {
        type: StatusType.Info,
        message: '',
      },
      filter = false,
      refetchAnywhere = false,
      endpoint = ApiEndpoint.DEFAULT,
    } = {},
  } = payload;
  try {
    const { read } = universalFormConfig(formType);
    const { path, endPath: lastPath, formatData } = read[endpoint];

    let url = path;
    if (params?.content_id) {
      url += `/${params.content_id}`;
    }

    if (endPath && lastPath) {
      url += lastPath;
    }
    const { pagination: formPagination } = yield select((state) => state.form[formType]);
    const { filterCriteria } = yield select((state) => state.form[formType].filter);

    const getFiltersByEndpoint = (endpoint: string, filterCriteria: DashboardFilter[]) => {
      const sourceType = (() => {
        switch (endpoint) {
          case DashboardEndpoint.TRAFFIC_LOG:
            return DashboardEndpoint.TRAFFIC_LOG;
          case DashboardEndpoint.ERROR_LOG:
            return DashboardEndpoint.ERROR_LOG;
          case DashboardEndpoint.WAF_LOG:
            return DashboardEndpoint.WAF_LOG;
          default:
            return DashboardEndpoint.TOTAL_TRAFFIC;
        }
      })();

      return filterCriteria
        .filter((criteria) => criteria.from === sourceType)
        .reduce((acc, { label, value }) => {
          if (label === 'domain_id' && value === 'all') {
            return acc;
          }
          return {
            ...acc,
            [label]: value,
          };
        }, {});
    };

    const filterValue = getFiltersByEndpoint(endpoint, filterCriteria);

    const page = formPagination[endpoint]?.page ?? 1;
    const page_size = formPagination[endpoint]?.page_size ?? 5;

    const requestParams = {
      ...(params && {
        ...Object.fromEntries(Object.entries(params).filter(([key]) => key !== 'content_id')),
      }),
      ...(filter && { ...filterValue }),
      ...(pagination && { page, page_size }),
    };

    const { data, headers } = yield call(API.get, url, requestParams ? { params: requestParams } : undefined);

    const paginationHeader = headers['x-pagination'] || headers['X-Pagination'];
    const paginationInfo = paginationHeader ? JSON.parse(paginationHeader) : null;

    if (validateValueEqual(endpoint, ApiEndpoint.SORTING) && data && Array.isArray(data)) {
      const updatedResponse = data?.map((item, index) => ({
        ...item,
        original_id: index + 1,
        sort_order: index,
      }));

      yield put(
        fetchSortingListSuccess({
          formType,
          endpoint,
          responseResult: updatedResponse,
        }),
      );
      return;
    }

    const formatedData = formatData ? yield call(formatData, data) : data;
    if (toast && toastOption) {
      if (!toastOption?.rules || validateRuleOptions(formatedData, toastOption.rules)) {
        yield put(
          addSnack({
            type: toastOption.type,
            message: toastOption.message,
          }),
        );
      }
    }
    yield put(
      fetchFormListSuccess({
        formType,
        endpoint,
        content,
        unique,
        ...(unique && {
          unique: true,
          uniqueId: params?.content_id,
        }),
        lastUpdated: getCurrentUnixTime(),
        list: formatedData,
        ...(pagination && {
          paginationInfo: {
            page: page,
            page_size: page_size,
            ...paginationInfo,
          },
        }),
      }),
    );
  } catch (error) {
    if (!axios.isCancel(error)) {
      yield put(
        fetchFormListError({
          formType,
          endpoint,
          responseResult: createFormError(error),
        }),
      );
    }
    if (refetchAnywhere && callbackFunction?.refetch) {
      yield* handleCallbackFunction(callbackFunction.refetch);
      if (refetch_id) {
        yield put(resetSubmitFormState({ formType }));
        yield put(
          fetchFormList({
            formType,
            params: { application_id: refetch_id },
            globalOptions: { pagination: true },
          }),
        );
      }
    }
  }
}

export function* fetchDashboardGraphSaga({ payload }: PayloadAction<any>) {
  const { globalOptions } = payload;
  if (!globalOptions?.endpoint?.length) return;
  const autoReload = globalOptions?.reload ?? false;

  try {
    while (true) {
      const createBatchPayloads = (payload: any): any[] =>
        payload.globalOptions.endpoint.map((endpoint) => ({
          formType: payload.formType,
          params: payload.params,
          globalOptions: {
            ...payload.globalOptions,
            endpoint,
          },
        }));

      const batchPayloads = createBatchPayloads(payload);

      for (const batchPayload of batchPayloads) {
        yield fork(function* () {
          yield put(fetchFormList(batchPayload));
        });
      }

      if (!autoReload) break;

      const { cancel } = yield race({
        timeout: delay(10000),
        cancel: take(cancelAutoReload.type),
      });
      if (cancel) {
        break;
      }
    }
  } catch (error) {
    console.warn(error);
  }
}

export function* submitFormRequestSaga({ payload }: PayloadAction<FormPayload>) {
  const { formType, formData, params, globalOptions = {}, ref = {}, callbackFunction } = payload;

  const {
    pagination = false,
    refetch_id,
    response = false,
    returnResult = false,
    returnPath,
    toast = true,
    endPath = false,
    recaptchaParams = false,
    refetchAnywhere = false,
    content = false,
    endpoint = ApiEndpoint.DEFAULT,
    refetch_endpoint = ApiEndpoint.DEFAULT,
    recaptcha = false,
  } = globalOptions;

  const { recaptcha: recaptchaRef } = ref;
  try {
    let formDataWithRecaptcha = { ...formData };

    if (recaptcha && recaptchaRef) {
      yield put(executeRecaptcha(recaptchaRef as any));
      const recaptchaResult = yield race({
        success: take(executeRecaptchaSuccess.type),
        error: take(executeRecaptchaError.type),
      });
      if (recaptchaResult.error) {
        yield put(
          submitFormError({
            formType,
            endpoint,
            responseResult: {
              message: 'Recaptcha validation failed',
            },
          }),
        );
        return;
      }
      const { recaptchaToken: recaptcha_token } = yield select((state) => state.auth);
      formDataWithRecaptcha = {
        ...formDataWithRecaptcha,
        ...(!recaptchaParams && { recaptcha_token }),
      };
    }

    const { recaptchaToken: recaptcha_token } = yield select((state) => state.auth);
    const formConfig = yield* getFormConfiguration(formType, endpoint);
    const { url, requestParams } = getApiRequestUrl({
      formConfig,
      params: {
        ...params,
        ...(recaptchaParams && { recaptcha_token }),
      },
      endPath,
    });

    const result = yield call(API.post, url, formDataWithRecaptcha, requestParams);

    const formattedData =
      result?.data && formConfig.formatData ? yield call(formConfig.formatData, result?.data) : result?.data;

    yield* handleFormSubmissionSuccess({
      data: formattedData,
      headers: result?.headers,
      formType,
      endpoint,
      formConfig,
      globalOptions: {
        toast,
        response,
        returnResult,
        pagination,
        content,
        returnPath,
      },
    });
    if (refetch_id) {
      yield put(
        fetchFormList({
          formType,
          params: { application_id: refetch_id },
          globalOptions: { pagination: true, endpoint: refetch_endpoint },
        }),
      );
    }
    if (callbackFunction?.refetch) {
      yield* handleCallbackFunction(callbackFunction.refetch);
    }
  } catch (error) {
    yield* handleFormSubmissionError({
      error,
      formType,
      endpoint,
      refetchAnywhere,
      callbackFunction,
      refetch_id,
      ...(recaptcha && recaptchaRef && { recaptchaRef }),
    });
  }
}

export function* deleteFormContentSaga({ payload }: PayloadAction<FormPayload>) {
  const {
    formType,
    formData,
    params,
    globalOptions: {
      toast = true,
      refetch_id,
      endPath = false,
      returnPath,
      content = false,
      returnResult = false,
      refetchAnywhere = false,
      endpoint = ApiEndpoint.DEFAULT,
    } = {},
    callbackFunction,
  } = payload;
  const { write } = universalFormConfig(formType);

  try {
    const { path, formatData, endPath: lastPath, translation } = write[endpoint];

    const { procedure } = yield select((state) => state.form[formType]);
    const { pagination } = yield select((state) => state.form[formType]);

    const deletedCount = 1;

    let url = path;
    if (params?.content_id) {
      url += `/${params.content_id}`;
    }
    if (endPath && lastPath) {
      url += lastPath;
    }
    const { content_id, ...updatedParams } = params as any;
    const requestData = formData ? { data: formData } : undefined;

    const { data } = yield call(API.delete, url, requestData);

    if (toast) {
      yield put(
        addSnack({
          type: StatusType.Success,
          message: i18n.t(`${translation}.${procedure.toLowerCase()}Successful`),
        }),
      );
    }

    const formatedData = formatData ? yield call(formatData, data) : data;

    if (pagination?.[endpoint]) {
      const updatedPage = yield call(
        getUpdatedPage,
        pagination[endpoint].page,
        deletedCount,
        pagination[endpoint].total,
        pagination[endpoint].page_size,
      );

      if (updatedPage !== pagination.page) {
        yield put(
          updateFormPages({
            formType,
            params: { application_id: refetch_id, ...updatedParams },
            globalOptions: { endpoint },
            page: updatedPage,
          }),
        );
      }
    }

    if (!refetchAnywhere && callbackFunction?.refetch) {
      yield* handleCallbackFunction(callbackFunction.refetch);
    }
    if (returnResult) {
      yield put(
        fetchFormListSuccess({
          formType,
          endpoint: returnPath ?? endpoint,
          lastUpdated: getCurrentUnixTime(),
          content: content,
          list: formatedData,
        }),
      );
    }
    if (refetch_id) {
      yield put(resetSubmitFormState({ formType }));
      yield put(
        fetchFormList({
          formType,
          params: { application_id: refetch_id },
          globalOptions: { pagination: true },
        }),
      );
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorDetails = errorResponse?.response?.data ?? error;
    yield put(
      deleteFormContentError({
        formType,
        endpoint,
        ...(endpoint && {
          error: errorDetails,
        }),
      }),
    );

    if (refetchAnywhere && callbackFunction?.refetch) {
      yield* handleCallbackFunction(callbackFunction.refetch);
      if (refetch_id) {
        yield put(resetSubmitFormState({ formType }));
        yield put(
          fetchFormList({
            formType,
            params: { application_id: refetch_id },
            globalOptions: { pagination: true },
          }),
        );
      }
    }
  }
}

export function* updateFormPagesSaga({ payload }: any) {
  const { formType, params, globalOptions } = payload;
  yield put(
    fetchFormList({
      formType,
      params,
      globalOptions: {
        ...globalOptions,
        pagination: true,
      },
    }),
  );
}

function* handleFormSubmissionSuccess({ data, headers, formType, endpoint, formConfig, globalOptions }) {
  const { procedure } = yield select((state) => state.form[formType]);
  const { translation } = formConfig;
  const { toast, response, returnResult, pagination, returnPath } = globalOptions;

  const paginationInfo = getPaginationInfo(headers);

  if (toast) {
    yield put(
      addSnack({
        type: StatusType.Success,
        message: i18n.t(`${translation}.${procedure.toLowerCase()}Successful`),
      }),
    );
  }
  yield put(
    submitFormSuccess({
      formType,
      endpoint,
      ...(response && { content: data }),
    }),
  );
  if (returnResult) {
    yield put(
      fetchFormListSuccess({
        formType,
        endpoint: returnPath ?? endpoint,
        lastUpdated: Math.floor(Date.now() / 1000),
        content: true,
        list: data,
        ...(pagination && { paginationInfo }),
      }),
    );
  }
}

function* handleFormSubmissionError({
  error,
  formType,
  endpoint,
  refetchAnywhere,
  callbackFunction,
  refetch_id,
  recaptchaRef,
}: {
  error: any;
  formType: FormType;
  endpoint: EndpointType;
  refetchAnywhere?: boolean;
  callbackFunction?: CallbackOptionPayload;
  refetch_id?: string | number;
  recaptchaRef?: RecaptchaRef;
}) {
  const axiosError = error as AxiosError<{ data: any }>;
  if (recaptchaRef) {
    yield put(resetAuthState());
    recaptchaRef?.current?.reset();
  }

  if (axiosError.response) {
    const { data } = axiosError.response.data;
    yield put(submitFormError({ formType, endpoint, responseResult: data }));
  }

  if (refetchAnywhere && callbackFunction?.refetch) {
    yield* handleCallbackFunction(callbackFunction.refetch);

    if (refetch_id) {
      yield put(resetSubmitFormState({ formType }));
      yield put(
        fetchFormList({
          formType,
          params: { application_id: refetch_id },
          globalOptions: { pagination: true },
        }),
      );
    }
  }
}
function getUpdatedPage(currentPage: number, deletedCount: number, total: number, pageSize: number) {
  const newTotal = total - deletedCount;
  const newTotalPages = Math.ceil(newTotal / pageSize);
  return Math.max(1, Math.min(currentPage, newTotalPages));
}

export function* formSagaWatcher() {
  yield takeEvery(fetchFormList.type, fetchFormListSaga);
  yield takeLatest(fetchDashboardGraph.type, fetchDashboardGraphSaga);
  yield takeLatest(submitFormRequest.type, submitFormRequestSaga);
  yield takeLatest(deleteFormContent.type, deleteFormContentSaga);
  yield takeLatest(updateFormPages.type, updateFormPagesSaga);
}
