import { PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import jwt_decode from 'jwt-decode';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { STORAGE_KEYS } from '@constants';
import { accessToken, getAllStorageData, refreshToken } from '@core/storage';
import { Headers, StatusType } from '@enums';
import {
  addSnack,
  logOut,
  setAppLoading,
  systemError,
  systemUpdateNetworkConnection,
  systemUpdatePageFocus,
  updateApiConnectionStatus,
  updateToken,
  updateTokenSuccess,
} from '@store/slices';
import { API, setHeaders } from '@utils';

const TOKEN_EXPIRATION_TIME = 300; // 5 minutes
const MIN_VALID_DURATION = 60; // 1 minute

export function* checkAndRefreshToken(tickCount: number) {
  const token = getAccessToken();
  if (!token) {
    yield put(logOut());
    return 'failed';
  }

  try {
    const decoded = jwt_decode<{ exp: number }>(token);
    const timeUntilExpiry = decoded.exp - Math.floor(Date.now() / 1000);

    if (timeUntilExpiry < TOKEN_EXPIRATION_TIME) {
      yield put(updateToken());

      const { success } = yield race({
        success: take(updateTokenSuccess.type),
      });

      return success ? 'refreshed' : 'failed';
    }
    return 'valid';
  } catch (error) {
    yield put(logOut());
    return 'failed';
  }
}

function isTokenExpired(token: string): boolean {
  try {
    const decoded: any = jwt_decode(token);
    return decoded.exp <= Math.floor(Date.now() / 1000);
  } catch (e) {
    return true;
  }
}

function getAccessToken() {
  return localStorage.getItem('access_token');
}

function* updateNetworkConnectionSaga() {
  const isBackOnline = yield select((state) => state.system.networkConnection.backOnline);
  try {
    yield put(
      addSnack({
        type: isBackOnline ? StatusType.Success : StatusType.Error,
        message: i18n.t(isBackOnline ? 'common:connection.restored' : 'common:connection.disconnected'),
        clear: isBackOnline,
      }),
    );
    if (isBackOnline) {
      yield put(systemUpdatePageFocus({ isActive: true }));
    }
  } catch (error) {
    yield put(systemError(error instanceof Error ? error : new Error('Unknown error')));
  }
}

function* checkTokenAfterInactivitySaga({ payload: { isActive } }: PayloadAction<{ isActive: boolean }>) {
  if (isActive) {
    yield put(setAppLoading(true));
    try {
      yield call(updateTokenIfNeeded);
    } catch (e) {
      yield put(logOut());
    } finally {
      yield put(setAppLoading(false));
    }
  }
}

function* updateTokenIfNeeded() {
  try {
    const { current_access_token: accessToken } = yield call(getAllStorageData);
    if (!accessToken) return 'failed';

    const decoded = jwt_decode<{ exp: number }>(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeRemaining = decoded.exp - currentTime;

    const bufferTime = 60;

    if (timeRemaining < TOKEN_EXPIRATION_TIME + bufferTime || timeRemaining < MIN_VALID_DURATION * 2) {
      yield put(updateToken());
      const { success } = yield race({
        success: take(updateTokenSuccess.type),
      });

      if (success) {
        const newToken = yield call(getAccessToken);
        const newDecoded = jwt_decode<{ exp: number }>(newToken);
        const newTimeRemaining = newDecoded.exp - Math.floor(Date.now() / 1000);

        return newTimeRemaining >= MIN_VALID_DURATION ? 'refreshed' : 'failed';
      } else {
        return 'failed';
      }
    } else if (timeRemaining <= MIN_VALID_DURATION) {
      return 'failed';
    } else {
      return 'valid';
    }
  } catch (error) {
    return 'failed';
  }
}

function* updateTokenSaga() {
  try {
    const { current_refresh_token } = yield call(getAllStorageData);
    if (!current_refresh_token) return yield put(logOut());

    if (isTokenExpired(current_refresh_token)) {
      yield put(logOut());
      return;
    }

    yield call(setHeaders, { [Headers.Authorization]: `Bearer ${current_refresh_token}` });
    const { data } = yield call(API.post, '/user/refresh');
    const { access_token, refresh_token } = data;
    if (!access_token || !refresh_token) return yield put(logOut());

    yield call(accessToken.setItem, access_token);
    yield call(refreshToken.setItem, refresh_token);
    yield call(setHeaders, { [Headers.Authorization]: `Bearer ${access_token}` });
    localStorage.setItem(STORAGE_KEYS.LAST_REFRESH, Date.now().toString());

    yield put(updateTokenSuccess());
  } catch (e) {
    yield put(logOut());
  }
}

function* updateTokenSuccessSaga() {
  const isAPIWorking = yield select((state) => state.system.apiConnection.isConnected);
  if (!isAPIWorking) {
    yield put(updateApiConnectionStatus({ status: true }));
  }
}

export function* systemSagaWatcher() {
  yield takeLatest(systemUpdatePageFocus.type, checkTokenAfterInactivitySaga);
  yield takeLatest(systemUpdateNetworkConnection.type, updateNetworkConnectionSaga);

  yield takeLatest(updateToken.type, updateTokenSaga);
  yield takeLatest(updateTokenSuccess.type, updateTokenSuccessSaga);
}
