import i18n from 'i18next';
import * as Yup from 'yup';

import { ipWithCidrValidationReg } from '@core/regexp';

export const securityFormSchema = () => {
  return Yup.object().shape({
    block_request_limit: Yup.number()
      .required(() => i18n.t('validation:security.blockRequestRequired'))
      .test(
        'block-greater-than-soft',
        () => i18n.t('validation:security.blockRequestMustMoreThanLimitRequest'),
        function (value) {
          const softValue = this.parent.soft_request_limit;
          return !softValue || value > softValue;
        },
      ),
    soft_request_limit: Yup.number()
      .required(() => i18n.t('validation:security.softRequestRequired'))
      .test(
        'soft-lesser-than-block',
        () => i18n.t('validation:security.limitRequestMustLessThanBlockRequest'),
        function (value) {
          const blockValue = this.parent.block_request_limit;
          return !blockValue || value < blockValue;
        },
      ),
    ip_black_list: Yup.lazy((value) => {
      return Yup.array()
        .transform((currentValue) => {
          if (Array.isArray(currentValue)) {
            return currentValue.map((item) => item.trim().replace(/^\n+/, ''));
          } else if (typeof currentValue === 'string') {
            return currentValue.split('\n').map((item) => item.trim());
          }
          return [];
        })
        .of(
          Yup.string().matches(
            ipWithCidrValidationReg,
            () => `${i18n.t('validation:security.checkIpFormat')} (${i18n.t('validation:format.ipWithCidr')})`,
          ),
        );
    }),
  });
};
