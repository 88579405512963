import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getCdnOriginListColumns } from '@constants';
import { FormListLayout } from '@containers';
import { FormActionType, FormType, ServiceModalName, StatusType, Translation } from '@enums';
import { useAppDispatch, useFormHandlers, useHealthCheckTableConfiguration, useToggleState } from '@hooks';
import { addServiceModal, deleteFormContent, fetchFormList, useFormList, useFormSubmissionStatus } from '@store';

import { AddOriginModal } from './addOriginModal';
import { HealthCheckConfigurationModal } from './healthCheckConfigurationModal';

export const OriginForm = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnOrigin;

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.origin' });
  const { t: tButtons } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.origin' });
  const { t: tOriginModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.origin' });
  const { t: tModals } = useTranslation(Translation.Configuration, { keyPrefix: 'modals' });
  const { t: tTables } = useTranslation(Translation.Description, { keyPrefix: 'configuration.tables' });
  const { t: tCommon } = useTranslation(Translation.Common);

  const [addOriginModal, setAddOriginModal] = useState<boolean>(false);
  const [healthCheckConfigurationModal, setHealthCheckConfigurationModal] = useState<boolean>(false);

  const isOriginSubmitting = useFormSubmissionStatus({ formType });
  const handleOpenOriginModal = useToggleState(setAddOriginModal);
  const handleOpenHealtchCheckConfigurationModal = useToggleState(setHealthCheckConfigurationModal);
  const formList = useFormList({ formType });

  const {
    function: { handleHealthStatusModal },
  } = useHealthCheckTableConfiguration();

  const cdnOriginTableColumns = useCallback(
    () => getCdnOriginListColumns({ handleAddModal: handleHealthStatusModal }),
    [tTables, formList],
  );

  const originFormHandlers = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Create,
        function: handleOpenOriginModal,
        label: tButtons('addNewOrigin'),
      },
      {
        type: FormActionType.Edit,
        function: handleOpenOriginModal,
      },
      {
        type: FormActionType.Delete,
        function: (rowData) => {
          dispatch(
            addServiceModal({
              name: ServiceModalName.ConfirmModal,
              payload: {
                label: {
                  title: tOriginModal('originDelete'),
                  text: tModals('areYouSureDelete', { domain: rowData?.name }),
                  confirmButton: tCommon('button.delete'),
                },
                option: {
                  modalType: StatusType.Delete,
                  button: {
                    loading: isOriginSubmitting,
                  },
                },
                callbackFunction: {
                  submit: () =>
                    dispatch(
                      deleteFormContent({
                        formType,
                        params: {
                          content_id: rowData?.id,
                          application_id: application_id,
                        },
                        callbackFunction: {
                          refetch: fetchFormList({
                            formType,
                            params: { application_id },
                            globalOptions: {
                              pagination: true,
                            },
                          }),
                        },
                      }),
                    ),
                },
              },
            }),
          );
        },
      },
      {
        type: FormActionType.HealthCheck,
        function: handleOpenHealtchCheckConfigurationModal,
      },
    ],
  });

  return (
    <FormListLayout
      label={{
        title: tTabs('originList'),
      }}
      tableData={{
        columns: cdnOriginTableColumns(),
        actionHandlers: originFormHandlers,
        hasChildren: true,
        formType,
      }}
    >
      {addOriginModal && <AddOriginModal open={addOriginModal} onClose={handleOpenOriginModal} />}
      {healthCheckConfigurationModal && (
        <HealthCheckConfigurationModal
          open={healthCheckConfigurationModal}
          onClose={handleOpenHealtchCheckConfigurationModal}
        />
      )}
    </FormListLayout>
  );
};
