import { motion } from 'framer-motion';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Translation } from '@enums';

import type { RecaptchaRef } from '@types';
import { SignInForm } from './subPages/signIn/signInForm';

interface LoginProps {
  recaptchaRef?: RecaptchaRef;
}

export const Login: FC<LoginProps> = ({ recaptchaRef }) => {
  const { t: tTab } = useTranslation(Translation.SignIn, { keyPrefix: 'tab' });
  return (
    <div className="flex flex-col gap-6">
      <motion.div
        className="text-center"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.3 }}
      >
        <h2 className="text-lg font-semibold leading-9 tracking-tight text-theme-text-main">
          {tTab('signInWithYourAccountDetails')}
        </h2>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.3 }}
      >
        <SignInForm recaptchaRef={recaptchaRef} />
      </motion.div>
    </div>
  );
};
