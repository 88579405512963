import i18n from 'i18next';
import { UsersIcon } from 'lucide-react';

import { Avatar, Column, Text } from '@components';
import { CellType } from '@enums';
import { getColumnSizes } from '@utils';
import { getInitials, getOwnerDetails, getRandomTextAvatarColor } from '@utils/pages';

export const getWorkspaceListTableColumn = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', ...getColumnSizes(50) },
  {
    label: i18n.t('form:tables.workspace'),
    value: 'name',
    ...getColumnSizes(200),
    cellType: CellType.Custom,
    format: (value, fullData) => (
      <div className="flex space-y-1 flex-col ">
        <Text $level={6} className="truncate">
          {getOwnerDetails(fullData.permissions)?.name}
        </Text>
        <Text $level={4} color="text-black" className="truncate">
          {value}
        </Text>
      </div>
    ),
  },
  {
    label: i18n.t('form:tables.users'),
    value: 'permissions',
    ...getColumnSizes(110),
    cellType: CellType.Custom,
    format: (value) => (
      <div className="flex -space-x-2">
        {value.map((user, index) => {
          if (user?.name)
            return (
              <div key={index} className="hover:!z-30 relative" style={{ zIndex: value.length - index }}>
                <Avatar
                  textAvatar={!user?.userImage}
                  className="size-5"
                  src={user.userImage ?? getInitials(user?.name)}
                  alt={user?.name ?? 'User Image'}
                  bgColor={getRandomTextAvatarColor()}
                />
              </div>
            );
        })}
      </div>
    ),
  },
  {
    label: i18n.t('form:tables.userCount'),
    value: 'permissions',
    ...getColumnSizes(100),
    cellType: CellType.Custom,
    format: (value) => {
      const userCount = value.length;
      const formattedValue = userCount < 10 ? `0${userCount}` : userCount;
      return (
        <div className="flex space-x-2 items-center">
          <UsersIcon className="w-4 h-4 text-zinc-500" />
          <Text $level={5}>{formattedValue}</Text>
        </div>
      );
    },
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    ...getColumnSizes(90),
  },
];
