import { Button } from '@components/Buttons';
import type { DualListTransferableRowProps } from '@types';
import { validateValueEqual } from '@utils';

export const DualListTransferableRow = ({
  handleClick,
  handleMouseEvent,
  hoveredContent,
  zone,
  showShortForm,
  value,
  label,
}: DualListTransferableRowProps) => {
  if (!value && !label) return;
  const isContentHovered = validateValueEqual(hoveredContent, value);
  const isLeftZone = validateValueEqual(zone, 0);
  return (
    <Button
      customizeColor
      className="flex items-center justify-between hover:bg-gray-100"
      onClick={() => handleClick(value)}
      disabled={!value}
      onMouseEnter={() => handleMouseEvent(value)}
      onMouseLeave={() => handleMouseEvent(null)}
      fullWidth
    >
      {Boolean(label) && <span className="truncate">{label}</span>}
      {isContentHovered && <span className="text-gray-400">{isLeftZone ? '→' : '←'}</span>}
      {Boolean(value) && !isContentHovered && showShortForm && <span className="text-gray-400">{value}</span>}
    </Button>
  );
};
