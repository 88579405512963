import { Formik } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SearchInput, Text } from '@components';
import { Translation, VariantType } from '@enums';
import type { SelectableItemListProps } from '@types';
import { DualListTransferableRow } from './DualListTransferableRow';

export const SelectableItemList = ({
  items,
  onToggleItem,
  placeholder = '',
  title = '',
  name,
  onMoveAll,
  showValue = true,
  zone,
}: SelectableItemListProps) => {
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.security' });

  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  if (!name) {
    return;
  }

  return (
    <div className="w-full flex flex-col gap-3">
      <div className="flex justify-between items-center rounded-t-lg">
        <Text $level={4} $customizeColor className="font-medium">
          {title}
        </Text>
        <Button
          variant={VariantType.Clean}
          customizeColor
          withoutPadding
          rounded="rounded-none"
          className="text-blue-500 hover:underline"
          onClick={onMoveAll}
        >
          {tButton('moveAll')}
        </Button>
      </div>
      <Formik initialValues={{ [name]: '' }} onSubmit={() => {}}>
        {({ values }) => {
          const filteredItems =
            items?.filter((item) => item?.label?.toLowerCase().includes(values[name]?.toLowerCase())) ?? [];

          return (
            <Fragment>
              <SearchInput name={name} placeholder={placeholder} />
              <div className="h-64 rounded-lg border overflow-y-auto py-2 padded-scrollbar-track">
                {filteredItems.map((item, index) => (
                  <div key={index}>
                    <DualListTransferableRow
                      handleClick={onToggleItem}
                      handleMouseEvent={setHoveredItem}
                      hoveredContent={hoveredItem}
                      zone={zone}
                      showShortForm={showValue}
                      value={item?.value}
                      label={item?.label}
                    />
                  </div>
                ))}
              </div>
            </Fragment>
          );
        }}
      </Formik>
    </div>
  );
};
