import { CHART_DEFAULT_HEIGHT, CHART_HEIGHT_SCALE_CONFIGS, CHART_RESPONSIVE_CONFIG } from '@constants';

import type { ComputeChartHeightParams } from '@types';

const computeChartHeight = ({ itemCount, dimensions, deviceType = 'large' }: ComputeChartHeightParams): number => {
  if (!itemCount) {
    return CHART_DEFAULT_HEIGHT;
  }
  const heightScaleMap = new Map(CHART_HEIGHT_SCALE_CONFIGS.map((config) => [config.itemCount, config.scaleFactor]));

  const defaultConfig = CHART_RESPONSIVE_CONFIG[deviceType];
  const {
    baseHeightPerItem = defaultConfig.baseHeightPerItem,
    minHeight = defaultConfig.minHeight,
    maxHeight = defaultConfig.maxHeight,
  } = dimensions;

  const heightPerItem = heightScaleMap.get(itemCount) ?? baseHeightPerItem;
  const computedHeight = itemCount * heightPerItem;

  return Math.min(Math.max(computedHeight, minHeight), maxHeight);
};

export const memoizedComputeChartHeight = (() => {
  const cache = new Map<string, number>();

  return (params: ComputeChartHeightParams): number => {
    const cacheKey = `${params.itemCount}-${JSON.stringify(params.dimensions)}-${params.deviceType}`;

    if (cache.has(cacheKey)) {
      return cache.get(cacheKey)!;
    }

    const result = computeChartHeight(params);
    cache.set(cacheKey, result);
    return result;
  };
})();
