import { Mail } from 'lucide-react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Avatar, Breadcrumbs, Button, IconLabelAnimation, Text } from '@components';
import { ROUTES } from '@constants';
import { MainContentContainer, MainLayout } from '@containers';
import { FormActionType, FormType, StatusType, Translation, WorkspaceMemberEndpoint } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  addSnack,
  fetchFormList,
  getWorkspaceOptions,
  submitFormRequest,
  updateFormProcedure,
  useFormList,
  useUserAuthenticated,
} from '@store';
import { getInitials, invitationStorage } from '@utils';

export const WorkspaceInvitation: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formType = FormType.Workspace;

  const { t: tToasts } = useTranslation(Translation.Workspace, { keyPrefix: 'toasts' });
  const { t: tButton } = useTranslation(Translation.Workspace, { keyPrefix: 'buttons' });
  const { t: tDescription } = useTranslation(Translation.Workspace, { keyPrefix: 'descriptions' });

  const { workspaceId: workspace_id } = useParams<{ workspaceId: string }>();
  const isUserAuthenticated = useUserAuthenticated();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const formData = useFormList({ formType, endpoint: WorkspaceMemberEndpoint.JOIN });
  useEffect(() => {
    if (token && workspace_id) {
      if (isUserAuthenticated) {
        invitationStorage.store({ token, workspaceId: workspace_id });
        dispatch(
          fetchFormList({
            formType,
            params: { content_id: workspace_id, token: token },
            globalOptions: {
              endPath: true,
              endpoint: WorkspaceMemberEndpoint.JOIN,
              toast: true,
              toastOption: {
                type: StatusType.Error,
                message: tToasts('thisWorkspaceInvitationLinkHasExpiredOrIsInvalid'),
                rules: [{ key: 'status', expectedValue: 0 }],
              },
            },
          }),
        );
      } else {
        if (token && workspace_id) {
          invitationStorage.store({ token, workspaceId: workspace_id });
          dispatch(
            addSnack({ type: StatusType.Error, message: tToasts('youMustLogInToAcceptTheWorkspaceInvitation') }),
          );
        }

        navigate(ROUTES.login);
      }
    }
    if (!token || !workspace_id) {
      navigate(ROUTES.workspace.main);
    }
    return () => {
      if (isUserAuthenticated) {
        invitationStorage.clear();
      }
    };
  }, [token, workspace_id]);

  if (!isUserAuthenticated) return null;

  const handleAcceptInvitation = () => {
    const workspace = parseInt(workspace_id ?? '');
    if (token && workspace) {
      dispatch(
        updateFormProcedure({
          formType,
          procedure: FormActionType.Accept,
        }),
      );
      dispatch(
        submitFormRequest({
          formType,
          params: { content_id: workspace, token: token },
          globalOptions: {
            endPath: true,
            endpoint: WorkspaceMemberEndpoint.JOIN,
            toast: true,
          },
          callbackFunction: {
            refetch: () => {
              dispatch(getWorkspaceOptions());
              navigate(ROUTES.workspace.main);
            },
          },
        }),
      );
    }
  };

  const handleNavigateBackToHomePage = () => {
    invitationStorage.clear();
    navigate(ROUTES.homepage);
  };
  return (
    <MainContentContainer>
      <MainLayout>
        <Breadcrumbs />
        <div className="flex flex-1 items-center justify-center bg-white rounded-2xl shadow-card mt-8">
          {formData?.name ? (
            <div className="px-6 py-7 space-y-8 place-items-center">
              <Avatar
                textAvatar
                className="size-14 text-xl"
                src={getInitials(formData?.name)}
                alt={formData?.name}
                bgColor="blue"
              />
              <div className="space-y-8 place-items-center">
                <Text color="black" $level={2} className="line-clamp-1">
                  {`${tDescription('youHaveBeenInvitedToAccess.before')} `}
                  <span className="font-medium text-theme-primary-main">{formData?.name}</span>
                  {tDescription('youHaveBeenInvitedToAccess.after')}
                </Text>
                <Button
                  customizeColor
                  disabled={!token}
                  onClick={handleAcceptInvitation}
                  withoutPadding
                  rounded="rounded-lg"
                  className="shadow-card bg-green-500 text-white font-medium py-3 px-4"
                >
                  <Text $level={4} $customizeColor>
                    {tButton('acceptInvitation')}
                  </Text>
                </Button>
              </div>
            </div>
          ) : (
            <IconLabelAnimation label={tDescription('noInvitationFound')} icon={<Mail className="size-16" />}>
              <Button
                disabled={!token}
                onClick={handleNavigateBackToHomePage}
                withoutPadding
                rounded="rounded-lg"
                className="shadow-card py-3 px-4 mt-7"
              >
                <Text $level={4} $customizeColor>
                  {tButton('navigateBackToHomePage')}
                </Text>
              </Button>
            </IconLabelAnimation>
          )}
        </div>
      </MainLayout>
    </MainContentContainer>
  );
};
