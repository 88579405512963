import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Breadcrumbs, GlobalLoader, Responsive, Skeleton, Text } from '@components';
import { ROUTES } from '@constants';
import { Translation } from '@enums';
import { useAppDispatch } from '@hooks';
import { SideNavigation } from '@pages/Cdn';
import {
  getCdnSummary,
  resetCdnSummary,
  useCdnSummarInit,
  useCdnSummary,
  useCdnSummaryError,
  useCdnSummaryLoading,
  useCurrentWorkspaceId,
  useWorkspaceOptions,
  useWorkspaceOptionsLoading,
} from '@store';

export const ConfigurationLayout = ({ children }: PropsWithChildren<object>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { id: application_id } = useParams();

  const { t: tConfiguration } = useTranslation(Translation.Configuration);

  const cdnSummary = useCdnSummary();
  const cdnError = useCdnSummaryError();
  const cdnLoading = useCdnSummaryLoading();
  const cdnInit = useCdnSummarInit();

  const currentWorkspaceId = useCurrentWorkspaceId();
  const workspaceList = useWorkspaceOptions();
  const workspaceLoading = useWorkspaceOptionsLoading();

  const getLastPathnameSegment = () => {
    const pathSegments = pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  };

  useEffect(() => {
    if (pathname.includes('cdn') && application_id) {
      dispatch(getCdnSummary(application_id));
    }
  }, [application_id]);

  useEffect(() => {
    if (cdnError) {
      if (currentWorkspaceId && workspaceList.length > 0) {
        navigate(ROUTES.cdn);
      } else navigate(ROUTES.homepage);
    }

    return () => {
      dispatch(resetCdnSummary());
    };
  }, [cdnError]);

  if (workspaceLoading || cdnLoading) return <GlobalLoader overlay />;

  return (
    <div className="max-w-full w-full space-y-8">
      <Breadcrumbs />
      <div className="grid grid-cols-1 lg:grid-cols-9 lg:flex-row gap-5 sm:gap-8 lg:gap-9">
        <div className="col-span-1 lg:col-span-2">
          <SideNavigation />
        </div>
        <div className="col-span-1 lg:col-span-7 space-y-7 flex flex-col">
          <div className="flex justify-between items-center">
            <div className="flex flex-1 flex-col gap-3">
              <Responsive showAbove="lg">
                <Text $level={3} color="text-black" className="font-medium">
                  {tConfiguration(`${getLastPathnameSegment()}.pageTitle`)}
                </Text>
              </Responsive>
              <Text $level={5} color="text-gray-400" className="font-light leading-4">
                {tConfiguration(`${getLastPathnameSegment()}.pageSummary`)}
              </Text>
            </div>
          </div>
          {(cdnInit && cdnLoading) || cdnError || (cdnSummary && !('id' in cdnSummary)) ? (
            <Skeleton className="w-full h-full rounded-2xl" />
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};
