import type { DependencyRule } from '@types';

export const validateDependency = (formData: Record<string, any>, dependsOn: DependencyRule): boolean => {
  const { field, value, shouldValidate = true, operator = 'AND', conditions = [] } = dependsOn;

  const mainCondition = validateValue(formData[field], value);
  const isMainValid = shouldValidate ? mainCondition : !mainCondition;

  if (!conditions.length) {
    return isMainValid;
  }

  const additionalConditions = conditions.map((condition) => {
    const conditionResult = validateValue(formData[condition.field], condition.value);
    return condition.shouldValidate ? conditionResult : !conditionResult;
  });

  return operator === 'AND'
    ? isMainValid && additionalConditions.every(Boolean)
    : isMainValid || additionalConditions.some(Boolean);
};

const validateValue = (value1: any, value2: any): boolean => {
  if (value1 === value2) return true;
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    return value1.toLowerCase() === value2.toLowerCase();
  }
  return false;
};
