import { FormType } from '@enums';
import { useFormError, useFormSubmissionStatus } from '@store';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

type ErrorValue = string | string[] | Record<string, unknown>;
type ProcessedErrorValue = string | object | string[] | ProcessedErrorRecord;
type ProcessedErrorRecord = Record<string, string | string[]>;

interface UseErrorFormInnerProps {
  formType?: FormType;
  submitError?: ErrorValue;
  isSubmitting?: boolean;
}

const processError = (error: unknown): ProcessedErrorValue => {
  if (!error || typeof error === 'string') {
    return error || '';
  }

  if (Array.isArray(error)) {
    return error.map((item) => processError(item) as string);
  }

  if (typeof error === 'object') {
    const result: ProcessedErrorRecord = {};

    Object.entries(error as Record<string, unknown>).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        result[key] = value.every((item) => typeof item === 'string')
          ? value
          : value.map((item) => processError(item) as string);
      } else if (typeof value === 'object' && value) {
        const processed = processError(value);
        if (processed !== '') result[key] = processed as string;
      } else {
        result[key] = String(value);
      }
    });

    return result;
  }

  return String(error);
};

const getFullFieldPath = (parentField: string, field: string): string => {
  if (!parentField) {
    return field;
  }

  const isNumericField = !Number.isNaN(Number(field));
  if (isNumericField) {
    return parentField;
  }

  return `${parentField}.${field}`;
};

export const useErrorFormInner = ({
  formType = FormType.Empty,
  submitError,
  isSubmitting = false,
}: UseErrorFormInnerProps) => {
  const { setFieldError, setFieldTouched } = useFormikContext();
  const formError = useFormError({ formType });
  const formSubmitting = useFormSubmissionStatus({ formType });
  const isFormError = formError ?? submitError;
  const isFormSubmitting = formSubmitting ?? isSubmitting;

  useEffect(() => {
    if (isFormSubmitting || !isFormError) return;

    const handleError = (field: string, error: unknown) => {
      const errorMessage = processError(error);
      setFieldError(field, errorMessage as string);
      setFieldTouched(field, true, false);
    };

    if (typeof isFormError === 'string') {
      handleError('submitError', isFormError);
      return;
    }

    const traverseErrors = (errors: Record<string, unknown>, parentField = '') => {
      Object.entries(errors).forEach(([field, error]) => {
        handleError(getFullFieldPath(parentField, field), error);
      });
    };

    traverseErrors(isFormError as Record<string, unknown>);
  }, [isFormSubmitting, isFormError]);
};
