import { memo, PropsWithChildren, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from '@constants';

type ProtectedRouteProps = PropsWithChildren<{
  isAllowed: boolean;
  redirectPath?: string;
}>;

export const ProtectedRoute = memo(
  ({ isAllowed = false, redirectPath = ROUTES.login, children }: ProtectedRouteProps): ReactElement => {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }

    return (children as ReactElement) ?? <Outlet />;
  },
);
