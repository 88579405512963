import type { ChartDimensionConfig, ChartHeightScaleConfig, DeviceType, ResponsiveConfig } from '@types';

export const CHART_DEFAULT_HEIGHT = 400;

export const CHART_RESPONSIVE_CONFIG: Record<DeviceType, ChartDimensionConfig> = {
  mobile: {
    yAxisWidth: 80,
    baseHeightPerItem: 45,
    minHeight: 105,
    maxHeight: 432.5,
  },
  tablet: {
    yAxisWidth: 100,
    baseHeightPerItem: 55,
    minHeight: 105,
    maxHeight: 450,
  },
  desktop: {
    yAxisWidth: 130,
    baseHeightPerItem: 55,
    minHeight: 110,
    maxHeight: 480,
  },
  large: {
    yAxisWidth: 150,
    baseHeightPerItem: 55,
    minHeight: 110,
    maxHeight: 500,
  },
};

export const LINE_GRAPH_RESPONSIVE_CONFIG: Record<DeviceType, ResponsiveConfig> = {
  mobile: { minHeight: 250, maxHeight: 350 },
  tablet: { minHeight: 300, maxHeight: 400 },
  desktop: { minHeight: 420, maxHeight: 550 },
  large: { minHeight: 520, maxHeight: 650 },
};

export const CHART_HEIGHT_SCALE_CONFIGS: ChartHeightScaleConfig[] = [
  { itemCount: 1, scaleFactor: 40 },
  { itemCount: 2, scaleFactor: 67.5 },
  { itemCount: 3, scaleFactor: 56.5 },
];
