import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { Button, colors, Text } from '@components';
import { VariantType } from '@enums';
import { validateValueEqual } from '@utils';

import { Option, useSelectBox } from './useSelectBox';

type Align = 'start' | 'end';
type Placement = 'top' | 'right' | 'bottom' | 'left';
type AnchorProps = `${Placement}` | `${Placement} ${Align}`;

interface SelectBoxProps {
  options: Option[];
  fullWidth?: boolean;
  smallFont?: boolean;
  name: string;
  onOptionSelect?: (option: Option) => void;
  color?: any;
  customMenu?: ReactNode;
  menuItemClassName?: string;
  withoutPadding?: boolean;
  anchorDirection?: AnchorProps;
}

export const SelectBox: FC<SelectBoxProps> = ({
  options,
  smallFont = false,
  fullWidth = false,
  name,
  onOptionSelect,
  withoutPadding,
  color = 'bg-slate-700 bg-opacity-5',
  customMenu,
  menuItemClassName = 'px-3 py-3.5',
}) => {
  const { field, meta, selected, setValue } = useSelectBox({ name, options });
  const [menuWidth, setMenuWidth] = useState<number>(0);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      setMenuWidth(buttonRef.current.offsetWidth * 3);
    }
  }, [buttonRef]);

  const handleOptionClick = (option: Option) => {
    setValue(option.value);
    if (onOptionSelect) {
      onOptionSelect(option);
    }
  };

  const dynamicContainerClasses = classNames(
    'inline-flex items-center justify-between gap-2 rounded-md text-sm/6 shadow-inner shadow-white/10 focus:outline-none',
    colors[color] ?? color,
    {
      'px-2.5 py-2 sm:py-2.5 sm:px-3.5': customMenu,
      'sm:py-2.5 sm:px-3.5 p-2.5': !customMenu,
      'w-full': fullWidth,
      'min-w-28 sm:min-w-40 max-w-28 sm:max-w-64': !fullWidth,
      'text-xs': smallFont,
      'text-sm': !smallFont,
    },
  );

  if (!name || !selected) return null;

  return (
    <div className="relative">
      <Menu>
        <div ref={buttonRef}>
          <MenuButton as={'div'}>
            <Button
              withoutScale
              withoutEllipsis
              customizeColor
              withoutPadding={withoutPadding}
              className={dynamicContainerClasses}
            >
              {customMenu ?? (
                <>
                  <Text $level={6} color="text-gray-600" className="truncate">
                    {selected?.label}
                  </Text>
                  <ChevronUpDownIcon className="size-4 sm:size-5 text-gray-400 flex-shrink-0" aria-hidden="true" />
                </>
              )}
            </Button>
          </MenuButton>
        </div>

        <div className="relative">
          <MenuItems
            className="absolute right-0 z-50 origin-top-right my-1.5 bg-white shadow-card rounded-xl max-w-64"
            style={{ width: `${menuWidth}px` }}
          >
            <div className="max-h-40 flex flex-col p-2 gap-1.5 overflow-y-auto padded-scrollbar-track">
              {options.map((option, index) => {
                const isCurrent = validateValueEqual(option.value.toString(), field.value.toString());

                const dynamicRowClasses = classNames(
                  'group flex w-full min-w-40 max-w-full items-center rounded-lg text-opacity-100 justify-between relative',
                  {
                    'bg-slate-100 text-slate-900 hover:cursor-not-allowed pr-10': isCurrent,
                    'text-gray-900 hover:text-green-900 hover:bg-gray-100': !isCurrent,
                  },
                  menuItemClassName,
                );

                return (
                  <MenuItem key={index}>
                    <Button
                      variant={VariantType.Clean}
                      rounded="rounded-2xl"
                      withoutPadding
                      withoutCenter
                      withoutEllipsis
                      disabled={isCurrent}
                      className={dynamicRowClasses}
                      onClick={() => handleOptionClick(option)}
                    >
                      <Text $level={5} className="truncate">
                        {option.label}
                      </Text>
                      {isCurrent && <CheckIcon className="h-4 w-4 absolute right-3 flex-shrink-0" aria-hidden="true" />}
                    </Button>
                  </MenuItem>
                );
              })}
            </div>
          </MenuItems>
        </div>
      </Menu>
      {meta.touched && meta.error && <div className="text-red-500 text-xs mt-1">{meta.error}</div>}
    </div>
  );
};
