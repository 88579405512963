import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { Column, Text } from '@components';
import type { PaginationProps } from '@types';

type TableCellProps = {
  data?: ReactNode;
  column: Column;
  rowId: number;
  pagination?: PaginationProps;
  multiLines?: boolean;
};

type FormatCellOptions = {
  isIdColumn: boolean;
  rowId: number;
  startIndex: number;
};

const calculateStartIndex = (pagination?: PaginationProps): number => {
  if (!pagination) return 1;

  const calculated = (pagination.page - 1) * pagination.page_size + 1;
  return Number.isNaN(calculated) ? 1 : calculated;
};

const formatCellData = (data: ReactNode, { isIdColumn, rowId, startIndex }: FormatCellOptions): ReactNode => {
  if (isIdColumn) {
    const index = rowId + startIndex;
    return Number.isNaN(index) ? 1 : index;
  }
  if (!data) return '—';
  return data;
};

export const DefaultTableCell: FC<TableCellProps> = ({ data, rowId, pagination, column, multiLines = false }) => {
  const { value, label, fontSize = 4, labelStyle = 'font-normal' } = column;
  const isSortedId = value === 'original_id' && label === '#';
  const isIdColumn = value === 'id' && label === '#';
  const startIndex = calculateStartIndex(pagination);

  const cellClasses = classNames('leading-6', {
    'pl-3': isIdColumn || isSortedId,
    'h-full flex flex-col whitespace-break-spaces break-words': multiLines,
    'truncate ellipsis-text': !multiLines,
    [labelStyle]: labelStyle,
  });

  const content = formatCellData(data, {
    isIdColumn,
    rowId,
    startIndex,
  });

  if (typeof content === 'string' || typeof content === 'number') {
    return (
      <Text $level={fontSize} color="text-black" className={cellClasses}>
        {content}
      </Text>
    );
  }

  return content;
};
