import i18n from 'i18next';

import { Badge, Column, Text } from '@components';
import HealthCheckTableCell from '@components/DraggableTable/DraggableTableCell/HealthCheckTableCell';
import { CellType } from '@enums';
import { getColumnSizes } from '@utils';

export const getCdnOriginHealthCheckListColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', ...getColumnSizes(50), multiLines: true },
  { label: i18n.t('configuration:tables.originEndpoint'), value: 'ip', ...getColumnSizes(130), multiLines: true },
  {
    label: i18n.t('configuration:tables.connectionStatus'),
    value: 'status',
    format: (value) => {
      const hasConnection = value ?? true;
      return (
        <Badge color={hasConnection ? 'green' : 'red'} className="self-start px-2.5 py-1">
          {i18n.t(`configuration:forms.origin.${hasConnection ? 'healthy' : 'unhealthy'}`)}
        </Badge>
      );
    },
    ...getColumnSizes(110),
    multiLines: true,
  },
];

export const getCdnOriginListColumns = ({ handleAddModal }): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', ...getColumnSizes(50), multiLines: true },
  { label: i18n.t('configuration:tables.name'), value: 'name', ...getColumnSizes(120), multiLines: true },
  {
    label: i18n.t('configuration:tables.host'),
    value: 'origins',
    format: (value) => {
      return (
        <div className="flex flex-col">
          {value?.length > 0 ? (
            value?.map((data, index) => (
              <Text
                $level={4}
                color="text-black"
                className="text-sm font-normal select-text leading-6 ellipsis-text"
                key={index}
              >
                {data.origin ?? '—'}
              </Text>
            ))
          ) : (
            <Text $level={4} color="text-black" className="text-sm font-normal select-text leading-6 ellipsis-text">
              -
            </Text>
          )}
        </div>
      );
    },
    ...getColumnSizes(120),
    multiLines: true,
  },
  {
    label: i18n.t('configuration:tables.healthCheck'),
    value: 'health_check',
    ...getColumnSizes(130),
    multiLines: true,
    format: (value, fullData) => {
      return <HealthCheckTableCell status={value} rowId={fullData?.id} onDetailClick={handleAddModal} />;
    },
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    ...getColumnSizes(90),
    multiLines: true,
  },
];
