import { Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormField, FormModal } from '@components';
import { routeFormSchema } from '@constants';
import {
  ApiEndpoint,
  ConditionOperatorType,
  ConditionVariableType,
  FormActionType,
  FormType,
  InputType,
  Translation,
  columnDirection,
  columnSpace,
} from '@enums';
import { useAppDispatch } from '@hooks';
import { submitFormRequest, useCdnSummary, useFormContent, useFormList, useFormProcedure } from '@store';
import type { RouteConfigurationForm } from '@types';
import { convertOptionList, validateValueEqual } from '@utils';

interface RouteFormProps {
  open: boolean;
  onClose: () => void;
}

export const RouteModal = ({ open, onClose }: RouteFormProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnRoute;

  const currentForm = useFormContent({ formType });
  const originList = useFormList({ formType: FormType.CdnOrigin, endpoint: ApiEndpoint.CUSTOM });
  const currentProcedure = useFormProcedure(formType);

  const { t: tForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.route' });
  const { t: tModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.route' });
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.route' });
  const { t: tTables } = useTranslation(Translation.Common, { keyPrefix: 'tables' });

  const CdnConfigurationSummary = useCdnSummary();

  const isEdit = validateValueEqual(currentProcedure, FormActionType.Edit);

  const { options: domainOptions } = useMemo(
    () =>
      convertOptionList({
        originalOptions: CdnConfigurationSummary?.domain,
        defaultOptions: 'all',
        output: { value: 'domain', label: 'domain' },
      }),
    [CdnConfigurationSummary?.domain],
  );

  const { options: originOptionList } = convertOptionList({
    originalOptions: originList,
    defaultOptions: '',
    output: { value: 'id', label: 'name' },
  });

  const handleSubmit = (values: RouteConfigurationForm) => {
    const updatedValues = {
      ...values,
      conditions: values.conditions.map((condition) => ({
        ...condition,
        argument: condition.argument ?? '',
      })),
    };
    dispatch(
      submitFormRequest({
        formType,
        formData: isEdit ? updatedValues : { application_id, ...updatedValues },
        ...(isEdit && {
          params: {
            content_id: currentForm?.id,
          },
        }),
        globalOptions: {
          refetch_id: application_id,
        },
      }),
    );
  };

  const initialValues: RouteConfigurationForm = {
    name: currentForm?.name ?? '',
    origin: currentForm?.origin_id ?? '',
    conditions: currentForm?.conditions ?? [],
    connect_timeout: currentForm?.connect_timeout ?? '5',
    send_timeout: currentForm?.send_timeout ?? '5',
    read_timeout: currentForm?.read_timeout ?? '5',
  };

  if (!open) return;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={routeFormSchema({ optionList: domainOptions })}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <FormModal
            formType={formType}
            title={tModal(isEdit ? 'editApplicationRoute' : 'addApplicationRoute')}
            description={tModal(isEdit ? 'editRouteDescription' : 'addNewRouteDescription')}
            size="lg"
            open={open}
            confirmButton={{ children: tButton(isEdit ? 'editRoute' : 'addRoute') }}
            onClose={onClose}
          >
            <FormField
              label={tForm('rulesName')}
              name="name"
              direction={columnDirection.Row}
              smallFont
              required
              placeholder={tForm('enterYourRulesName')}
            />
            <FormField
              inputType={InputType.Table}
              label={tForm('conditions')}
              columnPercentage={columnSpace.TwoOverFive}
              format={tForm}
              withoutLabel
              direction={columnDirection.Column}
              name="conditions"
              fullWidth
              smallFont
              placeholder={''}
              emptyData={{
                main: {
                  field: ConditionVariableType.URI,
                  argument: '',
                  operator: ConditionOperatorType.stringEqual,
                  values: [{ value: '' }],
                },
              }}
            />
            <FormField
              label={tForm('originServer')}
              name="origin"
              inputType={InputType.Select}
              placeholder={tForm('selectYourOriginServer')}
              direction={columnDirection.Row}
              smallFont
              className="text-sm"
              fullWidth
              required
              options={originOptionList}
            />
            <FormField
              label={tForm('connectionTimeOut')}
              name="connect_timeout"
              direction={columnDirection.Row}
              smallFont
              min={1}
              max={60}
              fullWidth
              placeholder={'0'}
              adornment={
                <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                  {tTables('seconds')}
                </article>
              }
              type="number"
            />
            <FormField
              label={tForm('sendTimeOut')}
              name="send_timeout"
              smallFont
              fullWidth
              placeholder={'0'}
              min={1}
              max={60}
              direction={columnDirection.Row}
              adornment={
                <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                  {tTables('seconds')}
                </article>
              }
              type="number"
            />
            <FormField
              label={tForm('readTimeOut')}
              direction={columnDirection.Row}
              smallFont
              fullWidth
              min={1}
              max={1800}
              name="read_timeout"
              placeholder={'0'}
              adornment={
                <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                  {tTables('seconds')}
                </article>
              }
              type="number"
            />
          </FormModal>
        );
      }}
    </Formik>
  );
};
