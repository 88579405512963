import { ApexOptions } from 'apexcharts';
import React, { memo, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { NoRecordFound } from '@components';
import { SCREEN_BREAKPOINTS } from '@constants';
import { ApiEndpoint, DashboardEndpoint, FormType } from '@enums';
import { useChartDimensions, useMediaQuery } from '@hooks';
import { useFormList } from '@store';
import { formatBarChartYAxisUnit, formatNumberWithSeparator, validateValueEqual } from '@utils';

const PADDING_FACTOR = 1.15;

interface HorizontalBarChartProps {
  payload: {
    endpoint: ApiEndpoint;
    formType: FormType;
  };
}

export const HorizontalBarChart: React.FC<HorizontalBarChartProps> = memo(({ payload }) => {
  const isBelowSm = useMediaQuery(`(max-width: ${SCREEN_BREAKPOINTS.sm}px)`);
  const graphData = useFormList(payload);

  const { seriesData, maxValue } = useMemo(() => {
    if (!graphData.length) {
      return { seriesData: { value: [], key: [] }, maxValue: 0 };
    }

    const xKey = validateValueEqual(payload.endpoint, DashboardEndpoint.TOP_TEN_URL) ? 'url' : 'ip_address';
    const value: number[] = [];
    const key: string[] = [];
    let max = 0;

    graphData.forEach((entry) => {
      const y = typeof entry.count === 'number' && !isNaN(entry.count) ? entry.count : 0;
      const x = String(entry[xKey] ?? '');

      value.push(y);
      key.push(x);
      if (y > max) max = y;
    });

    return { seriesData: { value, key }, maxValue: max };
  }, [graphData, payload.endpoint]);

  const { chartHeight, yAxisWidth } = useChartDimensions({
    itemCount: seriesData.value.length,
  });
  const paddedMaxValue = maxValue * PADDING_FACTOR;

  const formatters = useMemo(
    () => ({
      yAxis: (value: string | number) => {
        const numericValue = typeof value === 'string' ? parseFloat(value) : value;
        return formatBarChartYAxisUnit(numericValue);
      },
      tooltip: (value: number) => formatNumberWithSeparator(value),
    }),
    [],
  );

  const getOptimizedXAxisConfig = (maxValue: number, isBelowSm: boolean) => {
    if (maxValue <= 2) {
      return {
        min: 0,
        max: Math.ceil(maxValue * PADDING_FACTOR),
        tickAmount: maxValue <= 1 ? 2 : 3,
        decimalsInFloat: maxValue <= 1 ? 1 : 0,
        labels: {
          formatter: (val: number) => {
            if (maxValue <= 1) {
              return val;
            }
            return Math.round(val).toString();
          },
        },
      };
    }

    return {
      min: 0,
      max: Math.ceil(maxValue * PADDING_FACTOR),
      tickAmount: isBelowSm ? 3 : undefined,
      labels: {
        formatter: (val: number) => formatters.yAxis(Math.round(val)),
      },
    } as any;
  };

  const options = useMemo<ApexOptions>(
    () => ({
      chart: {
        type: 'bar',
        height: chartHeight,
        width: '100%',
        toolbar: { show: false },
        animations: { enabled: false },
      },
      colors: ['#74A9FF'],
      dataLabels: { enabled: false },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '60%',
          dataLabels: { position: 'outside' },
          borderRadius: 2,
        },
      },
      background: { enabled: true },
      grid: {
        show: true,
        borderColor: '#e7e7e7',
        strokeDashArray: 4,
        position: 'back',
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: false } },
      },
      borderColor: '#f0f0f0',

      labels: seriesData.key,
      xaxis: {
        ...getOptimizedXAxisConfig(maxValue, isBelowSm),
        axisTicks: {
          show: true,
          color: '#E5E7EB',
        },
        axisBorder: {
          show: true,
          color: '#E5E7EB',
        },
      },
      yaxis: {
        labels: {
          minWidth: yAxisWidth,
          maxWidth: yAxisWidth,
          style: { colors: ['#000'] },
        },
      },
      tooltip: {
        y: { formatter: formatters.tooltip },
      },
    }),
    [seriesData.key, isBelowSm, paddedMaxValue, yAxisWidth, chartHeight, formatters],
  );

  const series = useMemo(
    () => [
      {
        name: 'Request Count',
        data: seriesData.value,
      },
    ],
    [seriesData.value],
  );

  if (!seriesData.value.length) {
    return <NoRecordFound />;
  }

  return (
    <div className="justify-center mt-8">
      <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
    </div>
  );
});
