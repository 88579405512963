import { FormType, TableActionHandlerType } from '@enums';
import { useFormSelectedList } from '@store';
import { checkIdProperty } from '@utils';

import { FormHandlers } from '@hooks';
import { DraggableItem } from '../DraggableTable';

interface CheckBoxTableCellDefaultProps<T> {
  formType: FormType;
  data: T;
  actionHandlers: FormHandlers<T>;
}

export const CheckBoxTableCell = <T extends DraggableItem>({
  formType,
  data,
  actionHandlers,
}: CheckBoxTableCellDefaultProps<T>) => {
  const selectedData = useFormSelectedList(formType);

  if (!checkIdProperty(data)) return;
  return (
    <input
      type="checkbox"
      checked={selectedData?.includes(data.id) ?? false}
      onChange={() => actionHandlers[TableActionHandlerType.handleCheck]?.(formType, data.id)}
      className="h-3.5 w-3.5 mx-3 rounded border-gray-300 text-indigo-600 focus:ring-0 hover:cursor-pointer"
    />
  );
};
