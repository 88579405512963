import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  getWorkspaceOptions,
  getWorkspaceOptionsError,
  getWorkspaceOptionsSuccess,
  updateWorkspaceId,
  updateWorkspaceIdError,
  updateWorkspaceIdSuccess,
} from '@store';
import { API } from '@utils';
import { workSpaceId } from 'storage';

export function* getWorkspaceOptionSaga() {
  try {
    const currentWorkspace_id = yield select((state) => state.workspace.currentId);
    const { data: responseData } = yield call(API.get, '/workspace');
    const { workspace_list, default_workspace_id } = responseData;

    if (default_workspace_id && (!currentWorkspace_id || currentWorkspace_id !== default_workspace_id)) {
      yield put(updateWorkspaceId(default_workspace_id));
    }
    yield put(getWorkspaceOptionsSuccess(workspace_list));
  } catch (error: any) {
    yield put(getWorkspaceOptionsError(error));
  }
}

export function* updateWorkspaceIdSaga({ payload: workspace_id }: PayloadAction<any>) {
  try {
    const currentWorkspaceId = workSpaceId.getItem();

    if (workspace_id && workspace_id !== currentWorkspaceId) {
      yield call(workSpaceId.setItem, workspace_id);
      yield put(updateWorkspaceIdSuccess(workspace_id));
    }
    yield put(updateWorkspaceIdSuccess({ workspace_id: Number(workspace_id) }));
  } catch (error: any) {
    yield put(updateWorkspaceIdError(error));
  }
}

export function* workspaceSagaWatcher() {
  yield takeLatest(getWorkspaceOptions.type, getWorkspaceOptionSaga);
  yield takeLatest(updateWorkspaceId.type, updateWorkspaceIdSaga);
}
