const API_URL: string | undefined = import.meta.env.VITE_API_URL as string | undefined;

import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { addHours } from 'date-fns';
import i18n from 'i18next';
import jwtDecode from 'jwt-decode';

import {
  ERROR_CODES,
  ERROR_CUSTOM_SNACKS,
  HTTP_STATUS_MESSAGES,
  getAxiosResponseErrorCodeExceptionsList,
  getAxiosResponseErrorMessageExceptionList,
  getAxiosResponseErrorUrlExceptionList,
} from '@constants';
import { accessToken } from '@core/storage';
import { Headers, StatusType } from '@enums';
import { addSnack, store } from '@store';
import { ApiErrorData } from '@types';
import { cleanupRequestTracker, tracker, validateValueEqual } from '@utils';

export const API: AxiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

const createRequestKey = (config: any): string => {
  const { url, method, params, data } = config;
  return `${method}-${url}${params ? `?${JSON.stringify(params)}` : ''}${data ? `-${JSON.stringify(data)}` : ''}`;
};

const TOKEN_WAIT_TIMES = {
  refresh: 10000,
  access: 30000,
} as const;

async function waitForValidToken(token: string, waitTime: number): Promise<string | null> {
  const endTime = Date.now() + waitTime;
  const tokenExp = jwtDecode<{ exp: number }>(token).exp * 1000;

  while (Date.now() < endTime) {
    const currentToken = accessToken.getItem();
    if (currentToken && currentToken !== token) return currentToken;
    if (Date.now() >= tokenExp) return null;
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  return null;
}

async function handleTokenRefresh(config: any, token: string, isRefreshToken: boolean): Promise<any> {
  const waitTime = isRefreshToken ? TOKEN_WAIT_TIMES.refresh : TOKEN_WAIT_TIMES.access;
  const newToken = await waitForValidToken(token, waitTime);

  if (newToken) {
    config.headers[Headers.Authorization] = `Bearer ${newToken}`;
    return config;
  }

  return config;
}

export const setHeaders = (headers: { [key: string]: string }, axiosInstance: AxiosInstance = API) => {
  const commonHeaders = { ...axiosInstance.defaults.headers.common };
  Object.keys(headers).forEach((key) => (commonHeaders[key] = headers[key]));
  axiosInstance.defaults.headers.common = commonHeaders;
};
export const deleteHeaders = (headerKeys: string[], axiosInstance: AxiosInstance = API) => {
  const commonHeaders = { ...axiosInstance.defaults.headers.common };
  headerKeys.forEach((key) => commonHeaders[key] && delete commonHeaders[key]);
  axiosInstance.defaults.headers.common = commonHeaders;
};
export function setInitialHeaders() {
  const access_token = accessToken.getItem();
  const headers: Record<string, string> = {};
  if (access_token) {
    API.defaults.headers.common[Headers.Authorization] = `Bearer ${access_token}`;
  }
  return headers;
}

setInitialHeaders();

API.interceptors.request.use(
  async (config: any) => {
    const requestKey = createRequestKey(config);

    if (tracker.pendingRequests.has(requestKey)) {
      const controller = tracker.pendingRequests.get(requestKey);
      return new Promise((resolve, reject) => {
        controller?.signal.addEventListener('complete', (response) => {
          resolve(response);
        });
        controller?.signal.addEventListener('error', (error) => {
          reject(error);
        });
      });
    }

    const authHeader = config.headers[Headers.Authorization] as string | undefined;
    if (authHeader?.startsWith('Bearer ')) {
      const token = authHeader.slice(7);
      const { exp, type } = jwtDecode<{ exp: number; type: string }>(token);

      if ((type === 'refresh' && config.url !== '/user/refresh') || Date.now() >= exp * 1000) {
        return handleTokenRefresh(config, token, type === 'refresh');
      }
    }

    const controller = new AbortController();
    tracker.pendingRequests.set(requestKey, controller);
    config.signal = controller.signal;
    config._requestKey = requestKey;

    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (response: AxiosResponse) => {
    const config = response.config as any;
    cleanupRequestTracker(config._requestKey);
    return response;
  },
  async (error: AxiosError<ApiErrorData>) => {
    const config = error.config as any;

    let skipMessageDisplay = false;

    cleanupRequestTracker(config?._requestKey);

    if (!error.response && config?._retryCount < tracker.MAX_RETRIES) {
      config._retryCount++;
      await new Promise((resolve) => setTimeout(resolve, tracker.RETRY_DELAYS[config._retryCount - 1]));
      return API(config);
    }

    if (!error.response) {
      await Promise.all([
        store.dispatch({
          type: addSnack.type,
          payload: {
            type: StatusType.Error,
            message: i18n.t(`errors:SYSTEM_ERROR`),
          },
        }),
      ]);

      return Promise.reject(error);
    }

    const { status, data } = error.response;
    const errorCode = data.status_code ?? status ?? data?.code;
    const errorMessage = data.message ?? HTTP_STATUS_MESSAGES[status] ?? 'errors:SYSTEM_ERROR';
    const url = config?.url ?? '';

    const [codeExceptions, urlExceptions, messageExceptions] = await Promise.all([
      getAxiosResponseErrorCodeExceptionsList(),
      getAxiosResponseErrorUrlExceptionList(),
      getAxiosResponseErrorMessageExceptionList(),
    ]);

    if (validateValueEqual(status, 503)) {
      localStorage.setItem('maintenanceEndTime', addHours(new Date(), 24).toISOString());
      skipMessageDisplay = true;
    }

    skipMessageDisplay =
      skipMessageDisplay ||
      (url && urlExceptions.some((urlEx) => url.includes(urlEx))) ||
      (errorCode && codeExceptions.some((exception) => exception === String(errorMessage))) ||
      (errorMessage && messageExceptions.some((exception) => exception === String(errorMessage)));

    if (!skipMessageDisplay && errorCode) {
      const toastMessage = i18n.exists(`errors:${errorCode}`)
        ? i18n.t(`errors:${errorCode}`, { message: errorMessage })
        : errorMessage;

      store.dispatch({
        type: addSnack.type,
        payload: ERROR_CUSTOM_SNACKS[errorCode.toString() as ERROR_CODES] || {
          type: StatusType.Error,
          message: toastMessage,
        },
      });
    }

    return Promise.reject(error);
  },
);

window.addEventListener('beforeunload', () => {
  tracker.pendingRequests.clear();
});
