import { ApexOptions } from 'apexcharts';
import { FC, memo, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { NoRecordFound } from '@components';
import { LINE_GRAPH_RESPONSIVE_CONFIG } from '@constants';
import { ApiEndpoint, DashboardEndpoint, FormType } from '@enums';
import { useChartDimensions, useTimeAndDomainFilters } from '@hooks';
import { useFormList } from '@store';
import { convertGraphDateTimeFormat, formatNumberWithSeparator, formatYAxisValue, validateValueEqual } from '@utils';

const BASE_CHART_OPTIONS = {
  chart: {
    type: 'line',
    zoom: { enabled: false },
    toolbar: { show: false },
    animations: { enabled: false },
  },
  stroke: { curve: 'smooth', width: 2 },
  dataLabels: { enabled: false },
  yaxis: {
    min: 0,
    labels: {
      formatter: (value: number) =>
        validateValueEqual(value, 0) ? '' : formatNumberWithSeparator(formatYAxisValue({ value })),
    },
  },
  tooltip: {
    x: { format: 'dd MMM yyyy HH:mm' },
    y: { formatter: (value: number) => formatNumberWithSeparator(value) },
  },
} as const;

interface ChartPayload {
  formType: FormType;
  endpoint: ApiEndpoint;
}

export const AttackCountGraph: FC<{ payload: ChartPayload }> = memo(({ payload }) => {
  const graphData = useFormList(payload);

  const { timeFilter } = useTimeAndDomainFilters({
    formType: payload.formType,
    endpoint: DashboardEndpoint.TOTAL_TRAFFIC,
  });

  const { chartHeight } = useChartDimensions({ dimensions: LINE_GRAPH_RESPONSIVE_CONFIG });

  const { seriesData, chartOptions } = useMemo(() => {
    if (!graphData?.length) return { seriesData: [], chartOptions: BASE_CHART_OPTIONS };

    const availableKeys = new Set<string>();
    graphData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'timestamp' && item[key] !== undefined) availableKeys.add(key);
      });
    });

    const series = Array.from(availableKeys).map((key) => ({
      name: key.replace(/_/g, ' '),
      data: graphData.filter((item) => item[key] !== undefined).map((item) => [item.timestamp * 1000, item[key]]),
    }));

    const options: ApexOptions = {
      ...BASE_CHART_OPTIONS,
      xaxis: {
        type: 'datetime',
        labels: {
          format: convertGraphDateTimeFormat(timeFilter),
          datetimeUTC: false,
        },
      },
      colors: ['#007BFF', '#FF0000', '#00C853', '#FF9800', '#9C27B0'],
    };

    return {
      seriesData: series,
      chartOptions: options,
    };
  }, [graphData, timeFilter]);

  if (!timeFilter) return null;
  if (!seriesData.length || !seriesData[0]?.data?.length) return <NoRecordFound />;

  return (
    <div className="mt-8">
      <ReactApexChart options={chartOptions} series={seriesData} height={chartHeight} type="line" />
    </div>
  );
});
