import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { CheckBadgeIcon, XCircleIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { AnimatePresence, motion } from 'framer-motion';
import { AlertCircleIcon, CircleHelpIcon } from 'lucide-react';
import { memo } from 'react';

import { Button } from '@components';
import { validateValueEqual } from '@utils';

const iconVariants = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

export const InputWithIcons = memo(
  ({
    isError,
    isCorrect,
    field,
    hints,
    isPassword,
    isHide,
    iconInputRef,
    clearFunction,
    paddingRight,
    handleHideIcon,
    isNumber,
    handleClear,
    showClearIcon,
    withoutClear,
  }: any) => {
    const isHidingPasswordText = validateValueEqual(isHide, 'password');

    return (
      <motion.div
        ref={iconInputRef}
        className="flex items-center space-x-2 absolute right-3 top-1/2 transform -translate-y-1/2 pl-6"
        style={{ right: paddingRight }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        <AnimatePresence>
          {isCorrect && field.value && (
            <motion.div key="correct" variants={iconVariants} initial="initial" animate="animate" exit="exit">
              <CheckBadgeIcon className="size-4 pointer-events-none text-green-400" />
            </motion.div>
          )}
          {!!hints && (
            <motion.div key="hints" variants={iconVariants} initial="initial" animate="animate" exit="exit">
              <Tippy content={hints} role="tooltip">
                <CircleHelpIcon
                  className="size-4 z-50 hover:cursor-pointer text-blue-400"
                  tabIndex={-1}
                  aria-hidden="true"
                />
              </Tippy>
            </motion.div>
          )}
          {isPassword && (
            <motion.div key="password" variants={iconVariants} initial="initial" animate="animate" exit="exit">
              {isHidingPasswordText ? (
                <EyeIcon
                  className="size-4 z-50 hover:cursor-pointer focus:outline-none text-gray-400"
                  onClick={handleHideIcon}
                />
              ) : (
                <EyeSlashIcon
                  className="size-4 z-50 hover:cursor-pointer focus:outline-none text-gray-400"
                  onClick={handleHideIcon}
                />
              )}
            </motion.div>
          )}
          {showClearIcon && !isNumber && !withoutClear && (field.value || field.value === 0) && (
            <motion.div key="clear" variants={iconVariants} initial="initial" animate="animate" exit="exit">
              <Button
                onMouseDown={(e) => e.preventDefault()}
                withoutPadding
                iconWithoutM
                customizeColor
                className="hover:cursor-pointer"
                onClick={clearFunction ?? handleClear}
                icon={<XCircleIcon className="size-4 text-gray-400" />}
                tabIndex={-1}
                aria-label="Clear input"
              />
            </motion.div>
          )}
          {isError && (
            <motion.div key="error" variants={iconVariants} initial="initial" animate="animate" exit="exit">
              <AlertCircleIcon className="size-4 text-red-400 pointer-events-none" />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  },
);
