import { MinusCircleIcon } from '@heroicons/react/20/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Field, FieldArray, useField, useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { VariantType } from '@enums';
import { Button } from '../Buttons';
import { TextInput } from './TextInput/TextInput';
import ErrorMessage from './TextInput/errorMessage';

export const MultipleInput = ({
  name,
  nestedName,
  disabled,
  maxField,
  emptyData,
  withoutErrorMessage,
  nestedComponent,
  ...props
}) => {
  const [field, meta] = useField(name);
  const formik = useFormikContext();

  return (
    <FieldArray name={name}>
      {({ remove, push }) => {
        return (
          <div className="grid grid-cols-1 gap-y-4 flex-col w-full">
            {field.value.map((_, index) => {
              const isFieldLessThanOne = field.value.length <= 1;
              const isFieldHitMaximum = field.value.length >= maxField;

              const nestedIndex = `${name}.${index}`;
              const nestedInputName = nestedName ? `${[nestedIndex]}.${nestedName}` : [nestedIndex];

              const isFieldError = Boolean(
                meta.error && (meta.initialTouched || meta.touched || formik.submitCount > 0),
              );

              const nestedError = meta.error?.[index];
              const inputError = nestedError?.[nestedName];
              const nestedComponentError = nestedComponent ? nestedError?.[nestedComponent.props.name] : undefined;
              const isInputError = !!inputError && isFieldError;
              const isNestedComponentError = !!nestedComponentError && isFieldError;

              return (
                <div key={index} className="flex flex-col w-full gap-3">
                  <div className="flex w-full space-x-3 items-center">
                    <Field
                      {...props}
                      name={nestedInputName}
                      onBlur={field.onBlur}
                      disabled={disabled}
                      withoutErrorMessage={withoutErrorMessage}
                      isError={isInputError}
                      fullWidth={false}
                      as={TextInput}
                    />
                    {!!nestedComponent &&
                      React.cloneElement(nestedComponent, {
                        name: `${[nestedIndex]}.${nestedComponent.props.name}`,
                        isError: isNestedComponentError,
                        withoutErrorMessage: withoutErrorMessage,
                      })}
                    <div className="flex space-x-3">
                      <Button
                        className="bg-white"
                        withoutPadding
                        disabled={disabled || isFieldHitMaximum}
                        variant={VariantType.Clean}
                        onClick={() => push(emptyData)}
                      >
                        <PlusCircleIcon
                          className={clsx('size-6', {
                            'text-gray-300': isFieldHitMaximum,
                            'text-blue-400': !isFieldHitMaximum,
                          })}
                        />
                      </Button>
                      <Button
                        className="bg-white"
                        variant={VariantType.Clean}
                        disabled={disabled || isFieldLessThanOne}
                        withoutPadding
                        onClick={() => remove(index)}
                      >
                        <MinusCircleIcon
                          className={clsx('size-6', {
                            'text-gray-300': isFieldLessThanOne,
                            'text-red-500': !isFieldLessThanOne,
                          })}
                        />
                      </Button>
                    </div>
                  </div>
                  {!!withoutErrorMessage && (
                    <AnimatePresence>
                      {isFieldError && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.2 }}
                          className="space-y-1.5"
                        >
                          {isInputError && <ErrorMessage isError={isInputError} meta={{ error: inputError }} />}
                          {!!nestedComponent && isNestedComponentError && (
                            <ErrorMessage isError={isNestedComponentError} meta={{ error: nestedComponentError }} />
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  )}
                </div>
              );
            })}
          </div>
        );
      }}
    </FieldArray>
  );
};
