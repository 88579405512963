export const SMOOTH_FADE_IN_ENTRANCE_ANIMATION = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
} as const;

export const LOGIN_ANIMATION_CONFIG = {
  duration: 0.3,
  initialDelay: 0.2,
  buttonDelay: 0.3,
} as const;
