import { format, isValid, parseISO } from 'date-fns';
import i18n from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Column, InformationModal, Text } from '@components';
import { CellType, Dictionaries, sslCertificateType, Translation } from '@enums';
import { SslStatusLog } from '@pages/Cdn/subPages/CdnDashboard/subPages/sslStatusLog';
import { getCodeValueFromCatalog, getColumnSizes, validateValueEqual } from '@utils';

export const StatusCell = ({ value, id, type }: { value: string; id: number; type: string }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => {
    validateValueEqual(type, sslCertificateType.letsEncrypt) && setOpenModal(!openModal);
  };

  const { t: tDescription } = useTranslation(Translation.Description, { keyPrefix: 'configuration' });

  const getStatusColor = (status) => {
    const colorMap = {
      expired: 'red',
      pendingIssue: 'yellow',
      valid: 'green',
    };
    return colorMap[status] ?? 'yellow';
  };

  return (
    <>
      <div className="h-full">
        <Badge
          color={getStatusColor(value)}
          className={`${validateValueEqual(type, sslCertificateType.letsEncrypt) ? 'cursor-pointer' : 'cursor-default'} self-start px-2.5 py-1 ellipsis-text`}
          onClick={handleModal}
        >
          <Text $level={5} $customizeColor className="truncate">
            {i18n.t(`common:certificateStatus.${value}`)}
          </Text>
        </Badge>
      </div>
      {openModal && (
        <InformationModal
          title={tDescription('ssl.status.status_title')}
          size="2xl"
          topTitle
          hideIcon
          content={<SslStatusLog sslId={id} />}
          onClose={handleModal}
          open={openModal}
        />
      )}
    </>
  );
};

export const getSSLCertificateColumns = (): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', ...getColumnSizes(50), multiLines: true },
  {
    label: i18n.t('configuration:tables.name'),
    value: 'name',
    ...getColumnSizes(100),
    multiLines: true,
  },
  {
    label: i18n.t('configuration:tables.domain'),
    value: 'domain',
    format: (value) => {
      return (
        <div className="flex flex-col flex-wrap space-y-1.5 h-full">
          {value?.length > 0 ? (
            value.map((entry, index) => {
              return (
                <Text key={index} $level={4} $customizeColor className="truncate ellipsis-text font-normal leading-6">
                  {entry ?? '—'}
                </Text>
              );
            })
          ) : (
            <Text $level={4} $customizeColor className="leading-6 ellipsis-text font-normal select-text">
              —
            </Text>
          )}
        </div>
      );
    },
    multiLines: true,
    cellType: CellType.Custom,
    ...getColumnSizes(180),
  },
  {
    label: i18n.t('configuration:tables.type'),
    format: (value) => {
      return getCodeValueFromCatalog(Dictionaries.SSLType, value);
    },
    value: 'type',
    ...getColumnSizes(70),
    multiLines: true,
  },
  {
    label: i18n.t('configuration:tables.status'),
    value: 'status',
    cellType: CellType.Custom,
    format: (value, row) => <StatusCell value={value} id={row.id} type={row.type} />,
    ...getColumnSizes(100),
    multiLines: true,
  },
  {
    label: i18n.t('configuration:tables.expiredOn'),
    format: (value) => {
      return value && typeof value === 'string' && isValid(parseISO(value))
        ? format(parseISO(value), 'dd MMM yyyy')
        : '—';
    },
    value: 'expired_at',
    ...getColumnSizes(90),
    multiLines: true,
  },
  {
    label: i18n.t('configuration:tables.action'),
    value: 'actions',
    cellType: CellType.Action,
    ...getColumnSizes(90),
    multiLines: true,
  },
];
