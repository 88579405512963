import { ValidationType } from '@enums';
import { createValidationSchema } from '@utils';

export const SIGN_IN_FORM_SCHEMA = createValidationSchema({
  username: {
    required: true,
    pattern: ValidationType.EMAIL,
  },
  password: {
    required: true,
    minLength: 8,
  },
});
