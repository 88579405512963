import { TableCellsIcon } from '@heroicons/react/20/solid';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Plus, Save } from 'lucide-react';
import React, { useMemo } from 'react';

import { FormActionType, TableActionType, VariantType } from '@enums';
import { FormHandlers } from '@hooks';
import { validateValueEqual } from '@utils';

import { Text } from '../Text';
import { Button } from './Button';

interface ActionConfig {
  icon?: React.ReactNode;
  color: any;
  tooltip: string;
  condition: (data: any) => boolean;
}

type ActionConfigType = {
  [K in TableActionType]?: ActionConfig;
};

const actionConfigs: ActionConfigType = {
  [TableActionType.Create]: {
    icon: <Plus className="w-4 h-4" />,
    color: 'blue',
    tooltip: 'Create',
    condition: () => true,
  },
  [TableActionType.Save]: {
    icon: <Save className="w-4 h-4" />,
    color: 'blue',
    tooltip: 'Create',
    condition: () => true,
  },
  [TableActionType.Verification]: {
    icon: <ShieldCheckIcon className="w-4 h-4" />,
    color: 'blue',
    tooltip: 'verify',
    condition: () => true,
  },
  [TableActionType.Priority]: {
    icon: <TableCellsIcon className="w-4 h-4" />,
    color: 'blue',
    tooltip: 'verify',
    condition: () => true,
  },
};

interface ActionButtonGroupProps<T> {
  actionHandlers: FormHandlers<T>;
  loading?: boolean;
  procedure?: FormActionType;
}

export const ActionButtonGroup = <T,>({
  actionHandlers,
  procedure = FormActionType.Idle,
  loading = false,
}: ActionButtonGroupProps<T>) => {
  const renderActionButtons = useMemo(() => {
    return Object.entries(actionHandlers)
      .map(([type, action]) => {
        const actionType = type as keyof ActionConfigType;
        const config = actionConfigs[actionType];

        if (!config || !action.display) return null;

        const isDisabled = action.disabled || loading;
        const currentFormSubmitting = validateValueEqual(procedure, type);
        const isOutlinedButton = validateValueEqual(action.buttonType, VariantType.Outlined);
        const isCleanButton = validateValueEqual(action.buttonType, VariantType.Clean);

        return (
          <Button
            key={type}
            onClick={() => action.handler()}
            disabled={isDisabled}
            variant={action.buttonType}
            loading={action.loading && currentFormSubmitting}
            predefinedColor={!isCleanButton ? (isDisabled ? 'zinc' : config.color) : undefined}
            icon={config.icon}
            customizeColor={isCleanButton}
            withoutPadding={isCleanButton}
            className={clsx('', {
              'bg-transparent': isOutlinedButton,
              ['shadow']: !isCleanButton,
              [action.className]: action?.className,
            })}
          >
            <Text $level={5} $customizeColor className="ellipsis-text">
              {action?.label ?? config.tooltip}
            </Text>
          </Button>
        );
      })
      .filter(Boolean);
  }, [actionHandlers, loading]);

  if (!actionHandlers || Object.keys(actionHandlers).length === 0) {
    return null;
  }

  return <div className="flex items-center space-x-5">{renderActionButtons}</div>;
};
