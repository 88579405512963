import { FormikProps } from 'formik';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActionButtonGroup, ConfigurationTab, Responsive } from '@components';
import { FormActionType, FormType, Translation } from '@enums';
import { useFetchFormList, useFormHandlers } from '@hooks';
import { useFormLoadingStatus, useFormProcedure, useFormSubmissionStatus } from '@store';
import { validateValueEqual } from '@utils';

import { PredefinedPageRulesForm } from './subCards';

export const PageRules: FC = () => {
  const { id: application_id } = useParams();

  const formType = FormType.CdnPageRules;

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.pageRules' });
  const { t: tConfigurationButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons' });

  const formProcedure = useFormProcedure(formType);
  const formSubmitting = useFormSubmissionStatus({ formType });
  const formLoading = useFormLoadingStatus({ formType });

  useEffect(() => {
    if (formikRef.current?.errors && Object.keys(formikRef.current.errors).length > 0) {
      formikRef.current.validateForm();
    }
  }, [tTabs]);

  useFetchFormList({
    formType,
    params: { application_id },
    globalOptions: { content: true },
  });

  const formikRef = useRef<FormikProps<any> | null>(null);

  const tabs = [{ name: tTabs('pageRules'), key: 'pageRules' }];

  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const handleCurrentTab = useCallback((currentTab: string) => {
    setActiveTab(currentTab);
  }, []);

  const handleSaveSettings = useCallback(() => {
    formikRef?.current?.handleSubmit();
  }, []);

  const handleFormikInstance = useCallback((formik: FormikProps<any> | null) => {
    formikRef.current = formik;
  }, []);

  const PageRulesFormHandler = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Save,
        function: handleSaveSettings,
        label: tConfigurationButton('saveSettings'),
        loading: formSubmitting,
        disabled: formLoading,
      },
    ],
  });

  return (
    <div className="flex flex-col flex-1 space-y-8">
      <div className="flex justify-between">
        <ConfigurationTab arrayTab={tabs} currentTab={activeTab} setCurrentTab={handleCurrentTab} />
        <Responsive showAbove="sm">
          {validateValueEqual(activeTab, 'pageRules') && (
            <ActionButtonGroup actionHandlers={PageRulesFormHandler} procedure={formProcedure} />
          )}
        </Responsive>
      </div>
      <div className={`space-y-8`}>
        <PredefinedPageRulesForm onFormikInstance={handleFormikInstance} />
      </div>
      <Responsive showBelow="sm">
        {validateValueEqual(activeTab, 'pageRules') && (
          <ActionButtonGroup actionHandlers={PageRulesFormHandler} procedure={formProcedure} />
        )}
      </Responsive>
    </div>
  );
};
