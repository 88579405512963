import i18n from '@core/i18n';

import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  addSnack,
  clearProfileError,
  submitChangePasswordForm,
  submitChangePasswordFormError,
  submitChangePasswordFormSuccess,
} from '@store';

import { StatusType, Translation } from '@enums';
import { API } from '@utils';

export function* submitChangePasswordFormSaga({ payload }: PayloadAction<any>) {
  try {
    yield call(API.post, `/user/update-password`, {
      ...payload,
    });
    yield put(clearProfileError());
    yield put(submitChangePasswordFormSuccess());
    yield put(
      addSnack({
        type: StatusType.Success,
        message: i18n.t(`${Translation.SignIn}:toast.passwordChangedSuccessfully`),
      }),
    );
  } catch (error) {
    const ErrorResponse = error as AxiosError;
    yield put(submitChangePasswordFormError(ErrorResponse?.response?.data));
  }
}

export function* profileSagaWatcher() {
  yield takeLatest(submitChangePasswordForm.type, submitChangePasswordFormSaga);
}
