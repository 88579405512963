import { Formik } from 'formik';
import i18n from 'i18next';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Button, FormField, PredefinedCards, Responsive, Text } from '@components';
import { initData, updateDataInStorage } from '@core/storage';
import { Dictionaries, DisplayMode, InputMod, InputType, Language, Translation } from '@enums';
import { useUserDetails } from '@store';
import { getOptionListFromCatalog } from '@utils';

export const ProfileSettingsForm = ({ handleVerifyDialog, handleChangePassword }) => {
  const init = initData.getItem();

  const { t: tTabs } = useTranslation(Translation.ProfileSettings, { keyPrefix: 'tabs' });
  const { t: tForms } = useTranslation(Translation.ProfileSettings, { keyPrefix: 'forms' });
  const { t: tButtons } = useTranslation(Translation.ProfileSettings, { keyPrefix: 'buttons' });
  const { t: tCommon } = useTranslation(Translation.Common);

  const handleSubmit = () => {};
  const handleReset = () => {};
  const handleEmail = () => {};
  const userDetails = useUserDetails();

  const initialValues = {
    email: userDetails?.email ?? '',
    displayMode: init?.displayMode ?? DisplayMode.Light,
    language: init?.language ?? Language.English,
    status: userDetails?.status ?? 0,
  };

  const onOptionClick = (event, formik, name) => {
    const currentOption = event.target.value;
    formik.setFieldValue(name, currentOption);
    if (name === 'language') {
      formik.setFieldValue(name, currentOption);

      updateDataInStorage(initData, ['language'], { language: currentOption });

      // Trigger a change in the i18n language
      i18n.changeLanguage(currentOption);
    }
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit} onReset={handleReset}>
      {(formik) => {
        return (
          <Fragment>
            <PredefinedCards
              title={
                <div className="flex justify-between gap-3">
                  {tTabs('emailAddress')}
                  <Responsive showBelow="sm">
                    <Badge className="px-2.5 py-1" color={formik.values.status === 1 ? 'green' : 'red'}>
                      {formik.values.status
                        ? tCommon('verificationStatus.VERIFIED')
                        : tCommon('verificationStatus.UNVERIFIED')}
                    </Badge>
                  </Responsive>
                </div>
              }
              description={
                <div className="flex space-x-3 items-center">
                  <Text $level={4} color="text-black" className=" flex-grow">{`${formik.values.email}`}</Text>
                  <Responsive showAbove="sm">
                    <Badge className="px-2.5 py-1" color={formik.values.status === 1 ? 'green' : 'red'}>
                      {formik.values.status
                        ? tCommon('verificationStatus.VERIFIED')
                        : tCommon('verificationStatus.UNVERIFIED')}
                    </Badge>
                  </Responsive>
                </div>
              }
            >
              <Button onClick={handleEmail} fullWidth disabled={true} className="flex">
                {tButtons('changeEmailAddress')}
              </Button>
            </PredefinedCards>
            <PredefinedCards title={tTabs('password')} description={tTabs('passwordDescription')}>
              <Button onClick={handleChangePassword} fullWidth className="flex">
                {tButtons('changePassword')}
              </Button>
            </PredefinedCards>
            <PredefinedCards title={tTabs('appearance')} description={tTabs('appearanceDescription')}>
              <FormField
                label={tForms('displayMode')}
                name="displayMode"
                inputType={InputType.Select}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                fullWidth
                disabled={!formik.values?.displayMode}
                placeholder={tForms('selectYourDisplayMode')}
                options={getOptionListFromCatalog({ name: Dictionaries.DisplayMode, hiddenOptions: [] })}
                onChange={(event) => onOptionClick(event, formik, 'displayMode')}
              />
            </PredefinedCards>
            <PredefinedCards title={tTabs('languagePreference')} description={tTabs('languageDescription')}>
              <FormField
                label={tForms('language')}
                name="language"
                inputType={InputType.Select}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                fullWidth
                disabled={!formik.values?.displayMode}
                placeholder={tForms('selectYourLanguagePreference')}
                options={getOptionListFromCatalog({ name: Dictionaries.Languages })}
                onChange={(event) => onOptionClick(event, formik, 'language')}
              />
            </PredefinedCards>
          </Fragment>
        );
      }}
    </Formik>
  );
};
