import type { LoginFormConfig } from '@core/types/forms/user/CreateUserAccountForm';

export const CREATE_NEW_PASSWORD_FORM_CONFIG = (email: string): LoginFormConfig => {
  return {
    initialValues: {
      email: email ?? '',
      password: '',
      confirm_password: '',
    },
    fields: [
      {
        name: 'email',
        label: 'form.email',
        placeholder: 'form.enterYourEmailAddress',
        isAnimated: true,
        disabled: true,
      },
      {
        name: 'password',
        label: 'form.password',
        placeholder: 'form.enterYourPassword',
        type: 'password',

        isAnimated: true,
      },
      {
        name: 'confirm_password',
        label: 'form.confirmPassword',
        placeholder: 'form.enterYourConfirmPassword',
        type: 'password',
        isAnimated: true,
      },
    ],
  };
};
