import classNames from 'classnames';
import { memo } from 'react';

import {
  ActionTableCell,
  BadgeTableCell,
  CheckBoxTableCell,
  Column,
  ColumnLayout,
  DefaultTableCell,
  DraggableItem,
} from '@components';
import { CellType, FormType } from '@enums';
import { FormHandlers } from '@hooks';
import { PaginationProps } from '@types';
import { validateValueEqual } from '@utils';

interface DraggableCellProps<T> {
  column: Column;
  item: T;
  pagination?: PaginationProps;
  rowId: number;
  layout: ColumnLayout;
  actionHandlers?: FormHandlers<DraggableItem>;
  formType: FormType;
}

export const DraggableCell = memo(
  <T extends DraggableItem>({
    column,
    item,
    rowId,
    layout,
    actionHandlers,
    formType,
    pagination,
  }: DraggableCellProps<T>) => {
    const cellValue = Array.isArray(column.value)
      ? column.value.map((value) => item[value]).join(', ')
      : item[column.value];

    const renderContent = () => {
      const formattedData = column.format?.(cellValue, item) ?? cellValue;

      switch (column.cellType) {
        case CellType.Badge:
          return <BadgeTableCell formType={formType} data={formattedData} rawData={cellValue} />;
        case CellType.Action:
          if (!actionHandlers) return null;
          return (
            <ActionTableCell
              formType={formType}
              multiLines={column.multiLines}
              actionHandlers={actionHandlers}
              data={item}
            />
          );
        case CellType.Checkbox:
          if (!actionHandlers) return;
          return <CheckBoxTableCell data={item} formType={formType} actionHandlers={actionHandlers} />;
        default:
          return (
            <DefaultTableCell
              pagination={pagination}
              rowId={rowId}
              data={formattedData}
              column={column}
              multiLines={column.multiLines}
            />
          );
      }
    };

    return (
      <div
        className={classNames('flex px-3 flex-col', {
          truncate: column.dotsTextOverflow,
          'break-words': !column.dotsTextOverflow,
          'justify-start h-auto py-3.5': column.multiLines,
          'justify-center py-1': !column.multiLines,
        })}
        style={{
          width: `${layout.percentage}%`,
          minWidth: layout.minWidth,
          flex: layout.flex,
        }}
      >
        <div
          className={classNames('w-full', {
            'text-left': !column.center,
            'text-center': column.center,
            'py-1.5': !column.multiLines && validateValueEqual(column.cellType, CellType.Action),
            'pb-3.5 pt-3.5': !column.multiLines && !validateValueEqual(column.cellType, CellType.Action),
            'h-full pt-1.5': column.multiLines && !validateValueEqual(column.cellType, CellType.Action),
          })}
        >
          {renderContent()}
        </div>
      </div>
    );
  },
);
