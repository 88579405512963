import { GlobeAsiaAustraliaIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { ShieldPlusIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Chip, InformationModal, Subheading, Text } from '@components';
import { getCdnDomainListColumns } from '@constants';
import { FormListLayout } from '@containers';
import {
  ApiEndpoint,
  Direction,
  FormActionType,
  FormType,
  ServiceModalName,
  StatusType,
  Translation,
  VariantType,
} from '@enums';
import { useAppDispatch, useFormHandlers } from '@hooks';
import {
  addServiceModal,
  addSnack,
  deleteFormContent,
  getCdnSummary,
  submitFormRequest,
  useCdnSummary,
  useFormSubmissionStatus,
} from '@store';

import { UnverifiedDomainsAnnouncement } from './domainAnnouncement';
import { DomainModal } from './domainModal';

export const DomainForm = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnDomain;

  const { t: tCommon } = useTranslation(Translation.Common);
  const { t: tTab } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.domain' });
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons' });
  const { t: tModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals' });
  const { t: tDescription } = useTranslation(Translation.Description, { keyPrefix: 'configuration.domain.cName' });
  const { t: tTables } = useTranslation(Translation.Description, { keyPrefix: 'configuration.tables' });
  const { t: tToast } = useTranslation(Translation.Configuration, { keyPrefix: 'toasts.domain' });

  const CdnSummaryConfiguration = useCdnSummary();
  const isDomainSubmitting = useFormSubmissionStatus({ formType });
  const isDomainVerifying = useFormSubmissionStatus({ formType, endpoint: ApiEndpoint.VERIFY });

  const cname = CdnSummaryConfiguration?.cname;
  const domainList = CdnSummaryConfiguration?.domain;

  const [openDomainModal, setOpenDomainModal] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleDialog = async () => {
    if (!cname) {
      setOpenDialog((prev) => !prev);
    } else {
      try {
        await navigator.clipboard.writeText(cname);
        dispatch(addSnack({ type: StatusType.Success, message: tToast('copySuccessfully', { cname: cname }) }));
      } catch (err) {
        console.warn('Unable to copy cname');
      }
    }
  };

  const domainFormHandlers = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Verification,
        function: (rowData) => {
          dispatch(
            addServiceModal({
              name: ServiceModalName.ConfirmModal,
              payload: {
                label: {
                  title: tModal('domain.domainVerification'),
                  icon: <ShieldPlusIcon className="size-10 text-blue-400" />,
                  text: `${tModal(rowData ? 'domain.areYouSureVerifyDomain' : 'domain.areYouSureVerifyAllDomain', { domain: rowData?.domain, cname })} ? `,
                  confirmButton: tCommon('button.verify'),
                },
                option: {
                  modalType: StatusType.Info,
                },
                callbackFunction: {
                  submit: () =>
                    dispatch(
                      submitFormRequest({
                        formType,
                        params: {
                          content_id: rowData?.id,
                          application_id: rowData?.id ? undefined : application_id,
                        },
                        globalOptions: {
                          endpoint: ApiEndpoint.VERIFY,
                          refetch_id: application_id,
                          refetchAnywhere: !rowData?.id,
                        },
                        callbackFunction: {
                          refetch: getCdnSummary(application_id),
                        },
                      }),
                    ),
                },
              },
            }),
          );
        },
        label: tButton('verifyAll'),
        buttonType: VariantType.Outlined,
        disabled:
          isDomainVerifying ||
          !cname ||
          domainList?.length <= 0 ||
          domainList?.every(({ cname_verified }) => cname_verified),
      },
      {
        type: FormActionType.Create,
        function: () => setOpenDomainModal(true),
        label: tButton('domain.addNewDomain'),
        disabled: isDomainVerifying || domainList?.length >= CdnSummaryConfiguration?.allow_domain_count,
      },
      {
        type: FormActionType.Edit,
        function: () => setOpenDomainModal(true),
        disabled: isDomainSubmitting || isDomainVerifying,
      },
      {
        type: FormActionType.Delete,
        disabled: isDomainSubmitting || isDomainVerifying,
        function: (rowData) => {
          dispatch(
            addServiceModal({
              name: ServiceModalName.ConfirmModal,
              payload: {
                label: {
                  title: tModal('domain.domainDelete'),
                  icon: <ShieldPlusIcon className="size-10 text-blue-400" />,
                  text: tModal('areYouSureDelete', { domain: rowData?.domain }),
                  confirmButton: tCommon('button.delete'),
                },
                option: {
                  modalType: StatusType.Delete,
                  button: {
                    loading: isDomainSubmitting,
                  },
                },
                callbackFunction: {
                  submit: () => {
                    dispatch(
                      deleteFormContent({
                        formType,
                        params: {
                          content_id: rowData?.id,
                          application_id: application_id,
                        },
                        globalOptions: {
                          refetch_id: application_id,
                        },
                        callbackFunction: {
                          refetch: getCdnSummary(application_id),
                        },
                      }),
                    );
                  },
                },
              },
            }),
          );
        },
      },
    ],
  });

  const cdnDomainTableColumns = useMemo(
    () =>
      getCdnDomainListColumns({
        cname,
        isDataSubmitting: isDomainSubmitting || isDomainVerifying,
        actionHandlers: domainFormHandlers,
      }),
    [tTables, cname, isDomainVerifying, isDomainSubmitting],
  );

  const handleDomainModal = () => {
    setOpenDomainModal(false);
  };

  return (
    <FormListLayout
      label={{
        title: (
          <div className="flex flex-col gap-5">
            <Subheading level={3}>{tTab('domainList')}</Subheading>
            <Chip
              smallFont
              chipType={StatusType.Success}
              label={cname ?? tDescription('cNameNotSet')}
              chipVariant={VariantType.Outlined}
              onChipClick={handleDialog}
              className="self-start"
              direction={Direction.Left}
              adornment={
                cname ? (
                  <GlobeAsiaAustraliaIcon className="animate-spin-gradual w-5 h-5" />
                ) : (
                  <ExclamationTriangleIcon className="w-5 h-5" />
                )
              }
            />
          </div>
        ),
      }}
      tableData={{
        columns: cdnDomainTableColumns,
        actionHandlers: domainFormHandlers,
        hasChildren: true,
        formType,
      }}
    >
      <InformationModal
        title={tDescription(cname ? 'cNameSet' : 'cNameNotSet')}
        topTitle
        content={
          <Text $level={5} $customizeColor className="leading-5 text-justify pt-1">
            {tDescription(cname ? 'withCnameDescription' : 'withoutCnameDescription')}
          </Text>
        }
        onClose={handleDialog}
        open={openDialog}
      />
      <DomainModal open={openDomainModal} onClose={handleDomainModal} />
      <UnverifiedDomainsAnnouncement
        currentApplicationData={domainList}
        actionHandlers={domainFormHandlers}
        cname={cname}
        isDomainSubmitting={isDomainSubmitting || isDomainVerifying}
      />
    </FormListLayout>
  );
};
