import { ValidationType } from '@enums';
import { createValidationSchema } from '@utils';

export const CREATE_USER_ACCOUNT_SCHEMA = createValidationSchema({
  firstname: {
    required: true,
    pattern: ValidationType.NAME,
    minLength: 2,
    maxLength: 50,
  },
  lastname: {
    required: true,
    pattern: ValidationType.NAME,
    minLength: 2,
    maxLength: 50,
  },
  username: {
    required: true,
    pattern: ValidationType.EMAIL,
  },
  password: {
    required: true,
    minLength: 8,
  },
  confirm_password: {
    required: true,
    customRule: {
      validator: ({ value, formData }) => value === formData.password,
      message: 'validation:changePassword.passwordsMustMatch',
    },
  },
});
