export const getInitials = (name: string): string => {
  if (!name?.trim()) {
    return '';
  }

  const words = name.trim().split(/\s+/);
  const initials = words.map((word) => word[0]?.toUpperCase());

  return initials.length > 2 ? initials.slice(0, 2).join('') : initials.join('');
};
