import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { GlobalLoader, Text } from '@components';
import { FormActionType, FormType, Translation, UserEndpoint } from '@enums';
import { fetchFormList, updateFormProcedure, useFormError, useFormLoadingStatus } from '@store';

import { ROUTES } from '@constants';
import { useAppDispatch } from '@hooks';
import { CreateNewPasswordForm } from './createNewPasswordForm';

export const CreateNewPassword: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: tTabs } = useTranslation(Translation.SignIn, { keyPrefix: 'tab' });

  const formType = FormType.User;
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const isFormError = useFormError({ formType, endpoint: UserEndpoint.VERIFY_NEW_PASSWORD });
  const isFormLoading = useFormLoadingStatus({ formType, endpoint: UserEndpoint.VERIFY_NEW_PASSWORD });

  useEffect(() => {
    if (!isFormError && email && token) {
      dispatch(updateFormProcedure({ formType, procedure: FormActionType.Reset }));
      dispatch(
        fetchFormList({
          formType,
          params: { email, token },
          globalOptions: { endpoint: UserEndpoint.VERIFY_NEW_PASSWORD },
        }),
      );
    }
    if (!email || !token || (isFormError && isFormError?.details === 'Invalid Token or User')) {
      navigate(ROUTES.login);
    }
  }, [email, token, isFormError]);

  if (isFormLoading) return <GlobalLoader color="white" />;
  return (
    <div className="flex flex-col gap-6">
      <motion.div
        className="text-start space-y-2.5"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.3 }}
      >
        <Text $level={2} $customizeColor className="leading-10 font-medium text-theme-text-main">
          {tTabs('setNewPassword')}
        </Text>
        <Text $level={5} $customizeColor className="text-gray-400/90">
          {`${tTabs('createANewPasswordForYourAccount')}`}
        </Text>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.3 }}
      >
        <CreateNewPasswordForm email={email} token={token} />
      </motion.div>
    </div>
  );
};
