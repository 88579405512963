import { motion } from 'framer-motion';
import { memo } from 'react';

import { FormField } from '@components';
import { LOGIN_ANIMATION_CONFIG, SMOOTH_FADE_IN_ENTRANCE_ANIMATION } from '@constants/global/Motion';
import type { LoginFormFields } from '@types';

export const LoginFormFieldWrapper = memo(
  ({ field, translation }: { field: LoginFormFields; translation: (key: string) => string }) => {
    const FormFieldComponent = (
      <FormField
        key={field.name}
        {...field}
        label={typeof field.label === 'string' ? translation(field.label) : field.label}
        placeholder={translation(field?.placeholder)}
      />
    );

    return field.isAnimated ? (
      <motion.div
        key={field.name}
        variants={SMOOTH_FADE_IN_ENTRANCE_ANIMATION}
        initial="hidden"
        animate="visible"
        transition={{ duration: LOGIN_ANIMATION_CONFIG.duration, delay: LOGIN_ANIMATION_CONFIG.initialDelay }}
      >
        {FormFieldComponent}
      </motion.div>
    ) : (
      FormFieldComponent
    );
  },
);
