import { FormType, OriginEndpoint } from '@enums';
import { useAppDispatch } from '@hooks';
import { fetchFormList, resetFormData, useFormList, useFormLoadingStatus } from '@store';
import { useEffect } from 'react';

export const useHealthCheckStatus = (status: boolean, rowId: string | number) => {
  const dispatch = useAppDispatch();
  const formType = FormType.CdnOrigin;
  const endpoint = OriginEndpoint.HEALTH_CHECK_STATUS;

  const formList = useFormList({ formType, endpoint });
  const formContent = formList?.[rowId] ?? [];
  const formLoading = useFormLoadingStatus({ formType, endpoint });

  useEffect(() => {
    if (!rowId) return;

    if (status) {
      dispatch(
        fetchFormList({
          formType,
          params: { content_id: rowId },
          globalOptions: {
            endpoint,
            unique: true,
            endPath: true,
          },
        }),
      );
    } else {
      dispatch(resetFormData({ formType, endpoint, uniqueId: rowId }));
    }
  }, [status, rowId, dispatch]);

  return { formContent, formLoading };
};
