import { ValidationType } from '@enums';
import { createValidationSchema } from '@utils';
import i18n from 'i18next';
import * as Yup from 'yup';

export const WORKSPACE_FORM_SCHEMA = Yup.object().shape({
  members: Yup.array().of(
    Yup.object().shape({
      email: Yup.string()
        .email(() => i18n.t('validation:checkTheEmailFormat'))
        .required(() => i18n.t('validation:filledEmailRequired')),
    }),
  ),
});

export const WORKSPACE_OVERVIEW_FORM_SCHEMA = createValidationSchema({
  name: {
    required: true,
    pattern: ValidationType.NAME,
    minLength: 3,
    maxLength: 55,
  },
});

export const WORKSPACE_OWNER_FORM_SCHEMA = createValidationSchema({
  email: {
    required: true,
    isSelection: true,
    pattern: ValidationType.EMAIL,
  },
});

export const WORKSPACE_MEMBER_FORM_SCHEMA = createValidationSchema({
  email: {
    required: true,
    pattern: ValidationType.EMAIL,
  },
});
