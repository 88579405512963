import { Description, Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useField } from 'formik';

import { Dictionaries, WAFRulesType } from '@enums';
import { getOptionListFromCatalog } from '@utils';

const getDescription = (rule: string) => {
  switch (rule) {
    case WAFRulesType.scanner_detection:
      return 'Identifies probes searching for vulnerabilities in your network';
    case WAFRulesType.protocol_enforcement:
      return 'Ensures that all network communications adhere to established protocols';
    case WAFRulesType.protocol_attack:
      return 'Targets and exploits weaknesses in communication protocols';
    case WAFRulesType.application_attack_lfi:
      return 'A vulnerability that lets attackers access and execute files on your server from a web request';
    case WAFRulesType.application_attack_rfi:
      return 'Allows attackers to execute remote files on your server, typically through web requests';
    case WAFRulesType.application_attack_rce:
      return 'A vulnerability that enables attackers to run arbitrary code on your server remotely';
    case WAFRulesType.application_attack_php:
      return 'Security weaknesses in PHP applications that could be exploited by attackers';
    case WAFRulesType.application_attack_nodejs:
      return 'Specific security issues in Node.js applications that can be exploited';
    case WAFRulesType.application_attack_sqli:
      return 'An attack where malicious SQL statements are inserted into an input field to manipulate your database';
    case WAFRulesType.application_attack_session_fixation:
      return 'An attack where the session ID is hijacked, allowing an attacker to masquerade as a legitimate user';
    case WAFRulesType.application_attack_java:
      return 'Exploitable flaws in Java applications or the environment that can lead to unauthorized actions';
    default:
      return '';
  }
};

export const RadioTableInput = ({ name }) => {
  const [field, , helpers] = useField(name);

  const handleOptionClick = (rule) => {
    const index = value.findIndex((item) => item.value === rule.value);
    if (index !== -1) {
      const updatedValue = [...value.slice(0, index), ...value.slice(index + 1)];
      helpers.setValue(updatedValue);
    } else {
      const updatedValue = [...value, rule];
      helpers.setValue(updatedValue);
    }
  };

  const value = field.value;

  if (!value) return;

  const optionList = getOptionListFromCatalog({ name: Dictionaries.WAFRules });

  return (
    <div className="w-full flex">
      <RadioGroup value={value}>
        <div className="grid grid-cols-2 gap-4">
          {optionList?.map((rule) => {
            const isSelected = value?.some((item) => item.value === rule.value);
            return (
              <Radio
                key={rule.value}
                value={rule.value}
                onClick={() => handleOptionClick(rule)}
                className={() =>
                  ` ${isSelected ? 'bg-theme-primary-main text-white' : 'bg-white'} focus:ring-0
                relative flex cursor-pointer rounded-lg p-4 shadow-md focus:outline-none
                `
                }
              >
                {() => (
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm space-y-2 flex flex-col select-none">
                        <Description as="p" className={`font-medium `}>
                          {rule.label}
                        </Description>
                        <Description as="span" className={`text-us inline`}>
                          <article className="line-clamp-2 font-light ">{getDescription(rule.value)}</article>
                        </Description>
                      </div>
                    </div>
                    <div className="shrink-0 min-w-7 text-white flex justify-end">
                      {isSelected && <CheckCircleIcon className="h-6 w-6" />}
                    </div>
                  </div>
                )}
              </Radio>
            );
          })}
        </div>
      </RadioGroup>
    </div>
  );
};
