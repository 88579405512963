import type { LoginFormConfig } from '@core/types/forms/user/CreateUserAccountForm';

export const CREATE_USER_ACCOUNT_FORM_CONFIG: LoginFormConfig = {
  initialValues: {
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    confirm_password: '',
  },
  fields: [
    {
      name: 'username',
      label: 'form.email',
      placeholder: 'form.enterYourEmailAddress',
    },
    {
      name: 'firstname',
      label: 'form.firstName',
      placeholder: 'form.enterYourFirstName',
    },
    {
      name: 'lastname',
      label: 'form.lastName',
      placeholder: 'form.enterYourLastName',
    },
    {
      name: 'password',
      type: 'password',
      label: 'form.password',
      placeholder: 'form.enterYourPassword',
      isAnimated: true,
    },
    {
      name: 'confirm_password',
      type: 'password',
      label: 'form.confirmPassword',
      placeholder: 'form.enterYourConfirmPassword',
      isAnimated: true,
    },
  ],
} as const;
